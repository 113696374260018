import {stringify} from "query-string";
import {request, _urlOauth, ClientEncoded} from "../api";
import {store} from "../store";
import {history} from "../../configs/history";
import {AUTH_CONSTS, UTIL_CONSTS} from "../constants";
import swal from 'sweetalert';
import { socketConnection } from "../../socket/api/socketConnection";

export const LoginAdmin = loginCreds => {
    const requestData = {
        url:`${_urlOauth}/token`,
        method: "POST",
        data: stringify(loginCreds),
        customHeaders: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        request(requestData)
            .then(async ({data}) => {
                //scope: "access:admin"
                if (data?.scope === 'access:admin' || data?.scope === 'access:moderator' ) {
                    await dispatch({
                        type: AUTH_CONSTS.LOG_IN,
                        payload: data
                    });
                    history.push("/dashboard");
                } else {
                    swal({
                        title: "Չհաջողվեց!",
                        text: 'Սխալ մուտքանուն կամ գաղտնաբառ',
                        icon: "error",
                        button: 'Լավ'
                    });
                }

            })
    }
};

export const LogOut = () => {
    return dispatch => {
        if (socketConnection.instance) {
            socketConnection.instance.disconnect();
            socketConnection.instance = null;
        }
        dispatch({
            type: AUTH_CONSTS.LOG_OUT
        });
        history.push('/login');
    }
};

export const TokenValidation = () => {
    const AccessToken = store.getState().auth.accessToken;
    const requestData = {
        url: `${_urlOauth}/authenticate`,
        method: "GET",
        customHeaders: {
            "Accept": "application/json",
            "x-access-token":  AccessToken,
        }
    };
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then(async (res) => {
                await dispatch({
                    type: AUTH_CONSTS.SET_ACCESS_PAGES,
                    payload: res?.data?.user
                });
                return res?.data?.accessToken;
            })
    }
};

export const RefreshToken = () => {

    const refToken = store.getState().auth.refreshToken;
    // console.log('start-refreshing-function');
    const requestData = {
        url: `${_urlOauth}/refresh-token`,
        method: "POST",
        data: stringify({
            grant_type: "refresh_token",
            refresh_token: refToken
        }),
        customHeaders: {
            "Content-Type": "application/x-www-form-urlencoded",
            "authorization": "Basic " + ClientEncoded,
        }
    };
    return request(requestData).then(async (response) => {
        //   console.log(response);
        response && await store.dispatch({
            type: AUTH_CONSTS.REFRESH_TOKEN,
            payload: response.data
        });
        return response && response.data && response.data.accessToken;
    }).catch(() => null)
};
