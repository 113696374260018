// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon, Switch} from "antd";
import debounce from 'lodash.debounce';

//import styles
import "../../assets/styles/containerStyles/add-edit-track.scss";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";


// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {AddTrack, GetAlbums, GetArtists, GetTrackById, UpdateTrackById, GetAlbumById} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_AUDIO_TYPES, ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {resizeImage} from "../../utils/resizeImage";
import {youTubeRegExp} from "../../constants/regExps";
import isEmpty from "validator/es/lib/isEmpty";
import {ALBUM_TYPES} from "../../constants/constTypes";


const MEDIA_TYPES = {
    SONG: 'song',
    IMAGE: 'coverImage',
}

class AddEditTrack extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'subTitle',
            'slug',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                youtubeUrl: '',
                song: undefined,
                coverImage: undefined,
                artistsIds: [],
                albumsIds: [],
                playlistsIds: [],
                isHidden: false,
                contentWarning: false,
                isTop: props?.defaultValues?.defaultNewTrackIsTop,
            },
            artists: {
                itemsList: [],
                hasMore: false,
                text: ''
            },
            albums: {
                itemsList: [],
                hasMore: false,
                text: '',
                types: `${ALBUM_TYPES.MAIN.key},${ALBUM_TYPES.SINGLE.key}`
            },
            playlists: {
                itemsList: [],
                hasMore: false,
                text: '',
                types: `${ALBUM_TYPES.PLAYLIST.key}`
            },
            errors: {},
            errorsTabs: [],
            mediaType: null,
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.getPatchReqData = getPatchReqData.bind(this);
        this.toggleMediaModal = changeStateField.bind(this, 'mediaType');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getSelectValues = this.getSelectValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);

        this.onPopupScroll = this.onPopupScroll.bind(this);
        this.onSelectSearchChanged = this.onSelectSearchChanged.bind(this);
        this.onSelectSearchChangedBounded = debounce(this.onSelectSearchChanged, 500);

        this.addEditTrack = this.addEditTrack.bind(this);
    }

    async componentDidMount() {
        const {artists, albums} = this.props;
        const {isEditing, fieldsData} = this.state;
        const copiedTrackId = this.props.location.state?.id

        if (isEditing || copiedTrackId) {
            const editingData = await this.props.GetTrackById(isEditing ? this.props?.id : copiedTrackId)
            const translationsData = {};
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        subTitle: trItem?.subTitle || '',
                        description: trItem?.description || '',
                        slug: trItem?.slug || '',
                    };
                });
            } else {
                history.push('/dashboard');
                return;
            }
            const initStateData = {
                translations: translationsData,
                youtubeUrl: editingData?.youtubeUrl || '',
                song: editingData?.song,
                coverImage: editingData?.coverImage,
                artistsIds: editingData?.artists?.map(a => a?.id) || [],
                albumsIds: editingData?.albums?.filter(item => item.type === ALBUM_TYPES.MAIN.key || item.type === ALBUM_TYPES.SINGLE.key)?.map(a => a?.id) || [],
                playlistsIds: editingData?.albums?.filter(item => item.type === ALBUM_TYPES.PLAYLIST.key)?.map(a => a?.id) || [],
                isHidden: editingData?.isHidden,
                contentWarning: editingData?.contentWarning,
                isTop: editingData?.isTop,
            }
            this.attachedArtistOptions = editingData?.artists?.length && editingData?.artists.map(artist => {
                return {
                    id: artist?.id,
                    name: artist?.fullName || "",
                    imageUrl: resizeImage(generateImageMediaUrl(artist?.profileImage?.path), 200, 200),
                }
            });
            this.attachedAlbumOptions = editingData?.albums?.length && editingData?.albums.filter(item => item.type === ALBUM_TYPES.MAIN.key || item.type === ALBUM_TYPES.SINGLE.key).map(album => {
                return {
                    id: album?.id,
                    name: album?.title || "",
                    imageUrl: resizeImage(generateImageMediaUrl(album?.coverImage?.path), 200, 200),
                }
            });
            this.attachedPlaylistOptions = editingData?.albums?.length && editingData?.albums.filter(item => item.type === ALBUM_TYPES.PLAYLIST.key).map(album => {
                return {
                    id: album?.id,
                    name: album?.title || "",
                    imageUrl: resizeImage(generateImageMediaUrl(album?.coverImage?.path), 200, 200),
                }
            });

            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData,
            });
        }


        if (artists?.itemsList?.length && !artists?.text) {
            this.setState({
                artists: {
                    itemsList: artists?.itemsList,
                    hasMore: artists.hasMore,
                    text: ''
                },
            })
        } else {
            this.props.GetArtists({getResponse: true}).then(res => {
                this.setState({
                    artists: {
                        itemsList: res.data,
                        hasMore: res.hasMore,
                        text: ''
                    },
                })
            })
        }

        // if (albums?.itemsList?.length && !albums?.text) {
        //     this.setState({
        //         albums: {
        //             itemsList: albums?.itemsList,
        //             hasMore: albums.hasMore,
        //             text: ''
        //         },
        //     })
        // } else {

        this.props.GetAlbums({
            getResponse: true,
            types: `${ALBUM_TYPES.MAIN.key},${ALBUM_TYPES.SINGLE.key}`
        }).then(res => {
            this.setState({
                albums: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: '',
                    types: this.state.albums.types
                },
            })
        })
        this.props.GetAlbums({getResponse: true, types: `${ALBUM_TYPES.PLAYLIST.key}`}).then(res => {
            this.setState({
                playlists: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: '',
                    types: this.state.playlists.types
                },
            })
        })
        // }

    }

    onPopupScroll(e, type) {
        e.persist();
        // const {artistsIds} = this.state.fieldsData;
        const {itemsList, text, hasMore, types} = this.state?.[type];
        // console.log('hasMore', hasMore, type);
        let target = e.target;

        if (target.scrollTop + target.offsetHeight >= target.scrollHeight && hasMore) {
             // console.log(target.scrollTop, target.offsetHeight, target.scrollHeight)
            const reqData = {
                text,
                getResponse: true,
                offset: itemsList?.length,
            }
            if (type === 'albums' || type === 'playlists') {
                reqData.types = types
            }

            // if (type === 'albums' && artistsIds.length) {
            //     reqData.artists = artistsIds
            // }
            const reqPromise = type === 'artists' ? this.props.GetArtists(reqData) :
                this.props.GetAlbums(reqData);
            reqPromise.then(res => {
                this.setState({
                    [type]: {
                        itemsList: [...itemsList, ...res.data],
                        hasMore: res.hasMore,
                        text,
                        types
                    },
                })
            })
        }
    }

    onSelectSearchChanged(text, type) {
        // console.log('search change', text)
        //const {artistsIds} = this.state.fieldsData;
        const {types} = this.state?.[type];
        const reqData = {
            text,
            getResponse: true,
        }
        // if (type === 'albums' && artistsIds.length) {
        //     reqData.artist = artistsIds
        // }
        if (type === 'albums' || type === 'playlists') {
            reqData.types = types
        }
        const reqPromise = type === 'artists' ? this.props.GetArtists(reqData) :
            this.props.GetAlbums(reqData);
        reqPromise.then((res => {
            this.setState({
                [type]: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: text,
                    types
                },
            })
        }))
    }

    async getSelectValues(data) {
        const {albumsIds} = this.state.fieldsData

        await this.getInputValues(data);
        // if (data?.name === "artistsIds") {
        //     const reqData = {
        //         getResponse: true,
        //         artists: data.value
        //     };
        //
        //     this.getInputValues({
        //         name: 'albumsIds',
        //         value: undefined,
        //         haveChanges: this.currentData?.albumId
        //     });
        //
        //     this.props.GetAlbums(reqData).then((res => {
        //         this.setState({
        //             albums: {
        //                 itemsList: res.data,
        //                 hasMore: res.hasMore,
        //                 text: ''
        //             },
        //         })
        //     }))
        //
        // }
        if (data?.name === "albumsIds" && data.value?.length > albumsIds.length) {
            const {albums} = this.state
            const ids = []
            data.value && data.value.map(id => {
                const albumArtists = albums.itemsList.find(album => album.id === id)?.artists
                albumArtists?.length && albumArtists.map(artist => {
                    if (!ids.find(item => item?.id === artist?.id)) {
                        ids.push(artist)
                    }
                })

                this.attachedArtistOptions = ids?.length && ids.map(artist => {
                    return {
                        id: artist?.id,
                        name: artist?.fullName || "",
                        imageUrl: resizeImage(generateImageMediaUrl(artist?.profileImage?.path), 200, 200),
                    }
                });

                this.setState({
                    fieldsData: {
                        ...this.state.fieldsData,
                        artistsIds: [...new Set(this.state.fieldsData.artistsIds.concat(ids.map(item => item.id)))]
                    }
                })
                this.getInputValues({
                    name: 'artistsIds',
                    value: this.state.fieldsData.artistsIds,
                    haveChanges: this.currentData?.artistsIds
                });
                if (!this.state.fieldsData.coverImage) {
                    this.getMedia([albums?.itemsList.find(album => album.id === data.value[0])?.coverImage], "coverImage")
                }
            })
        }
        // else if(data?.name === "albumsIds" && !data.value.length) {
        //     this.deleteMedia.bind(this, 'coverImage')
        // }
    }

    getMedia(mediaArray, mediaType) {
        console.log(mediaArray, mediaType)
        const {defaultValues} = this.props;
        const {fieldsData, errors, isEditing} = this.state;
        let translations = {...fieldsData.translations};
        if (isEditing) {
            this.currentData?.[mediaType]?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set(mediaType, mediaArray[0]?.id)
                : this.updatedDataMap.delete(mediaType);
        } else if (defaultValues?.usTrackName) {
            let haveTranslationTitle = false;
            Object.keys(fieldsData.translations).forEach(key => {
                if (fieldsData.translations?.[key]?.title) {
                    haveTranslationTitle = true;
                }
            });
            console.log('haveTranslationTitle', haveTranslationTitle)
            if (!haveTranslationTitle) {
                const replaced = mediaArray[0]?.name?.replace(/-/gi, ' ');
                Object.keys(fieldsData.translations).forEach(key => {
                    translations = {
                        ...translations,
                        [key]: {
                            ...translations[key],
                            title: replaced || ""
                        }
                    }
                });

            }
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations,
                [mediaType]: mediaArray[0],
            },
            errors: {
                ...errors,
                [mediaType]: false
            }
        })
    }

    deleteMedia(mediaType) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.[mediaType] ?
                this.updatedDataMap.set(mediaType, null) :
                this.updatedDataMap.delete(mediaType);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [mediaType]: null
            }
        })
    }

    async addEditTrack() {
        await this.setState({
            requestLoading: true
        });
        const {translations, coverImage, youtubeUrl, song, isHidden, contentWarning, isTop, artistsIds, albumsIds, playlistsIds, genreId} = this.state.fieldsData;
        const {albums} = this.state;
        console.log("albums,", albums)
        const translationRequiredFields = ['title', 'slug']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        // if (!youtubeUrl || !youTubeRegExp.test(youtubeUrl)) {
        //     errors.youtubeUrl = true;
        //     result = false;
        // }
        if (result) {
            const trData = getTranslationData(translations)
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    isTop,
                    contentWarning,
                    translations: trData
                };

                youtubeUrl && (reqData.youtubeUrl = youtubeUrl);
                artistsIds.length && (reqData.artists = artistsIds);
                albumsIds.length && (reqData.albums = albumsIds);
                playlistsIds.length && (reqData.albums = albumsIds.concat(playlistsIds));
                song && (reqData.song = song?.id);
                genreId && (reqData.genre = genreId);
                coverImage && (reqData.coverImage = coverImage.id);

                this.props.AddTrack(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const reqData = this.getPatchReqData(trData);
                if (Object(reqData).hasOwnProperty('artistsIds')) {
                    reqData.artists = reqData.artistsIds;
                    delete reqData.artistsIds;
                }
                if (Object(reqData).hasOwnProperty('albumsIds')) {
                    reqData.albums = albumsIds ? albumsIds.concat(playlistsIds) : playlistsIds;
                }
                if (Object(reqData).hasOwnProperty('playlistsIds')) {
                    reqData.albums = playlistsIds ? playlistsIds.concat(albumsIds) : albumsIds;

                }
                delete reqData.playlistsIds;
                delete reqData.albumsIds;
                this.props.UpdateTrackById(this.props.id, reqData, this.props?.location?.state?.redirect)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {

        const {
            fieldsData, errors, languageTab, mediaType, requestLoading, isEditing, errorsTabs,
            artists, albums, playlists
        } = this.state;

        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        //this.attachedArtistOptions
        let artistsOptions = artists?.itemsList?.map(artist => {
            return {
                id: artist.id,
                name: artist?.fullName || "",
                imageUrl: resizeImage(generateImageMediaUrl(artist?.profileImage?.path), 200, 200),
            }
        });
        this.attachedArtistOptions?.length && this.attachedArtistOptions.forEach(initOption => {
            if (!artistsOptions.some(art => art?.id === initOption?.id)) {
                artistsOptions.unshift(initOption);
            }
        })

        //this.attachedAlbumOption
        let albumsOptions = albums?.itemsList?.map(album => {
            return {
                id: album.id,
                name: album?.title || "",
                imageUrl: resizeImage(generateImageMediaUrl(album?.coverImage?.path), 200, 200),
            }
        });

        this.attachedAlbumOptions?.length && this.attachedAlbumOptions.forEach(initOption => {
            if (!albumsOptions.some(alb => alb?.id === initOption?.id)) {
                albumsOptions.unshift(initOption);
            }
        })
        //this.attachedPlaylistOption
        let playlistsOptions = playlists?.itemsList?.map(playlist => {
            return {
                id: playlist.id,
                name: playlist?.title || "",
                imageUrl: resizeImage(generateImageMediaUrl(playlist?.coverImage?.path), 200, 200),
            }
        });

        this.attachedPlaylistOptions?.length && this.attachedPlaylistOptions.forEach(initOption => {
            if (!playlistsOptions.some(alb => alb?.id === initOption?.id)) {
                playlistsOptions.unshift(initOption);
            }
        })


        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Երգ' : 'Փոփոխել Երգը'}>
            <section className="general-add-edit add-edit-track">
                <div className="fixed-top-right">
                    <div className={'switch_one'}>
                        <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ակտիվ "}</label>
                        <Switch checked={!fieldsData.isHidden}
                                onChange={(checked) => this.getInputValues({
                                    name: "isHidden",
                                    value: !checked,
                                    haveChanges: this.currentData?.isHidden !== !checked
                                })}/>
                    </div>
                    <div className={'switch_two'}>
                        <label>{fieldsData.isTop ? "Թոփ " : "Թոփ "}</label>
                        <Switch checked={fieldsData.isTop}
                                onChange={(checked) => this.getInputValues({
                                    name: "isTop",
                                    value: checked,
                                    haveChanges: this.currentData?.isTop !== checked
                                })}/>
                    </div>
                    <div className={'switch_three'}>
                        <label>{fieldsData.contentWarning ? "18+ " : "18+ "}</label>
                        <Switch checked={fieldsData.contentWarning}
                                onChange={(checked) => this.getInputValues({
                                    name: "contentWarning",
                                    value: checked,
                                    haveChanges: this.currentData?.contentWarning !== checked
                                })}/>
                    </div>
                </div>
                <div className={'top-side-wrapper'}>
                    <div className="top-side">
                        <label>Նկար </label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn`}
                                    cb={this.toggleMediaModal.bind(this, MEDIA_TYPES.IMAGE)}/>
                        {fieldsData?.coverImage && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.coverImage}
                                           customDelete={this.deleteMedia.bind(this, 'coverImage')}/>
                        </div>}
                    </div>
                    <div className="top-side">
                        <label>Երգ </label>
                        <LinkButton title="Ընտրել մեդիադարանից"
                                    className={`media-select-btn ${errors?.song ? 'media-select-error' : ''}`}
                                    cb={this.toggleMediaModal.bind(this, MEDIA_TYPES.SONG)}/>
                        {fieldsData?.song && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.song}
                                           customDelete={this.deleteMedia.bind(this, 'song')}/>
                        </div>}
                    </div>
                </div>
                <div className="row-2">
                    <InputGroup inputType="selectCustom"
                                placeholder="Արտիստներ"
                                label="Արտիստներ"
                                name="artistsIds"
                                showSearch={true}
                                mode={'multiple'}
                                onPopupScroll={(e) => this.onPopupScroll(e, 'artists')}
                                onSearch={(e) => this.onSelectSearchChangedBounded(e, 'artists')}
                                value={fieldsData?.artistsIds}
                                initValue={this.currentData?.artistsIds}
                                onChange={this.getSelectValues}
                                options={artistsOptions}/>
                    <InputGroup inputType="selectCustom"
                                placeholder="Ալբոմներ"
                                label="Ալբոմներ"
                                name="albumsIds"
                                showSearch={true}
                                mode={'multiple'}
                                onPopupScroll={(e) => this.onPopupScroll(e, 'albums')}
                                onSearch={(e) => this.onSelectSearchChangedBounded(e, 'albums')}
                                value={fieldsData?.albumsIds}
                                initValue={this.currentData?.albumsIds}
                                onChange={this.getSelectValues}
                                options={albumsOptions}/>
                </div>

                <div className="albums-section">
                    <InputGroup inputType="selectCustom"
                                placeholder="Փլեյլիստեր"
                                label="Փլեյլիստեր"
                                name="playlistsIds"
                                showSearch={true}
                                mode={'multiple'}
                                onPopupScroll={(e) => this.onPopupScroll(e, 'playlists')}
                                onSearch={(e) => this.onSelectSearchChangedBounded(e, 'playlists')}
                                value={fieldsData?.playlistsIds}
                                initValue={this.currentData?.playlistsIds}
                                onChange={this.getSelectValues}
                                options={playlistsOptions}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Հղում"
                                placeholder="Հղում"
                                name="youtubeUrl"
                                value={fieldsData.youtubeUrl}
                                initValue={this.currentData?.youtubeUrl}
                                error={errors?.youtubeUrl}
                                maxLength={1024}
                                required={false}
                                onChange={this.getInputValues}>
                        {
                            fieldsData.youtubeUrl && youTubeRegExp.test(fieldsData.youtubeUrl) &&
                            <a href={fieldsData.youtubeUrl}
                               rel="noopener noreferrer"
                               target={'_blank'}
                               className={'visibility-icon invisible'}>
                                <Icon type="youtube"/>
                            </a>
                        }
                    </InputGroup>
                </div>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="row-2">
                    <div className="col-2">
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Անվանում"
                                    placeholder="Անվանում"
                                    name="title"
                                    value={currentData.title}
                                    initValue={initData.title}
                                    error={errors['title' + languageTab]}
                                    maxLength={1000}
                                    required={true}
                                    onChange={this.getTranslationValues}/>
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Ենթաանվանում"
                                    placeholder="Ենթաանվանում"
                                    name="subTitle"
                                    value={currentData.subTitle}
                                    initValue={initData.subTitle}
                                    error={errors['subTitle' + languageTab]}
                                    maxLength={1000}
                                    onChange={this.getTranslationValues}/>
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Սլագ"
                                    placeholder="Սլագ"
                                    name="slug"
                                    value={currentData.slug}
                                    initValue={initData.slug}
                                    error={errors['slug' + languageTab]}
                                    maxLength={1000}
                                    required={true}
                                    onChange={this.getTranslationValues}/>
                    </div>
                    <InputGroup inputType="textarea"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={8000}
                                onChange={this.getTranslationValues}/>
                </div>


                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditTrack}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaType}
                mediaType={mediaType}
                acceptTypes={mediaType === MEDIA_TYPES.IMAGE ? ACCEPT_IMAGE_TYPES : ACCEPT_AUDIO_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    AddTrack,
    UpdateTrackById,
    GetTrackById,
    GetArtists,
    GetAlbums,
    GetAlbumById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditTrack)
