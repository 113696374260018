import {request, _urlUsers} from "../api";
import {USERS_CONSTS, UTIL_CONSTS} from "../constants";
import {LIMIT_GET_USERS} from "../../constants/constLimitCounts";

export const GetUsers = ({
                             reset = true,
                             offset = 0,
                             text = '',
                             createdAtStartDate = null,
                             createdAtEndDate = null,
                             makeOrdersStartDate = null,
                             makeOrdersEndDate = null,
                             isVerified,
                             isBlocked,
                             isOnline,
                             limit = LIMIT_GET_USERS,
                             getResponse = false
                         } = {}) => {

    let url = `${_urlUsers}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${encodeURIComponent(text)}`);
    isVerified !== undefined && (url += `&isVerified=${Boolean(isVerified)}`);
    isBlocked !== undefined && (url += `&isBlocked=${Boolean(isBlocked)}`);
    isOnline !== undefined && (url += `&isOnline=${Boolean(isOnline)}`);
    createdAtStartDate && (url += `&createdAtStartDate=${createdAtStartDate}`);
    createdAtEndDate && (url += `&createdAtEndDate=${createdAtEndDate}`);
    makeOrdersStartDate && (url += `&makeOrdersStartDate=${makeOrdersStartDate}`);
    makeOrdersEndDate && (url += `&makeOrdersEndDate=${makeOrdersEndDate}`);
    const requestData = {
        url: url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && !getResponse && dispatch({
            type: UTIL_CONSTS.START_LOADING
        });
        return request(requestData)
            .then((res) => {
                const data = res.data;
                //console.log('%c users', 'color:red', data);
                data && !getResponse && dispatch({
                    type: USERS_CONSTS.GET_USERS,
                    payload: {
                        data: data?.users,
                        usersCount: data?.usersCount,
                        reset,
                        isVerified,
                        isBlocked,
                        hasMore: data?.users?.length === limit
                    }
                });
                if (getResponse) {
                    return {
                        data: data?.users,
                        hasMore: data?.users?.length === limit
                    }
                }
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};


export const GetUserById = (id) => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        dispatch({type:UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(async ({data}) => {
                await dispatch({
                    type: USERS_CONSTS.GET_USER_BY_ID,
                    payload: data
                });
                dispatch({type:UTIL_CONSTS.END_LOADING})
                //  console.log("user",data);
            })
    }
};

export const ToggleUserBlockState = (id, data) => {
    const requestData = {
        url: `${_urlUsers}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: USERS_CONSTS.TOGGLE_USER_BLOCK_STATE,
                    payload: {
                        id,
                        state: data.isBlocked
                    }
                });
            })
    }
};

export const ClearUserById = () => {
    return dispatch => {
        dispatch({
            type: USERS_CONSTS.CLEAR_USER_BY_ID,
        });
    }
};
