import {ARTISTS_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    artists: {
        itemsList: [],
        text: '',
        hasMore:false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ARTISTS_CONSTS.GET_ARTISTS:
            return {
                ...state,
                artists: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.artists.itemsList, ...action.payload.data],
                    text: action.payload.text,
                    hasMore: action.payload.hasMore,
                }
            };
        case ARTISTS_CONSTS.CREATE_ARTIST:
            return {
                ...state,
                artists: {
                    ...state.artists,
                    itemsList: [action.payload].concat(state.artists.itemsList)
                }
            };
        case ARTISTS_CONSTS.UPDATE_ARTIST_BY_ID:
            return {
                ...state,
                artists: {
                    ...state.artists,
                    itemsList: state.artists.itemsList.map(artist => artist.id === action.payload.id
                        ? action.payload : artist),
                },
            };
        case ARTISTS_CONSTS.DELETE_ARTIST_BY_ID:
            return {
                ...state,
                artists: {
                    ...state.artists,
                    itemsList: state.artists.itemsList.filter(artist => artist.id !== action.payload),
                },
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}
