// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {Switch} from "antd";

// Import utils
import {
    getInputValues,
    getPatchReqData,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateYouTubeKey, UpdateYouTubeKeyById,} from "../../redux/actions";
import {history} from "../../configs/history";
import {keyTypeOptions, keyPlatformOptions} from "./utils";
import {YOUTUBE_KEYS_PLATFORMS, YOUTUBE_KEYS_TYPES} from "../../constants/constTypes";

class AddEditKey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                value: '',
                platform: YOUTUBE_KEYS_PLATFORMS.iOS.id,
                type: YOUTUBE_KEYS_TYPES.production.id,
                allowUse: true
            },
            requestLoading: false,
            errors: {},
            isEditing: !!props?.id,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getInputValues = getInputValues.bind(this);
        this.addEditKey = this.addEditKey.bind(this);
    }

     componentDidMount() {
        const {isEditing, } = this.state;
        const {id, youtubeKeys} = this.props;
        if (isEditing) {
            const editingData = youtubeKeys?.itemsList?.find(item => item.id === id);
            if (!editingData) {
                history.push('/youTubeKeys');
                return;
            }
            const initStateData = {
                value: editingData?.value || '',
                platform: editingData?.platform,
                type: editingData?.type,
                allowUse: editingData?.allowUse,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData,
            });
        }
    }

    async addEditKey() {
        await this.setState({
            requestLoading: true
        });
        const {value, platform, type, allowUse} = this.state.fieldsData;

        let result = !!value;
        const errors = {
            value: !value
        };

        if (result) {
            if (!this.state.isEditing) {
                const reqData = {
                    platform, type, allowUse, value
                };

                this.props.CreateYouTubeKey(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const reqData = this.getPatchReqData();
                // console.log('reqData', reqData)

                this.props.UpdateYouTubeKeyById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }


    render() {
        const {fieldsData, errors, isEditing,requestLoading} = this.state;

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Կոդ' : 'Փոփոխել Կոդը'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{fieldsData.allowUse ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={fieldsData.allowUse}
                            onChange={(checked) => this.getInputValues({
                                name: "allowUse",
                                value: checked,
                                haveChanges: this.currentData?.allowUse !== checked
                            })}/>
                </div>

                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="value"
                            value={fieldsData?.value}
                            initValue={this.currentData?.value}
                            error={errors?.value}
                            maxLength={64}
                            required={true}
                            onChange={this.getInputValues}/>

                <div className={'row-2'}>
                    <InputGroup inputType="selectCustom"
                                placeholder="Ընտրել Պլատֆորմ"
                                label="Պլատֆորմ"
                                name="platform"
                                showSearch={false}
                                withClear={false}
                                value={fieldsData?.platform}
                                initValue={this.currentData?.platform}
                                onChange={this.getInputValues}
                                options={keyPlatformOptions}/>

                    <InputGroup inputType="selectCustom"
                                placeholder="Ընտրել Տիպ"
                                label="Տիպ"
                                name="type"
                                showSearch={false}
                                withClear={false}
                                value={fieldsData?.type}
                                initValue={this.currentData?.type}
                                onChange={this.getInputValues}
                                options={keyTypeOptions}/>
                </div>


                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditKey}/>
                </div>

            </section>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateYouTubeKey,
    UpdateYouTubeKeyById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditKey)
