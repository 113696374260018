// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import debounce from 'lodash.debounce';

//Import styles
import '../../assets/styles/containerStyles/albums.scss'

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";
import {ReactComponent as ContentWarning} from "../../assets/images/ic_content_warning.svg";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetAlbums, GetArtists, UpdateAlbumsPositions, UpdateAlbumById,
    DeleteAlbumById, setPageOffset,
} from "../../redux/actions";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {resizeImage} from "../../utils/resizeImage";
import {ALBUM_TYPES} from "../../constants/constTypes";

const typeOptions = Object.keys(ALBUM_TYPES).map(key => ({
    id: key,
    name: ALBUM_TYPES[key].name
}));


class Albums extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            artist: undefined,
            type: undefined,
            artists: {
                itemsList: [],
                hasMore: false,
                text: ''
            },
        };
        this.getSearchValue = this.getSearchValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.getSelectValue = this.getSelectValue.bind(this);
        this.onPopupScroll = this.onPopupScroll.bind(this);
        this.onSearchArtist = this.onSearchArtist.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
        this.deleteItem = deleteItem.bind(this, props.DeleteAlbumById, 'ալբոմը');
        this.onSearchArtistBounded = debounce(this.onSearchArtist, 500);
    }

    componentDidMount() {
        const {pageOffset, albums, artists} = this.props;
        if (pageOffset.pageName === 'albums') {
            setTimeout(() => {
                window.scrollTo(0, pageOffset.offset);
            }, 0);
            if (albums?.text || albums?.artist || albums?.type) {
                this.setState({
                    text: albums?.text || '',
                    artist: albums?.artist || undefined,
                    type: albums?.type || undefined,
                })
            }
            setPageOffset(null);
        } else {
            this.props.GetAlbums();
        }
        if (artists?.itemsList?.length && !artists?.text) {
            this.setState({
                artists: {
                    itemsList: artists?.itemsList,
                    hasMore: artists.hasMore,
                    text: ''
                },
            })
        } else {
            this.props.GetArtists({getResponse: true}).then(res => {
                this.setState({
                    artists: {
                        itemsList: res.data,
                        hasMore: res.hasMore,
                        text: ''
                    },
                })
            })
        }
    }

    onPopupScroll(e) {
        e.persist();
        const {itemsList, text, hasMore} = this.state.artists;
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            // console.log(target.scrollTop, target.offsetHeight, target.scrollHeight)

            hasMore && this.props.GetArtists({
                getResponse: true,
                text,
                offset: itemsList?.length
            }).then(res => {
                this.setState({
                    artists: {
                        itemsList: [...itemsList, ...res.data],
                        hasMore: res.hasMore,
                        text
                    },
                })
            })
        }
    }

    onSearchArtist(text) {
        this.props.GetArtists({
            getResponse: true,
            text,
        }).then((res => {
            this.setState({
                artists: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: text
                },
            })
        }))
    }

    getSearchValue(text) {
        // console.log('text', text)
        this.setState({
            text
        }, () => !text && this.searchItems())
    }

    getSelectValue({name, value}) {
        console.log(name, value)
        this.setState({
            [name]: value
        }, () => this.searchItems())
    }

    searchItems() {
        const {text, artist, type} = this.state;
        text?.length !== 1 && this.props.GetAlbums({
            text,
            artist,
            types: type
        });
    }

    loadMoreItems() {
        const {albums} = this.props;
        const {text, artist, type} = this.state;
        albums?.hasMore && this.props.GetAlbums({
            reset: false,
            offset: albums?.itemsList?.length,
            text,
            artist,
            types: type
        });
    };

    async onPositionChange(items, item) {
        const movedItemId = item.id;
        const {albums} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~albums.itemsList.findIndex(m => m.id === movedItemId) &&
            ~positionedItems.findIndex(m => m.id === movedItemId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateAlbumsPositions(positionedItems);
            this.props.GetAlbums({
                changeLoadingState: false,
                limit: items?.length,
            });
        }
    }

    render() {
        let {text, artist, type, artists} = this.state;
        let {albums, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Նկար", "Անվանում", "Ամսատիվ", "Տիպ"],
            status: true,
            fixWidths: {0: 60, 2: 100, 3: 100},
            styles: {
                1: {justifyContent: 'flex-start'},
            },
            actions: ["toggle", "edit", "delete"],
        };
        let itemListInfo = [];
        albums && albums.itemsList && albums.itemsList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                status: !item?.isHidden,
                values: [
                    {
                        type: 'image',
                        path: resizeImage(generateImageMediaUrl(item?.coverImage?.path), 200, 200),
                    },
                    <span className={"info-title-wrapper"}>
                        <div className={'info-title'}>
                            {item?.title || "-"}
                            <span className={'info-sub-title'}>{item?.subTitle || ""}</span>
                        </div>
                        {item?.contentWarning && <ContentWarning title={''} className="e-icon"/>}
                    </span>,
                    item?.year,
                    ALBUM_TYPES[item?.type].name
                ],
                deleteCb: this.deleteItem.bind(this, item.id),
                toggleCb: () => this.props.UpdateAlbumById(item.id, {isHidden: !item?.isHidden}, false)
            });
        });

        let artistsOptions = artists?.itemsList?.map(artist => {
            return {
                id: artist.id,
                name: artist?.fullName || "",
                imageUrl: generateImageMediaUrl(artist?.profileImage?.path),
            }
        });

        return <PageWrapper pageTitle={'Ալբոմներ'}>

            <section className="albums">
                <PageHeader linkTitle={"Ավելացնել"}
                            addingLink={"/albums/add"}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.searchItems}
                            searchValue={text}
                            search={true}
                            renderingCustomRightPart={
                                <>
                                    <InputGroup inputType="selectCustom"
                                                placeholder="Արտիստ"
                                                name="artist"
                                                showSearch={true}
                                                withClear={true}
                                                onPopupScroll={this.onPopupScroll}
                                                onSearch={this.onSearchArtistBounded}
                                                value={artist}
                                                onChange={this.getSelectValue}
                                                options={artistsOptions}/>
                                    <InputGroup inputType="selectCustom"
                                                placeholder="Տիպ"
                                                name="type"
                                                showSearch={false}
                                                labelInValue={false}
                                                value={type}
                                                withClear={'Բոլորը'}
                                                onChange={this.getSelectValue}
                                                options={typeOptions}/></>
                            }/>
                <ItemsList itemListInfo={itemListInfo}
                           itemListHeaderInfo={itemListHeaderInfo}
                           loading={requestLoading}
                           editLink={"/albums/edit"}
                           detailsLink={"albums/edit"}
                           isNestable={false && !artists?.text && !artists?.artist}
                           onPositionChange={this.onPositionChange}
                           infiniteScroll={true}
                           useWindow={true}
                           hasMore={albums?.hasMore}
                           loadMoreItems={this.loadMoreItems}
                />
            </section>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    DeleteAlbumById,
    UpdateAlbumsPositions,
    GetAlbums,
    UpdateAlbumById,
    GetArtists
};

export default connect(mapStateToProps, mapDispatchToProps)(Albums);
