// Import packages
import React, {useEffect, useRef} from "react";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";
import AudioPlayerLib from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import {setCurrentPlayedTrack, togglePlayState} from "../../redux/actions";
import {store} from "../../redux/store";

export const AudioPlayer = props => {
    const {currentPlayedTrack, playState} = props;
    const audioUrl = currentPlayedTrack?.path ? generateFileMediaUrl(currentPlayedTrack?.path) : '';
    const playerRef = useRef();

    useEffect(() => {
        if (currentPlayedTrack) {
            if (playState) {
                playerRef.current &&
                playerRef.current.audio &&
                playerRef.current.audio.current &&
                playerRef.current.audio.current.pause &&
                playerRef.current.audio.current.play();
            } else {
                playerRef.current &&
                playerRef.current.audio &&
                playerRef.current.audio.current &&
                playerRef.current.audio.current.pause &&
                playerRef.current.audio.current.pause();
            }
        }
    }, [playState]);

    function onPlay() {

        !playState && togglePlayState(true);
    }

    function onPause() {
        playState && togglePlayState(false);
    }

    function onEnded() {
        onClickNext();
    }

    function onClickNext() {
        const tracks = store?.getState?.().track?.tracks?.itemsList;

        if (currentPlayedTrack && tracks?.length) {
            const currentTrackIndex = tracks.findIndex(track => track?.song?.id === currentPlayedTrack.id);

            let nextTrackIndex = (currentTrackIndex + 1) % tracks?.length;
            if (currentTrackIndex === -1) {
                nextTrackIndex = 0;
            }
            tracks[nextTrackIndex] && setCurrentPlayedTrack(tracks[nextTrackIndex]?.song);
        }
    }

    function onClickPrevious() {
        const tracks = store?.getState?.().track?.tracks?.itemsList;
        if (currentPlayedTrack && tracks?.length) {
            const currentTrackIndex = tracks.findIndex(track => track?.song?.id === currentPlayedTrack.id);
            let prevTrackIndex = '';
            if (currentTrackIndex === -1) {
                prevTrackIndex = 0;
            } else if (currentTrackIndex === 0) {
                prevTrackIndex = tracks?.length - 1;
            } else {
                prevTrackIndex = currentTrackIndex - 1;
            }

            tracks[prevTrackIndex] && setCurrentPlayedTrack(tracks[prevTrackIndex]?.song);
        }
    }

    return <AudioPlayerLib src={audioUrl}
                           ref={playerRef}
                           preload={'auto'}
                           layout="horizontal-reverse"
                           onPlay={onPlay}
                           onPause={onPause}
                           onEnded={onEnded}
                           onClickPrevious={onClickPrevious}
                           onClickNext={onClickNext}
                           showSkipControls={true}
                           showJumpControls={false}
                           customAdditionalControls={[]}
                           customVolumeControls={[]}
    />
};
