import {PODCASTS_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    podcasts: {
        itemsList: [],
        text: '',
        hasMore:false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PODCASTS_CONSTS.GET_PODCASTS:
            return {
                ...state,
                podcasts: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.podcasts.itemsList, ...action.payload.data],
                    text: action.payload.text,
                    hasMore: action.payload.hasMore,
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}
