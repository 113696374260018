import {_urlReports, _urlTracks, request} from "../api";
import {TRACKS_CONSTS, UTIL_CONSTS} from "../constants";
import {LIMIT_GET_TRACKS} from "../../constants/constLimitCounts";
import {history} from "../../configs/history";
import { notificationError, notificationSuccess } from "../../components/uiElements/Notifications";
import {store} from "../store";

export const GetTracks = ({
                              reset = true,
                              offset = 0,
                              text = '',
                              artist,
                              album,
                              limit = LIMIT_GET_TRACKS,
                              isTop = null,
                              getResponse = false,
                          } = {}) => {
    let url = `${_urlTracks}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${encodeURIComponent(text)}`);
    album && (url += `&album=${album}`);
    artist && (url += `&artist=${artist}`);
    isTop !== null && (url += `&isTop=${isTop}`);

    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        !getResponse && reset && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                if (!getResponse) {
                    const filtered = !!(text || artist || album);
                    data && dispatch({
                        type: TRACKS_CONSTS.GET_TRACKS,
                        payload: {
                            data: data,
                            text,
                            artist,
                            album,
                            reset,
                            hasMore: data.length === limit,
                            filtered,
                        }
                    });
                } else {
                    return {
                        data,
                        hasMore: data.length === limit,
                    }
                }
            }).finally(() => {
                !getResponse && reset && dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetTrackById = (id) => {
    const requestData = {
        url: `${_urlTracks}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                // dispatch({
                //     type: TRACKS_CONSTS.GET_TRACK_BY_ID,
                //     payload: res.data
                // })
                return res?.data;
            })
            .catch(() => {
            })
    }
};

export const AddTrack = (data, redirect=true) => {
    const requestData = {
        url: _urlTracks,
        token: true,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(async ({data}) => {
                dispatch({
                    type: TRACKS_CONSTS.ADD_TRACK,
                    payload: data
                });
                notificationSuccess({
                    description: "Երգը հաջողությամբ ստեղծվեց!"
                })
                redirect && history.push('/dashboard')
            }).catch((err)=>{
                    if (err && err.response && err.response.status === 409) {
                        notificationError({
                            description: 'Սլագը գոյություն ունի!'
                        })
                    }
                })
    }
};

export const UpdateTrackById = (id, data, redirect = '/dashboard') => {
    const requestData = {
        url: `${_urlTracks}/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return (dispatch) => {
        return request(requestData)
            .then(({data}) => {
                // console.log('data', data)
                dispatch({
                    type: TRACKS_CONSTS.UPDATE_TRACK,
                    payload: data
                });
                redirect && notificationSuccess({
                    description: "Երգը հաջողությամբ փոփոխվեց!"
                });
                redirect && history.push({
                    pathname: redirect,
                })
            }).catch((err)=>{
                    if (err && err.response && err.response.status === 409) {
                        notificationError({
                            description: 'Սլագը գոյություն ունի!'
                        })
                    }
                })
    }
};

export const ToggleTrackVisibility = (id, data) => {
    const requestData = {
        url: `${_urlTracks}/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: TRACKS_CONSTS.TOGGLE_TRACK_VISIBILITY,
                    payload: {
                        id,
                        isHidden: data.isHidden
                    }
                });
            })
    }
};

export const DeleteTrack = (id) => {
    const requestData = {
        url: `${_urlTracks}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: TRACKS_CONSTS.DELETE_TRACK,
                    payload: id
                });
                notificationSuccess({
                    description: "Երգը հաջողությամբ ջնջվեց!"
                });
            })
    }
};

export const toggleTrackView = (state) => {
    store.dispatch({
        type: TRACKS_CONSTS.TOGGLE_TRACK_CARD_VIEW,
        payload: state
    })
}

export const GetReports = ({
                               reset = true,
                               offset = 0,
                               user,
                               track,
                               type,
                               limit = LIMIT_GET_TRACKS,
                           } = {}) => {
    let url = `${_urlReports}?offset=${offset}&limit=${limit}`;
    user && (url += `&user=${user}`);
    track && (url += `&track=${track}`);
    type && (url += `&type=${type}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: TRACKS_CONSTS.GET_REPORTS,
                    payload: {
                        data: res.data,
                        type,
                        user,
                        track,
                        reset,
                        hasMore: res.data.length === limit,
                    }
                })
                return res?.data;
            })
            .catch(() => {
            })
    }
};

export const CheckReport = (id) => {
    const requestData = {
        url: `${_urlReports}/checked/${id}`,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: TRACKS_CONSTS.CHECK_REPORT,
                    payload: id
                })
                notificationSuccess({
                    description: "Հաջողությամբ ստուգվեց!"
                })
                history.push('/reports')
            })
            .catch(() => {
            })
    }
};

export const UncheckReport = (id) => {
    const requestData = {
        url: `${_urlReports}/unchecked/${id}`,
        token: true,
        method: "PUT",
    };
    console.log('UncheckReport');
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: TRACKS_CONSTS.CHECK_REPORT,
                    payload: id
                })
                notificationSuccess({
                    description: "Հաջողությամբ ստուգվեց!"
                })
                history.push('/reports')
            })
            .catch(() => {
            })
    }
};

export const SeenReport = (id) => {
    const requestData = {
        url: `${_urlReports}/seen/${id}`,
        token: true,
        method: "PUT",
    };
    return dispatch => {
        return request(requestData)
    }
};
