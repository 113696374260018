import {request, _urlSection} from "../api";
import {SECTION_CONSTS, UTIL_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetSections = ({changeLoadingState = true} = {}) => {
    const requestData = {
        url: _urlSection,
        token: true,
        method: "GET",
    };

    return dispatch => {
        changeLoadingState && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: SECTION_CONSTS.GET_SECTIONS,
                    payload: data
                });
            }).finally(() => {
                changeLoadingState && dispatch({type: UTIL_CONSTS.END_LOADING});
            })

    }
};


export const GetSectionById = id => {
    const requestData = {
        url: `${_urlSection}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                return res?.data
            })
            .catch(() => {
            })
    }
};

export const GetSectionTracks = id => {
    const requestData = {
        url: `${_urlSection}/${id}/tracks`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                return res?.data
            })
            .catch(() => {
            })
    }
};


export const DeleteSectionById = id => {
    const requestData = {
        url: `${_urlSection}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SECTION_CONSTS.DELETE_SECTION_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateSection = data => {
    const requestData = {
        url: _urlSection,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SECTION_CONSTS.CREATE_SECTION,
                    payload: res.data
                });
                notificationSuccess({
                    description: 'Բաժինը հաջողությամբ ստեղծվեց'
                })
                history.push('/section')
            })
            .catch(() => {
            })
    }
};

export const UpdateSectionById = (id, data, redirect = true) => {
    const requestData = {
        url: `${_urlSection}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SECTION_CONSTS.UPDATE_SECTION_BY_ID,
                    payload: res.data
                });
                redirect && notificationSuccess({
                    description: 'Բաժինը հաջողությամբ փոփոխվեց!'
                })
                redirect && history.push('/section')
            })
            .catch(() => {
            })
    }
};

export const UpdateSectionsPositions = items => {
    const requestData = {
        url: _urlSection,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: 'Բաժինը հաջողությամբ փոփոխվեց!'
                })
            })
            .catch(() => {
            })
    }
};
