import {AUTH_CONSTS, GENRE_CONSTS} from "../constants";

export const initialState = {
    genreList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GENRE_CONSTS.GET_GENRES:
            return {
                ...state,
                genreList: action.payload || [],
            };
        case GENRE_CONSTS.CREATE_GENRE:
            return {
                ...state,
                genreList: [action.payload].concat(state.genreList)
            };
        case GENRE_CONSTS.UPDATE_GENRE_BY_ID:
            return {
                ...state,
                genreList: state.genreList.map(item => item.id === action.payload?.id ? action.payload : item)
            };
        case GENRE_CONSTS.DELETE_GENRE_BY_ID:
            return {
                ...state,
                genreList: state.genreList.filter(item => item.id !== action.payload)
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}
