import {resizeImage} from "../../../utils/resizeImage";
import {generateImageMediaUrl} from "../../../utils/generateMemberMediaUrl";
import {history} from "../../../configs/history";

export const userDetailsTabs = [
    {
        name: "Լսված Երգեր",
        key: "listenedTracks"
    },
    {
        name: "Հավանած երգեր",
        key: "favoriteTracks"
    },
    {
        name: "Ստեղծված երգացանկեր",
        key: "playlists"
    },
    {
        name: "Հավանած ալբոմներ",
        key: "favoriteAlbums"
    },
    {
        name: "Հավանած երգիչներ",
        key: "favoriteArtists"
    },
];

export function getDetailsLink(type) {
    switch (type) {
        case 'listenedTracks':
            return "dashboard/track/edit";
        case 'favoriteTracks':
            return "dashboard/track/edit";
        case 'favoriteAlbums':
            return "albums/edit";
        case 'favoriteArtists':
            return "artists/edit";
        default : return null
    }
}

export function getDetailsHeaderData(type, data) {
    switch (type) {
        case 'listenedTracks':
        case 'favoriteTracks':
            return ["Նկար", "Անվանում", "Կատարող"];
        case 'playlists':
            return ["Նկար", "Անվանում"];
        case 'favoriteAlbums':
            return ["Նկար", "Անվանում"];
        case 'favoriteArtists':
            return ["Նկար", "Անուն Ազգանուն"];
        default :
            return [];
    }
}

export function getDetailsListData(type, user) {
    const data = user && user[type];
    return data && Array.isArray(data) ? data.map(item => {
        switch (type) {
            case 'listenedTracks':
            case 'favoriteTracks':
                return {
                    id: item.id,
                    values: [
                        {
                            type: 'image',
                            path: resizeImage(generateImageMediaUrl(item?.coverImage?.path), 200, 200),
                        },
                        item?.title || "-",
                        item?.description || "-",
                    ],
                };
            case 'playlists':
                return {
                    id: item.id,
                    values: [
                        {
                            type: 'image',
                            path: resizeImage(generateImageMediaUrl(item?.tracks?.[0]?.coverImage?.path), 200, 200),
                        },
                        item?.name || "-",
                    ],
                    onClick: () => {
                        console.log(user?.id)
                        history.push({
                            pathname: `/users/user/playlist/${item.id}`,
                            state: {
                                user, playList: item
                            }
                        })
                    }
                };
            case 'favoriteAlbums':
                return {
                    id: item.id,
                    values: [
                        {
                            type: 'image',
                            path: resizeImage(generateImageMediaUrl(item?.coverImage?.path), 200, 200),
                        },
                        item?.title || "-",
                    ],
                };
            case 'favoriteArtists':
                return {
                    id: item.id,
                    values: [
                        {
                            type: 'image',
                            path: resizeImage(generateImageMediaUrl(item?.profileImage?.path), 200, 200),
                        },
                        item?.fullName || "-",
                    ],
                };
            default :
                return [];
        }
    }):[];
}
