// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {CustomTabs, LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import PositionedList from "../../components/PositionedList";
import {Collapse, Switch, Tree} from "antd";

// Import utils
import {
    arraysEquals,
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    CreateArtist,
    GetAlbums,
    GetArtistById,
    GetTracks,
    UpdateAlbumById,
    UpdateArtistAlbumsPositions,
    UpdateArtistById,
    UpdateArtistTracksPositions,
    UpdateTrackById,
} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {ReactComponent as ArrowIcon} from "../../assets/images/ic_select.svg";

const { Panel } = Collapse;

const tabsList = [
    {
        name: 'Ալբոմներ',
        key: 'albums'
    }, {
    name: 'Երգեր',
    key: 'tracks'
}];

class AddEditArtist extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'fullName',
            'slug',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                profileImage: undefined,
                genresIds: [],
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            fetchData: !!props?.id,
            activeTab: tabsList[0].key,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.getPatchReqData = getPatchReqData.bind(this);
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.changeTab = changeStateField.bind(this, 'activeTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.getArtistTopTracks = this.getArtistTopTracks.bind(this);
        this.addEditArtist = this.addEditArtist.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        if (isEditing) {

            const editingData = await this.props.GetArtistById(this.props?.id)
            const translationsData = {};
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        fullName: trItem?.fullName || '',
                        slug: trItem?.slug || '',
                        description: trItem?.description || '',
                    };
                });
            } else {
                history.push('/artists');
                return;
            }
            const initStateData = {
                translations: translationsData,
                profileImage: editingData?.profileImage,
                genresIds: editingData?.genres,
                isHidden: editingData?.isHidden,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData,
                fetchData: false
            });
        }
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.profileImage?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('profileImage', mediaArray[0]?.id)
                : this.updatedDataMap.delete('profileImage');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                profileImage: mediaArray[0],
            },
            errors: {
                ...errors,
                profileImage: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.profileImage ?
                this.updatedDataMap.set("profileImage", null) :
                this.updatedDataMap.delete("profileImage");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                profileImage: null
            }
        })
    }

    getArtistTopTracks(reqData) {
        return this.props.GetTracks({...reqData})
    }

    async addEditArtist() {
        await this.setState({
            requestLoading: true
        });
        const {translations, profileImage, isHidden, genresIds} = this.state.fieldsData;
        const translationRequiredFields = ['fullName', 'slug']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result) {
            const trData = getTranslationData(translations)
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    translations: trData
                };

                profileImage && (reqData.profileImage = profileImage.id);
                genresIds && (reqData.genres = genresIds);

                this.props.CreateArtist(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const reqData = this.getPatchReqData(trData)
                if (Object(reqData).hasOwnProperty('genresIds')) {
                    reqData.genres = reqData.genresIds;
                    delete reqData.genresIds;
                }
                this.props.UpdateArtistById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    getMultiSelectData = (checkedKeys, name) => {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            const updatedValues = checkedKeys?.length ? checkedKeys : []
            arraysEquals(this.currentData?.[name], checkedKeys)
                ? this.updatedDataMap.delete(name)
                : this.updatedDataMap.set(name, updatedValues);

        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [name]: checkedKeys,
            }
        })
    };

    treeData = (data, children) => {
        const treeData = [];
        data.forEach(item => {
            const treeItem = {
                title: item.title,
                key: item.id,
            };
            if (item[children] && item[children]?.length) {
                treeItem.children = this.treeData(item[children], children)
            }
            treeData.push(treeItem);
        })
        return treeData;
    };

    updateTrackItems = (data, children) => {
        const treeData = [];
        data.forEach(item => {
            const treeItem = {
                title: item.title,
                key: item.id,
            };
            if (item[children] && item[children]?.length) {
                treeItem.children = this.treeData(item[children], children)
            }
            treeData.push(treeItem);
        })
        return treeData;
    };

    render() {
        const {genreList} = this.props;
        const {
            fieldsData, errors, languageTab, mediaModalOpen, requestLoading, fetchData,
            isEditing, errorsTabs, activeTab
        } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        let containInitGenreInList = false;
        let genreOptions = this.props.genreList?.map(genre => {
            if (genre?.id === this.attachedArtistOption?.id) {
                containInitGenreInList = true;
            }
            return {
                id: genre.id,
                name: genre?.title || "",
                imageUrl: resizeImage(generateImageMediaUrl(genre?.icon?.path), 200, 200),
            }
        });
        if (!containInitGenreInList && this.attachedGenreOption) {
            genreOptions.unshift(this.attachedGenreOption);
        }
        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Արտիստ' : 'Փոփոխել Արտիստին'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            loading={fetchData}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                disabled={fetchData}
                                className={`media-select-btn ${errors.profileImage ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.profileImage && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.profileImage}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className="tree-item">
                    <Collapse
                        bordered={false}
                        expandIconPosition={'right'}
                        expandIcon={({ isActive }) => <ArrowIcon title={''}/>}>
                        <Panel header="ժանրեր" key="1">
                            <Tree
                                checkable
                                onCheck={(keys) => this.getMultiSelectData(keys, 'genresIds')}
                                checkedKeys={fieldsData.genresIds}
                                showLine={true}
                                treeData={this.treeData(genreList, "subGenres")}
                            />
                        </Panel>
                    </Collapse>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              loading={fetchData}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="row-2">
                    <div className="col-2">
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Անուն Ազգանուն"
                                    placeholder="Անուն Ազգանուն"
                                    name="fullName"
                                    loading={fetchData}
                                    value={currentData.fullName}
                                    initValue={initData.fullName}
                                    error={errors['fullName' + languageTab]}
                                    maxLength={100}
                                    required={true}
                                    onChange={this.getTranslationValues}/>
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Սլագ"
                                    placeholder="Սլագ"
                                    name="slug"
                                    loading={fetchData}
                                    value={currentData.slug}
                                    initValue={initData.slug}
                                    error={errors['slug' + languageTab]}
                                    maxLength={50}
                                    required={true}
                                    onChange={this.getTranslationValues}/>
                    </div>
                    <InputGroup inputType="textarea"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                loading={fetchData}
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                onChange={this.getTranslationValues}/>
                </div>


                {/*<div className="editor-wrapper">
                    <label>Նկարագրություն </label>
                    <Editor value={currentData.description || ''}
                            name={'description'}
                            initValue={initData.description}
                            error={errors['description' + languageTab]}
                            onChange={this.getTranslationValues}/>
                </div>*/}
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditArtist}/>
                </div>
                {isEditing && <>
                    <CustomTabs activeTab={activeTab}
                                changeTab={this.changeTab}
                                tabsList={tabsList}/>
                    <PositionedList id={this.props.id}
                                    hide={activeTab !== tabsList[0].key}
                                    title={'Արտիստի Ալբոմները'}
                                    detailsType={'artist'}
                                    itemsType={'album'}
                                    RemoveFromList={this.props.UpdateAlbumById}
                                    ToggleVisibility={this.props.UpdateAlbumById}
                                    GetItems={this.props.GetAlbums}
                                    UpdateItemsPositions={this.props.UpdateArtistAlbumsPositions}/>
                    <PositionedList id={this.props.id}
                                    hide={activeTab !== tabsList[1].key}
                                    title={'Արտիստի երգերը'}
                                    detailsType={'artist'}
                                    itemsType={'track'}
                                    RemoveFromList={this.props.UpdateTrackById}
                                    ToggleVisibility={this.props.UpdateTrackById}
                                    GetItems={this.getArtistTopTracks}
                                    UpdateItemsPositions={this.props.UpdateArtistTracksPositions}/>
                </>}

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateArtist,
    UpdateArtistById,
    GetArtistById,
    GetTracks,
    GetAlbums,
    UpdateArtistTracksPositions,
    UpdateArtistAlbumsPositions,
    UpdateTrackById,
    UpdateAlbumById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditArtist)
