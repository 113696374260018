import {SETTINGS_CONSTS} from "../constants";

export const setDefaultValues = (values) => {
    return dispatch=>{
        dispatch({
            type: SETTINGS_CONSTS.SET_DEFAULT_VALUES,
            payload: values
        })
    }
};
export const setShazamCurrentState = (state) => {
    return dispatch=>{
        dispatch({
            type: SETTINGS_CONSTS.SET_SHAZAM_CURRENT_STATE,
            payload: state
        })
    }
};
