import {TRACKS_CONSTS, AUTH_CONSTS, UTIL_CONSTS} from "../constants";

export const initialState = {
    tracks: {
        itemsList: [],
        hasMore: false,
        text: '',
        album : undefined,
        artist: undefined,
        filtered: false,
    },
    reports: {
        itemsList: [],
        hasMore: false,
        type : undefined,
        track: undefined,
        user: false,
    },
    trackById: null,
    trackCardView: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TRACKS_CONSTS.GET_TRACKS:
            return {
                ...state,
                tracks: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.tracks.itemsList, ...action.payload.data],
                    text: action.payload.text,
                    album: action.payload.album,
                    artist: action.payload.artist,
                    hasMore: action.payload.hasMore,
                    filtered: action.payload.filtered
                }
            };
        case TRACKS_CONSTS.GET_REPORTS:
            return {
                ...state,
                reports: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.reports.itemsList, ...action.payload.data],
                    user: action.payload.user,
                    track: action.payload.track,
                    hasMore: action.payload.hasMore,
                    type: action.payload.type
                }
            };
        case TRACKS_CONSTS.CHECK_REPORT:
            return {
                ...state,
                reports: {
                    itemsList: state.reports.itemsList?.map(item => item.id === action.payload ?
                        {
                            ...item,
                            checked: !item?.checked
                        } : item),
                }
            };
        case TRACKS_CONSTS.GET_TRACK_BY_ID:
            return {
                ...state,
                trackById: action.payload
            };
        case TRACKS_CONSTS.ADD_TRACK:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    itemsList: [action.payload, ...state.tracks.itemsList],
                }
            };
        case TRACKS_CONSTS.UPDATE_TRACK:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    itemsList: state?.tracks?.itemsList?.map(track => track.id === action.payload.id ?
                        action.payload : track)
                }
            };
        case TRACKS_CONSTS.TOGGLE_TRACK_VISIBILITY:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    itemsList: state?.tracks?.itemsList?.map(track => track.id === action.payload.id ?
                        {
                            ...track,
                            isHidden: action.payload.isHidden
                        } : track)
                }
            };
        case TRACKS_CONSTS.DELETE_TRACK:
            return {
                ...state,
                tracks: {
                    ...state.tracks,
                    itemsList: state.tracks.itemsList.filter(track => track.id !== action.payload),
                }
            };
        case TRACKS_CONSTS.TOGGLE_TRACK_CARD_VIEW:
            console.log(action.payload, 'LOG');
            return {
                ...state,
                trackCardView: action.payload,
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
