// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

import '../assets/styles/containerStyles/settings.scss';

// Import components
import PageWrapper from "../components/pageContentViews/pageWrapper";
import { LinkButton } from "../components/buttons/buttons";
import { InputGroup } from "../components/uiElements/inputGroup";


// Import utils
import { mapStateToProps } from "../redux/mapStateToProps";
import { GetArtistYandexTracksList } from "../redux/actions";
import { changeStateInputValue } from "../utils/helperFunctions";
import { DownloadAlbum, DownloadTrack } from "../socket/emitters";

class Yandex extends Component {
    constructor(props){
        super(props);
        this.state = {
            //id: '1053',
            albumId:'',
            trackId:''

        };
        this.getInputValues = changeStateInputValue.bind(this)
    }

    // execute = () => {
    //     const { id } = this.state;
    //     if ( id ) {
    //         this.props.GetArtistYandexTracksList(id).then((data) => {
    //             downloadData(data, 'Artist_Tracks');
    //             this.setState({ id: '' })
    //         })
    //     }
    // }

    downloadTrack = () => {
        const { albumId, trackId } = this.state;
        if ( albumId && trackId ) {
            DownloadTrack(albumId, trackId)
        }
    }
    downloadAlbum = () => {
        const { albumId } = this.state;
        if ( albumId ) {
            DownloadAlbum(albumId)
        }
    }

    render(){
        const { id, albumId, trackId } = this.state;
        const { albumDownloading, trackDownloading, downloadedAlbum, downloadedTrack, } = this.props;

        return <PageWrapper pageTitle={'Yandex'}>
            <div className={'yandex'}>
                {/*<InputGroup inputType="input"*/}
                {/*            type="text"*/}
                {/*            placeholder="Artist Yandex Id"*/}
                {/*            name="id"*/}
                {/*            value={id || ''}*/}
                {/*            onChange={this.getInputValues}/>*/}

                {/*<div className={'flex-wrapper-right'}>*/}
                {/*    <LinkButton title={'Execute'}*/}
                {/*                disabled={!id}*/}
                {/*                cb={this.execute}/>*/}
                {/*</div>*/}

                <InputGroup inputType="input"
                            type="text"
                            placeholder="Yandex Album Id"
                            label="Yandex Album Id"
                            name="albumId"
                            value={albumId || ''}
                            onChange={this.getInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            placeholder="Yandex Track Id"
                            label="Yandex Track Id"
                            name="trackId"
                            value={trackId || ''}
                            onChange={this.getInputValues}/>

                <div className={'flex-wrapper-right'}>
                    <LinkButton title={'Download Track'}
                                disabled={!trackId || !albumId}
                                loading={trackDownloading}
                                cb={this.downloadTrack}/>
                    <LinkButton title={'Download Album'}
                                disabled={!albumId}
                                loading={albumDownloading}
                                cb={this.downloadAlbum}/>
                </div>
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = { GetArtistYandexTracksList };

export default connect(mapStateToProps, mapDispatchToProps)(Yandex);
