// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Icon} from "antd";

// Import Styles
import '../../assets/styles/components/page-header.scss';

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";

// Import components
import {LinkButton} from "../buttons/buttons";
import {InputGroup} from "../uiElements/inputGroup";


class PageHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                start: '',
                end: '',
                network: undefined,
                station: undefined,
                address: undefined,
                type: undefined,
            },
        }
        this.handleChangeData = this.handleChangeData.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
    }

    handleChangeData(field, value) {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                [field]: value
            }
        })
    }

    changeFilter({name, value}) {
        this.setState({
            fieldsData: {
                ...this.state.fieldsData,
                [name]: value
            }
        });
    }

    render() {
        const {
            linkTitle, addingLink, rightBottomProps, children,
            search, rightTopProps, topProps, renderingCustomRightPart, searchValue, filters,
        } = this.props;

        return <div className="content-header">
            {(rightTopProps || topProps) && <div className={`up-part ${rightTopProps ? 'flex-right' : ''}`}>
                {topProps || rightTopProps}
            </div>}
            {children}
            <div className={"down-part"}>
                <div className={"down-part-left"}>
                    {addingLink && <LinkButton title={linkTitle}
                                               link={addingLink}/>}
                    {search && <div className="search_box">
                        <button type="button" className=" search_btn" onClick={this.props.searchItems}>
                            <Icon type="search"/>
                        </button>
                        <InputGroup inputType="input"
                                    type="text"
                                    placeholder="Որոնել"
                                    name="text"
                                    maxLength={256}
                                    onKeyDown={(e) => e && e.key === 'Enter' && this.props?.searchItems?.()}
                                    value={searchValue || ''}
                                    onChange={({value}) => {
                                        this.props.getSearchValue && this.props.getSearchValue(value);
                                    }}/>
                    </div>}
                    {renderingCustomRightPart && <div className={'down-part-right'}>
                        {renderingCustomRightPart}
                    </div>}

                </div>
                {rightBottomProps &&
                <span className={"right-bottom-part"}>{rightBottomProps}</span>}
            </div>
            {filters}
        </div>
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)
