export const TRACKS_CONSTS = {
    GET_TRACKS: "GET_TRACKS",
    GET_TRACK_BY_ID: "GET_TRACK_BY_ID",
    ADD_TRACK: "ADD_TRACK",
    UPDATE_TRACK: "UPDATE_TRACK",
    TOGGLE_TRACK_VISIBILITY: "TOGGLE_TRACK_VISIBILITY",
    DELETE_TRACK: "DELETE_TRACK",
    TOGGLE_TRACK_CARD_VIEW: "TOGGLE_TRACK_CARD_VIEW",
    GET_REPORTS: "GET_REPORTS",
    CHECK_REPORT: "CHECK_REPORT",
};
