// Import packages
import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

// Import styles
import "../../assets/styles/navbars/leftSideMenu.scss";

// Import components
import { MaterialIcon } from "../utils/Icon";

//import utils
import { ACCESS_PAGES } from "../../constants/accessPages";
import { CloseLeftSideMenu } from "../../redux/actions";
import { connect } from "react-redux";
import { mapStateToProps } from "../../redux/mapStateToProps";

class LeftSideMenu extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const {
            CloseLeftSideMenu, leftSideMenuOpen, accessPages, isAdmin,
        } = this.props;

        return <aside className={`left-side-menu custom-scrollbar-y ${leftSideMenuOpen ? "open" : ""}`}>
            <ul className="aside-menu-list" onClick={window.innerWidth <= 500 && leftSideMenuOpen && CloseLeftSideMenu}>
                <li>
                    <NavLink to={`/dashboard`} activeClassName='active'>
                        <MaterialIcon icon="dashboard"/>
                        <span>Երգեր</span>
                    </NavLink>
                </li>
                {this.props.isAdmin && <li >
                    <NavLink to="/moderators" activeClassName='active'>
                        <MaterialIcon icon="supervised_user_circle"/>
                        <span>Մոդերատորներ</span>
                    </NavLink>
                </li>}
                {
                    Object.keys(ACCESS_PAGES).map(path => {
                        const page = ACCESS_PAGES?.[path];

                        const haveAccess = isAdmin || accessPages?.includes(page?.key);
                        return haveAccess && !page?.isHidden ? <li key={path}>

                            <NavLink to={`/${path}`}
                                     activeClassName='active'>
                                <MaterialIcon icon={page?.icon}/>
                                <span>{page?.name}</span>
                            </NavLink>
                        </li> : null
                    })
                }
            </ul>

        </aside>
    }
}

const mapDispatchToProps = {
    CloseLeftSideMenu,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftSideMenu));
