import React from "react";

import {HeaderLogo} from "./headerLogo";
import {MenuDropdown} from "./MenuDropdown";

import "../../assets/styles/headerStyles/mainHeader.scss";
import {AudioPlayer} from "../uiElements/AudioPlayer";
import {MaterialIcon} from "../utils/Icon";
import {setCurrentPlayedTrack} from "../../redux/actions";

export function MainHeader(props) {
    const {toggleMenu, LogOut, isAdmin, leftSideMenuOpen, user, currentPlayedTrack, playState} = props;

    function closePlayer() {
        setCurrentPlayedTrack(null)
    }

    return <header className="main-header">
        <HeaderLogo toggleMenu={toggleMenu}
                    leftSideMenuOpen={leftSideMenuOpen}/>
        <MenuDropdown LogOut={LogOut}
                      isAdmin={isAdmin}
                      user={user}/>
        <div className={`player ${currentPlayedTrack ? 'show' : ''}`}>
            <MaterialIcon icon={'close'}
                          className={'close-btn'}
                          onClick={closePlayer}/>
            <AudioPlayer song={currentPlayedTrack}
                         playState={playState}
                         currentPlayedTrack={currentPlayedTrack}/>
        </div>
    </header>
}
