import {NOTIFICATIONS_CONSTS, AUTH_CONSTS,} from "../constants";

export const initialState = {
    createdNotification: {
        itemsList: [],
        hasMore:false,
    },
    sendedNnotification: {
        itemsList: [],
        hasMore:false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATIONS_CONSTS.GET_NOTIFICATIONS:
            return {
                ...state,
                [action.payload.sended ? 'sendedNnotification' : 'createdNotification']: {
                    itemsList: action.payload.reset ? action.payload.data : [...state[action.payload.sended ? 'sendedNnotification' : 'createdNotification'].itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                }
            };
        case NOTIFICATIONS_CONSTS.CREATE_NOTIFICATION:
            return {
                ...state,
                createdNotification: {
                    ...state.createdNotification,
                    itemsList: [action.payload].concat(state.createdNotification.itemsList)
                }
            };
        case NOTIFICATIONS_CONSTS.UPDATE_NOTIFICATION_BY_ID:
            return {
                ...state,
                createdNotification: {
                    ...state.createdNotification,
                    itemsList: state.createdNotification.itemsList.map(item => item.id === action.payload.id
                        ? action.payload : item),
                },
            };
        case NOTIFICATIONS_CONSTS.DELETE_NOTIFICATION_BY_ID:
            return {
                ...state,
                createdNotification: {
                    ...state.createdNotification,
                    itemsList: state.createdNotification.itemsList.filter(item => item.id !== action.payload),
                },
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}
