export const ACTION_TYPES = {
    AUTHENTICATION: "authentication",
    AUTHENTICATED: "authenticated",
    UNAUTHORIZED: "unauthorized",

    // socket actions
    DOWNLOAD_ALBUM: "download-album",
    DOWNLOAD_TRACK: "download-track",


    // redux actions
    ALBUM_DOWNLOADING: "ALBUM_DOWNLOADING",
    SET_DOWNLOADED_ALBUM: "SET_DOWNLOADED_ALBUM",

    TRACK_DOWNLOADING: "TRACK_DOWNLOADING",
    SET_DOWNLOADED_TRACK: "SET_DOWNLOADED_TRACK"
};
