// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Tabs} from 'antd';

// Import Styles
import '../../assets/styles/containerStyles/notifications.scss';
import '../../assets/styles/dataDisplay/tabs.scss';

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import Created from "./Created";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {LinkButton} from "../../components/buttons/buttons";
import {changeStateField} from "../../utils/helperFunctions";
import Sended from "./Sended";

const {TabPane} = Tabs;

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationTab: 'created'
        };
        this.changeNotificationTab = changeStateField.bind(this, 'notificationTab');
    }

    render() {
        const {notificationTab} = this.state;
        const {} = this.props;

        return <PageWrapper pageTitle={'Ծանուցումներ'}>
            <section className="notifications">
                <LinkButton title={notificationTab === 'created' ? 'Ստեղծել' : 'Ուղարկել'}
                            link={notificationTab === 'created' ? '/notification/add' : '/send-notification'}
                            className="notification-btn"/>
                <Tabs defaultActiveKey="1"
                      className="custom-tabs"
                      activeKey={notificationTab}
                      onChange={this.changeNotificationTab}>
                    <TabPane tab="Ստեղծված" key="created">
                        <Created/>
                    </TabPane>
                    <TabPane tab="Ուղարկված" key="sent">
                        <Sended/>
                    </TabPane>
                </Tabs>

                {/*<CustomTabs activeTab={notificationTab}*/}
                {/*            changeTab={this.changeNotificationTab}*/}
                {/*            tabsList={tabsList}/>*/}
                    {/*<TabPane tab="Ստեղծված" key="created">*/}
                    {/*    <Created/>*/}
                    {/*</TabPane>*/}
                    {/*<TabPane tab="Ուղարկված" key="sent">*/}
                    {/*    <Sended/>*/}
                    {/*</TabPane>*/}
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
