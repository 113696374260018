// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

//import styles
import '../../assets/styles/containerStyles/appended-item-section.scss';

// Import components
import Nestable from "react-nestable";
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import TrackSelectorModal from "../../components/TrackSelectorModal";
import {notificationSuccess} from "../../components/uiElements/Notifications";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations, toggleStateField,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetSectionById,
    GetSectionTracks,
    CreateSection,
    UpdateSectionById,
    UpdateTrackById
} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {history} from "../../configs/history";
import {SkeletonItem} from "../../components/uiElements/SkelatonItem";
import NestableItem from "../../components/cards/nestableItem";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";


class AddEditSection extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'slug',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                coverImage: undefined,
                tracks: [],
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
            fetchData: !!props?.id,
            fetchTracks: !!props?.id,
            tracksModalOpen: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleTracksModal = toggleStateField.bind(this, 'tracksModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.checkTrackListDifferent = this.checkTrackListDifferent.bind(this);
        this.getTracks = this.getTracks.bind(this);
        this.deleteTrack = this.deleteTrack.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
        this.addEditSection = this.addEditSection.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        if (isEditing) {
            const translationsData = {};
            const editingData = await this.props.GetSectionById(this.props.id);
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        title: trItem?.title || '',
                        slug: trItem?.slug || '',
                        description: trItem?.description || '',
                    };
                });
            } else {
                history.push('/sections');
                return;
            }
            const sectionTracks = await this.props.GetSectionTracks(this.props.id);

            const tracks = Array.isArray(sectionTracks) ?
                sectionTracks : []
            const initStateData = {
                translations: translationsData,
                coverImage: editingData?.coverImage,
                tracks: tracks,
                isHidden: editingData?.isHidden,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
                fetchData: false
            });
        }
    }

    checkTrackListDifferent(currTracks) {
        let result = false;
        const initTracks = this.currentData.tracks;
        if (!Array.isArray(currTracks) || (currTracks?.length !== initTracks.length)) {
            result = true;
        } else {
            currTracks.forEach((item, index) => {
                if (initTracks[index]?.id !== item?.id) {
                    result = true;
                }
            })
        }
        result ? this.updatedDataMap.set('tracks', '')
            : this.updatedDataMap.delete('tracks');
    }

    getTracks(tracksArray) {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.checkTrackListDifferent(tracksArray)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                tracks: tracksArray,
            }
        })
    }

    deleteTrack(id) {
        const {fieldsData, isEditing} = this.state;
        let tracks = fieldsData.tracks.filter(t => t.id !== id);

        if (isEditing) {
            this.checkTrackListDifferent(tracks)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                tracks: tracks,
            }
        })
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.coverImage?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('coverImage', mediaArray[0]?.id)
                : this.updatedDataMap.delete('coverImage');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                coverImage: mediaArray[0],
            },
            errors: {
                ...errors,
                coverImage: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.coverImage ?
                this.updatedDataMap.set("coverImage", null) :
                this.updatedDataMap.delete("coverImage");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                coverImage: null
            }
        })
    }

    toggleVisibility(id) {
        this.props.UpdateTrackById && this.props.UpdateTrackById(id,
            {
                isHidden: !this.state.fieldsData.tracks
                    .find(item => item.id === id)?.isHidden
            },
            `/section/edit/${this.props.id}`).then(() => {
            this.setState({
                fieldsData: {
                    ...this.state.fieldsData,
                    tracks: this.state.fieldsData.tracks?.map(track => track.id === id ?
                        {
                            ...track,
                            isHidden: !this.state.fieldsData.tracks
                                .find(item => item.id === id)?.isHidden
                        } : track),
                },
            })
        });
    }

    getNestableItems(list) {
        const items = [];
        list && !!list?.length && list.forEach((item, index) => {
            items.push({
                id: item?.id,
                track: item,
                data: item,
                index: index,
                title: item?.title || " ",
                contentWarning: item?.contentWarning,
                isTop: item?.isTop,
                song: item?.song,
                withImage: true,
                hideDeleteButton: false,
                path: item?.coverImage?.path,
                deleteCb: this.deleteTrack.bind(this, item?.id),
                toggleCb: this.toggleVisibility.bind(this, item?.id),
                link: {
                    pathname: `/dashboard/track/edit/${item?.id}`,
                    state: {
                        redirect: `/section/edit/${this.props.id}`
                    }
                }
            })
        });
        return items;
    }

    onPositionChange(items) {
        const {fieldsData, isEditing} = this.state;
        const tracks = items.map(item => item.track);
        if (isEditing) {
            this.checkTrackListDifferent(tracks)
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                tracks: tracks
            }
        })
    }

    /*
        loadMoreItems() {
            const {items} = this.state;
            items?.hasMore && this.props.GetItems({
                offset: items?.itemsList?.length,
                [this.props.detailsType]: this.props.id,
                getResponse: true,
            }).then(res => {
                this.setState({
                    items: {
                        itemsList: [...items.itemsList, ...res.data],
                        hasMore: res.hasMore,
                    }
                })
            });
        };
    */

    async addEditSection() {
        await this.setState({
            requestLoading: true
        });
        const {translations, coverImage, isHidden, tracks} = this.state.fieldsData;
        const translationRequiredFields = ['title', "slug"];
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        // if (!tracks.length) {
        //     errors.tracks = true;
        //     result = false;
        // }
        if (result) {
            const trData = getTranslationData(translations);
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    translations: trData
                };
                coverImage && (reqData.coverImage = coverImage.id);
                tracks.length && (reqData.tracks = tracks.map(track => track.id));

                this.props.CreateSection(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const reqData = this.getPatchReqData(trData);
                if (this.updatedDataMap.has('tracks')) {
                    reqData.tracks = tracks.map(track => track.id)
                }
                if (Object.keys(reqData)?.length) {

                    this.props.UpdateSectionById(this.props.id, reqData)
                        .finally(() => {
                            this.setState({requestLoading: false});
                        });
                } else {
                    await this.setState({requestLoading: false});
                    notificationSuccess({
                        description: 'Բաժինը հաջողությամբ փոփոխվեց!'
                    })
                    history.push('/section')
                }

            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, tracksModalOpen, requestLoading, isEditing, errorsTabs, fetchData, mediaModalOpen} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData?.translations[languageTab] || {};
        let nestableItems = this.getNestableItems(fieldsData.tracks);
        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Բաժին' : 'Փոփոխել Բաժինը'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            loading={fetchData}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար </label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                disabled={fetchData}
                                className={`media-select-btn ${errors.coverImage ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.coverImage && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.coverImage}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              loading={fetchData}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="row-2">
                    <div className="col-2">
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Անվանում"
                                    placeholder="Անվանում"
                                    name="title"
                                    loading={fetchData}
                                    required={true}
                                    value={currentData.title}
                                    initValue={initData.title}
                                    error={errors['title' + languageTab]}
                                    maxLength={100}
                                    onChange={this.getTranslationValues}/>
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Սլագ"
                                    placeholder="Սլագ"
                                    name="slug"
                                    loading={fetchData}
                                    value={currentData.slug}
                                    initValue={initData.slug}
                                    error={errors['slug' + languageTab]}
                                    maxLength={100}
                                    required={true}
                                    onChange={this.getTranslationValues}/>
                    </div>
                    <InputGroup inputType="textarea"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                loading={fetchData}
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                onChange={this.getTranslationValues}/>
                </div>


                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditSection}/>
                </div>
                <section className={'row-list-wrapper'}>
                    <div className={'label-and-action'}>
                        <label>Երգեր {/*<span className={'required-badge'}>*</span>*/}</label>
                        <LinkButton title="Ընտրել Երգեր"
                                    disabled={fetchData}
                                    className={`media-select-btn ${errors?.tracks ? 'media-select-error' : ''}`}
                                    cb={this.toggleTracksModal}/>
                    </div>
                    <div className={'items-wrapper'}>
                        {fetchData ? <div className={'loading-items'}>
                                <SkeletonItem/>
                            </div>
                            : <>{!!nestableItems?.length ?
                                <Nestable
                                    items={nestableItems}
                                    maxDepth={1}
                                    onChange={this.onPositionChange}
                                    renderItem={NestableItem}
                                />
                                :
                                <p className={'empty-text'}>Կցված երգեր չկան</p>}
                            </>}
                    </div>
                </section>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
            <TrackSelectorModal
                isOpen={!!tracksModalOpen}
                getSelectedTracks={this.getTracks}
                appendTracks={fieldsData.tracks}
                closeModal={this.toggleTracksModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetSectionById,
    GetSectionTracks,
    CreateSection,
    UpdateSectionById,
    UpdateTrackById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditSection)
