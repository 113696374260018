import {initialState as auth} from "../reducers/auth";
import {initialState as utils} from "../reducers/utils";
import {initialState as albums} from "../reducers/albums";
import {initialState as artists} from "../reducers/artists";
import {initialState as languages} from "../reducers/languages";
import {initialState as users} from "../reducers/users";
import {initialState as moderators} from "../reducers/moderators";
import {initialState as media} from "../reducers/media";
import {initialState as section} from "../reducers/section";
import {initialState as track} from "../reducers/track";
import {initialState as genre} from "../reducers/genre";
import {initialState as youTubeKey} from "../reducers/youTubeKeys";
import {initialState as device} from "../reducers/devices";
import {initialState as settings} from "../reducers/settings";
import {initialState as notifications} from "../reducers/notifications";
import {initialState as socket} from "../reducers/socket";
import {initialState as podcasts} from "../reducers/podcasts";


const reducers = {
    auth,
    utils,
    albums,
    artists,
    languages,
    users,
    track,
    moderators,
    media,
    section,
    genre,
    youTubeKey,
    device,
    settings,
    notifications,
    socket,
    podcasts,
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    })
    return sData;
};

