// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import debounce from 'lodash.debounce';

//import assets
import '../../assets/styles/containerStyles/appended-item-section.scss';
import "../../assets/styles/containerStyles/add-edit-album.scss";
import {ReactComponent as ArrowIcon} from "../../assets/images/ic_select.svg"

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import PositionedList from "../../components/PositionedList";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {Switch, Tree, Collapse} from "antd";
// import {Editor} from "../../components/uiElements/Editor";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    CreateAlbum,
    GetAlbumById,
    GetArtists,
    GetTracks,
    UpdateAlbumById,
    UpdateAlbumTracksPositions,
    UpdateTrackById,
    GetTrackById
} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {resizeImage} from "../../utils/resizeImage";
import {arraysEquals} from "../../utils/helperFunctions";
import {ALBUM_TYPES} from "../../constants/constTypes";

const { Panel } = Collapse;

const typeOptions = Object.keys(ALBUM_TYPES).map(key => ({
    id: key,
    name: ALBUM_TYPES[key].name
}));

class AddEditAlbum extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'subTitle',
            'description',
            'slug',
            'version',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                coverImage: undefined,
                year: '',
                type: `${ALBUM_TYPES.MAIN.key}`,
                artistsIds: [],
                genresIds: [],
                isHidden: false,
                contentWarning: false,
            },
            artists: {
                itemsList: [],
                hasMore: false,
                text: ''
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            fetchData: !!props?.id,
            tracksLoading: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.getPatchReqData = getPatchReqData.bind(this);
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.onPopupScroll = this.onPopupScroll.bind(this);
        this.addEditAlbum = this.addEditAlbum.bind(this);
        this.onSearchArtist = this.onSearchArtist.bind(this);
        this.onSearchArtistBounded = debounce(this.onSearchArtist, 500);
    }

    async componentDidMount() {
        const {artists} = this.props;
        const {isEditing, fieldsData,} = this.state;

        if (isEditing) {
            const editingData = await this.props.GetAlbumById(this.props?.id)
            const translationsData = {};
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        subTitle: trItem?.subTitle || '',
                        description: trItem?.description || '',
                        slug: trItem?.slug || '',
                    };
                });
            } else {
                history.push('/artists');
                return;
            }
            const initStateData = {
                translations: translationsData,
                coverImage: editingData?.coverImage,
                artistsIds: editingData?.artists?.map(a => a?.id) || [],
                genresIds: editingData?.genres || [],
                year: editingData?.year,
                type: editingData?.type,
                isHidden: editingData?.isHidden,
                contentWarning: editingData?.contentWarning,
            }
            this.attachedArtistOptions = editingData?.artists?.length && editingData?.artists.map(artist => {
                return {
                    id: artist?.id,
                    name: artist?.fullName || "",
                    imageUrl: resizeImage(generateImageMediaUrl(artist?.profileImage?.path), 200, 200),
                }
            });
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData,
                fetchData: false,
            });
        }
        if (artists?.itemsList?.length && !artists?.text) {
            this.setState({
                artists: {
                    itemsList: artists?.itemsList,
                    hasMore: artists.hasMore,
                    text: ''
                },
            })
        } else {
            this.props.GetArtists({getResponse: true}).then(res => {
                this.setState({
                    artists: {
                        itemsList: res.data,
                        hasMore: res.hasMore,
                        text: ''
                    },
                })
            })
        }
    }

    getMultiSelectData = (checkedKeys, name) => {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            const updatedValues = checkedKeys?.length ? checkedKeys : []
            arraysEquals(this.currentData?.[name], checkedKeys)
                ? this.updatedDataMap.delete(name)
                : this.updatedDataMap.set(name, updatedValues);

        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [name]: checkedKeys,
            }
        })
    };

    onPopupScroll(e) {
        e.persist();
        const {itemsList, text, hasMore} = this.state.artists;
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            // console.log(target.scrollTop, target.offsetHeight, target.scrollHeight)

            hasMore && this.props.GetArtists({
                getResponse: true,
                text,
                offset: itemsList?.length
            })
                .then(res => {
                    this.setState({
                        artists: {
                            itemsList: [...itemsList, ...res.data],
                            hasMore: res.hasMore,
                            text
                        },
                    })
                })
        }
    }

    onSearchArtist(text) {
        this.props.GetArtists({
            getResponse: true,
            text,
        }).then((res => {
            this.setState({
                artists: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: text
                },
            })
        }))
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.coverImage?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('coverImage', mediaArray[0]?.id)
                : this.updatedDataMap.delete('coverImage');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                coverImage: mediaArray[0],
            },
            errors: {
                ...errors,
                coverImage: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.coverImage ?
                this.updatedDataMap.set("coverImage", null) :
                this.updatedDataMap.delete("coverImage");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                coverImage: null
            }
        })
    }

    async addEditAlbum() {
        await this.setState({
            requestLoading: true
        });
        const {translations, coverImage, isHidden, year, type, artistsIds, genresIds, contentWarning} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'slug']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result) {
            const trData = getTranslationData(translations)
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    contentWarning,
                    translations: trData
                };

                artistsIds.length && (reqData.artists = artistsIds);
                genresIds.length && (reqData.genres = genresIds);
                coverImage && (reqData.coverImage = coverImage.id);
                year && (reqData.year = year);
                type && (reqData.type = type);

                this.props.CreateAlbum(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const reqData = this.getPatchReqData(trData);
                // console.log('reqData', reqData)
                if (Object(reqData).hasOwnProperty('artistsIds')) {
                    reqData.artists = reqData.artistsIds;
                    delete reqData.artistsIds;
                }
                if (Object(reqData).hasOwnProperty('genresIds')) {
                    reqData.genres = reqData.genresIds;
                    delete reqData.genresIds;
                }
                this.props.UpdateAlbumById(this.props.id, reqData, this.props?.location?.state?.redirect)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    treeData = (data, children) => {
        const treeData = [];
        data.forEach(item => {
            const treeItem = {
                title: item.title,
                key: item.id,
            };
            if (item[children] && item[children]?.length) {
                treeItem.children = this.treeData(item[children], children)
            }
            treeData.push(treeItem);
        })
        return treeData;
    };

    updateItemData = (id) => {
        this.props.GetTrackById(id).then((res) => {
            console.log("res", res.albums.filter(item => item.id !== this.props.id))
            return {
                albums: res.albums.filter(item => item.id !== this.props.id)
            }
        })
    };

    render() {
        const {genreList} = this.props;
        const {
            fieldsData, errors, languageTab, mediaModalOpen, requestLoading,
            isEditing, errorsTabs, artists, fetchData
        } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        //this.attachedArtistOption
        let artistsOptions = artists?.itemsList?.map(artist => {
            return {
                id: artist.id,
                name: artist?.fullName || "",
                imageUrl: resizeImage(generateImageMediaUrl(artist?.profileImage?.path), 200, 200),
            }
        });
        this.attachedArtistOptions?.length && this.attachedArtistOptions.forEach(initOption => {
            if (!artistsOptions.some(art => art?.id === initOption?.id)) {
                artistsOptions.unshift(initOption);
            }
        })

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Ալբոմ' : 'Փոփոխել Ալբոմը'}>
            <section className="general-add-edit add-edit-album">
                <div className="fixed-top-right">
                    <div className={'switch_one'}>
                        <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ակտիվ "}</label>
                        <Switch checked={!fieldsData.isHidden}
                                onChange={(checked) => this.getInputValues({
                                    name: "isHidden",
                                    value: !checked,
                                    haveChanges: this.currentData?.isHidden !== !checked
                                })}/>
                    </div>
                    <div className={'switch_two'}>
                        <label>{fieldsData.contentWarning ? "18+ " : "18+ "}</label>
                        <Switch checked={fieldsData.contentWarning}
                                onChange={(checked) => this.getInputValues({
                                    name: "contentWarning",
                                    value: checked,
                                    haveChanges: this.currentData?.contentWarning !== checked
                                })}/>
                    </div>
                </div>
                <div className="top-side">
                    <label>Նկար</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                disabled={fetchData}
                                className={`media-select-btn ${errors.coverImage ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.coverImage && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.coverImage}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <div className='row-3'>
                    <InputGroup inputType="selectCustom"
                                placeholder="Արտիստներ"
                                label="Արտիստներ"
                                name="artistsIds"
                                showSearch={true}
                                loading={fetchData}
                                mode={'multiple'}
                                onPopupScroll={this.onPopupScroll}
                                onSearch={this.onSearchArtistBounded}
                                value={fieldsData?.artistsIds}
                                initValue={this.currentData?.artistsIds}
                                onChange={this.getInputValues}
                                options={artistsOptions}/>

                    <InputGroup inputType="input"
                                type="text"
                                label="Տարի"
                                integerNumber={true}
                                placeholder="Տարի"
                                name="year"
                                loading={fetchData}
                                value={fieldsData.year}
                                initValue={this.currentData?.year}
                                error={errors.year}
                                onChange={this.getInputValues}/>
                    <InputGroup inputType="selectCustom"
                                placeholder="Տիպ"
                                label="Տիպ"
                                name="type"
                                showSearch={false}
                                labelInValue={false}
                                value={fieldsData.type}
                                onChange={this.getInputValues}
                                options={typeOptions}/>
                </div>

                <div className="tree-item">
                    <Collapse
                        bordered={false}
                        expandIconPosition={'right'}
                        expandIcon={({ isActive }) => <ArrowIcon title={''}/>}>
                        <Panel header="ժանրեր" key="1">
                            <Tree
                                checkable
                                onCheck={(keys) => this.getMultiSelectData(keys, 'genresIds')}
                                checkedKeys={fieldsData.genresIds}
                                showLine={true}
                                treeData={this.treeData(genreList, "subGenres")}
                            />
                        </Panel>
                    </Collapse>
                </div>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="row-2">
                    <div className="col-2">
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Անվանում"
                                    placeholder="Անվանում"
                                    name="title"
                                    loading={fetchData}
                                    value={currentData.title}
                                    initValue={initData.title}
                                    error={errors['title' + languageTab]}
                                    maxLength={1000}
                                    required={true}
                                    onChange={this.getTranslationValues}/>
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Ենթաանվանում"
                                    placeholder="Ենթաանվանում"
                                    name="subTitle"
                                    loading={fetchData}
                                    value={currentData.subTitle}
                                    initValue={initData.subTitle}
                                    error={errors['subTitle' + languageTab]}
                                    maxLength={50}
                                    onChange={this.getTranslationValues}/>
                        {/*<InputGroup inputType="input"*/}
                        {/*            type="text"*/}
                        {/*            label="Տարբերակ"*/}
                        {/*            placeholder="Տարբերակ"*/}
                        {/*            name="version"*/}
                        {/*            loading={fetchData}*/}
                        {/*            value={currentData.version}*/}
                        {/*            initValue={initData.version}*/}
                        {/*            error={errors['version' + languageTab]}*/}
                        {/*            maxLength={200}*/}
                        {/*            onChange={this.getTranslationValues}/>*/}
                        <InputGroup inputType="input"
                                    type="text"
                                    label="Սլագ"
                                    placeholder="Սլագ"
                                    name="slug"
                                    loading={fetchData}
                                    value={currentData.slug}
                                    initValue={initData.slug}
                                    error={errors['slug' + languageTab]}
                                    maxLength={50}
                                    required={true}
                                    onChange={this.getTranslationValues}/>
                    </div>
                    <InputGroup inputType="textarea"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                loading={fetchData}
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={8000}
                                onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditAlbum}/>
                </div>

                {isEditing && <PositionedList id={this.props.id}
                                              title={'Ալբոմի Երգերը'}
                                              detailsType={'album'}
                                              itemsType={'track'}
                                              RemoveFromList={this.props.UpdateTrackById}
                                              ToggleVisibility={this.props.UpdateTrackById}
                                              updateItemData={this.updateItemData}
                                              GetItems={this.props.GetTracks}
                                              UpdateItemsPositions={this.props.UpdateAlbumTracksPositions}/>}
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateAlbumTracksPositions,
    CreateAlbum,
    UpdateAlbumById,
    GetAlbumById,
    GetArtists,
    GetTracks,
    UpdateTrackById,
    GetTrackById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditAlbum)
