import {request, _urlUsers, _urlNotifications, _urlAlbums} from "../api";
import {UTIL_CONSTS} from "../constants";
import {notificationError, notificationSuccess} from "../../components/uiElements/Notifications";
import {history} from "../../configs/history";
import {NOTIFICATIONS_CONSTS} from "../constants/notificationConsts";
import {LIMIT_GET_ALBUMS, LIMIT_GET_NOTIFICATION} from "../../constants/constLimitCounts";

export const GetNotifications = ({
                                     reset = true,
                                     offset = 0,
                                     type = '',
                                     text = '',
                                     sended = false,
                                     limit = LIMIT_GET_NOTIFICATION,
                                     getResponse = false,
                                 } = {}) => {
    let url = `${_urlNotifications}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${text}`);
    type && (url += `&type=${type}`);
    url += `&sended=${sended}`;

    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        !getResponse && reset && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                if (!getResponse) {
                    dispatch({
                        type: NOTIFICATIONS_CONSTS.GET_NOTIFICATIONS,
                        payload: {
                            data,
                            reset,
                            sended,
                            hasMore: data.length === limit,
                        }
                    });
                } else {
                    return {
                        data,
                        hasMore: data.length === limit,
                    }
                }
            }).finally(() => {
                !getResponse && reset && dispatch({type: UTIL_CONSTS.END_LOADING})
            })

    }
};

export const GetNotificationById = (id) => {
    const requestData = {
        url: `${_urlNotifications}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                return res?.data;
            })
            .catch(() => {
            })
    }
};

export const CreateNotification = data => {
    const requestData = {
        url: _urlNotifications,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: NOTIFICATIONS_CONSTS.CREATE_NOTIFICATION,
                    payload: res.data
                });
                notificationSuccess({
                    description: 'Ծանուցումը հաջողությամբ ստեղծվեց!'
                })
                history.push('/notifications')
            })
            .catch((err) => {
                // if (err && err.response && err.response.status === 409) {
                //     notificationError({
                //         description: 'Սլագը գոյություն ունի!'
                //     })
                // }
            })
    }
};

export const UpdateNotificationById = (id, data, redirect = '/notifications') => {
    const requestData = {
        url: `${_urlNotifications}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: NOTIFICATIONS_CONSTS.UPDATE_NOTIFICATION_BY_ID,
                    payload: res.data
                });

                redirect && notificationSuccess({
                    description: 'Ծանուցումը հաջողությամբ փոփոխվեց!'
                })
                redirect && history.push({
                    pathname: redirect,
                })
            })
            .catch((err) => {
                // if (err && err.response && err.response.status === 409) {
                //     notificationError({
                //         description: 'Սլագը գոյություն ունի!'
                //     })
                // }

            })
    }
};

export const DeleteNotificationById = id => {
    const requestData = {
        url: `${_urlNotifications}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: NOTIFICATIONS_CONSTS.DELETE_NOTIFICATION_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const SendNotification = (id, data) => {
    const requestData = {
        url: `${_urlNotifications}/${id}`,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
    }
};
