import { socketConnection } from "../api/socketConnection";
import { store } from "../../redux/store";
import { isNotificationsConnected } from "../api/isConnected";
import { ACTION_TYPES } from "../constants";
import swal from "sweetalert";

export const DownloadAlbum = (albumId) => {
    const payload = { albumId };
    if ( isNotificationsConnected() ) {
        store.dispatch({
            type: ACTION_TYPES.ALBUM_DOWNLOADING,
            payload: true
        });
        socketConnection.instance.emit(ACTION_TYPES.DOWNLOAD_ALBUM, payload,
            (error, data) => {
            if (data){
                console.log('error', error)
                console.log('data', data)
                swal({
                    title: "Հաջողվեց!",
                    text: data?.failed?.length ? `faild - ${data?.failed.join(', ')}` :'',
                    icon: "success",
                    button: "Լավ",
                })
                store.dispatch({
                    type: ACTION_TYPES.SET_DOWNLOADED_ALBUM,
                    payload: data
                });
            }

                store.dispatch({
                    type: ACTION_TYPES.ALBUM_DOWNLOADING,
                    payload: false
                });
            })
    }
};

export const DownloadTrack = (albumId, trackId) => {
    const payload = { albumId, trackId };
    if ( isNotificationsConnected() ) {
        console.log('connected')
        store.dispatch({
            type: ACTION_TYPES.TRACK_DOWNLOADING,
            payload: true
        });
        socketConnection.instance.emit(ACTION_TYPES.DOWNLOAD_TRACK, payload,
            (error, data) => {
            if (data){
                swal({
                    title: "Հաջողվեց!",
                    text: data.audio?.name,
                    icon: "success",
                    button: "Լավ",
                })
                 store.dispatch({
                    type: ACTION_TYPES.SET_DOWNLOADED_TRACK,
                    payload: data
                });
            }

                store.dispatch({
                    type: ACTION_TYPES.TRACK_DOWNLOADING,
                    payload: false
                });
            })
    }
};
