// Import packages
import React, {Component} from "react";

//import utils
import '../assets/styles/containerStyles/appended-item-section.scss';

// Import components
import InfiniteScroll from "react-infinite-scroller";
import Nestable from "react-nestable";

// Import utils
import {SkeletonItem} from "./uiElements/SkelatonItem";
import NestableItem from "./cards/nestableItem";
import swal from "sweetalert";


class PositionedList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: {
                itemsList: [],
                hasMore: false,
            },
            itemsLoading: true,
        };
        this.onItemsPositionsChange = this.onItemsPositionsChange.bind(this);
        this.getNestableItems = this.getNestableItems.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    async componentDidMount() {
        this.props.GetItems({
            [this.props.detailsType]: this.props.id,
            getResponse: true,
        }).then(res => {
            this.setState({
                items: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                },
                itemsLoading: false
            })
        }).catch(() => {
            this.setState({
                itemsLoading: false
            })
        });
    }


    loadMoreItems() {
        const {items} = this.state;
        items?.hasMore && this.props.GetItems({
            offset: items?.itemsList?.length,
            [this.props.detailsType]: this.props.id,
            getResponse: true,
        }).then(res => {
            this.setState({
                items: {
                    itemsList: [...items.itemsList, ...res.data],
                    hasMore: res.hasMore,
                }
            })
        });
    };

    onItemsPositionsChange(nestableItems, item) {
        const movedItemId = item.id;
        const {items} = this.state;
        const positionedItems = nestableItems.map(item => item.id);
        if (nestableItems.findIndex(m => m.id === movedItemId) !== item?.index) {
            // first level menu was re-positioned in first level
            this.props.UpdateItemsPositions(this.props.id, positionedItems);
            const itemsList = nestableItems.map(item => item?.data);
            this.setState({
                items: {
                    ...items,
                    itemsList: itemsList
                }
            })

        }
    }

    deleteFromList(id) {
        swal({
            title: "Զգուշացում!",
            text: `Վստահ եք, որ ցանկանում եք հեռացնել?`,
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.RemoveFromList && this.props.RemoveFromList(id,
                    this.props.itemsType === 'track' ?
                    // this.props.updateItemData(id)
                        {
                            [[this.props.detailsType] + "s"]: this.state.items.itemsList
                                .find(item => item.id === id)?.[[this.props.detailsType] + "s"]
                                .filter(item => item !== this.props.id)
                        }

                    : (this.props.itemsType === 'album' ? {
                            [[this.props.detailsType] + "s"]: this.state.items.itemsList
                                .find(item => item.id === id)?.[[this.props.detailsType] + "s"]
                                .filter(item => item.id !== this.props.id).map(item => item.id)
                        }
                        : {}),
                    `/${this.props.detailsType}s/edit/${this.props.id}`).then(() => {
                    this.setState({
                        items: {
                            ...this.state.items,
                            itemsList: this.state.items.itemsList.filter(item => item.id !== id),
                        },
                    })
                });
            }
        });
    }
    toggleVisibility(id) {

                this.props.ToggleVisibility && this.props.ToggleVisibility(id,
                        {
                            isHidden: !this.state.items.itemsList
                                .find(item => item.id === id)?.isHidden
                        },
                    `/${this.props.detailsType}s/edit/${this.props.id}`).then(() => {
                    this.setState({
                        items: {
                            ...this.state.items,
                            itemsList: this.state.items.itemsList?.map(track => track.id === id ?
                                {
                                    ...track,
                                    isHidden: !this.state.items.itemsList
                                        .find(item => item.id === id)?.isHidden
                                } : track),
                        },
                    })
                });
    }

    getNestableItems(list) {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            items.push({
                id: item.id,
                data: item,
                index: index,
                title: item?.title || " ",
                subTitle: item?.subTitle || " ",
                song: item?.song,
                contentWarning: item?.contentWarning,
                isTop: item?.isTop,
                withImage: true,
                path: item?.coverImage?.path,
                deleteCb: this.props.RemoveFromList && this.deleteFromList.bind(this, item.id),
                toggleCb: this.props.ToggleVisibility && this.toggleVisibility.bind(this, item.id),
                link: {
                    pathname: this.props.itemsType === 'track' ? `/dashboard/track/edit/${item.id}` : `/albums/edit/${item.id}`,
                    state: {
                        redirect: `/${this.props.detailsType}s/edit/${this.props.id}`
                    }
                }
            })
        });
        return items;
    }

    render() {
        const {title, hide, itemsType} = this.props;
        const {items, itemsLoading} = this.state;
        let nestableItems = this.getNestableItems(items.itemsList);
        return <section className={`row-list-wrapper ${hide ? 'hide' : ''}`}>
            <div className={'label-and-action'}>
                <label>{title}</label>
            </div>
            <div className={'items-wrapper'}>
                {itemsLoading ? <div className={'loading-items'}>
                        <SkeletonItem/>
                    </div>
                    : <>{!!nestableItems.length ? <InfiniteScroll
                            hasMore={true}
                            loadMore={this.loadMoreItems}
                            pageStart={0}
                            className={'items-infinite-list'}
                            useWindow={true}
                            initialLoad={false}>
                            <Nestable
                                items={nestableItems}
                                maxDepth={1}
                                onChange={this.onItemsPositionsChange}
                                renderItem={NestableItem}
                            />
                        </InfiniteScroll>
                        :
                        <p className={'empty-text'}>{itemsType === 'album' ? 'Ալբոմներ չկան' : 'Երգեր չկան'}</p>}
                    </>}
            </div>
        </section>
    }
}

export default PositionedList
