import {AUTH_CONSTS, SETTINGS_CONSTS} from "../constants";

export const initialState = {
    defaultValues: {
        mediaDefaultValue: '',
        usTrackName: false,
        defaultNewTrackIsTop: false,
    },
    shazamCurrentState: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_CONSTS.SET_DEFAULT_VALUES:
            return {
                ...state,
                defaultValues: action.payload,
            };
        case SETTINGS_CONSTS.SET_SHAZAM_CURRENT_STATE:
            return {
                ...state,
                shazamCurrentState: action.payload,
            };
        case AUTH_CONSTS.LOG_OUT:
            return {
                ...state,
                ...initialState
            };
        default:
            return state;
    }
}
