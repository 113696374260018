// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

import '../assets/styles/containerStyles/settings.scss';
// Import components
import PageWrapper from "../components/pageContentViews/pageWrapper";
import {LinkButton} from "../components/buttons/buttons";
import {Switch} from "antd";
import {InputGroup} from "../components/uiElements/inputGroup";


// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {setDefaultValues,} from "../redux/actions";
import {changeStateInputValue} from "../utils/helperFunctions";

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _mediaDefaultValue: props?.defaultValues?.mediaDefaultValue,
            _usTrackName: props?.defaultValues?.usTrackName,
            _defaultNewTrackIsTop: props?.defaultValues?.defaultNewTrackIsTop,
        };
        this.getInputValues = changeStateInputValue.bind(this)
    }

    save = () => {
        const {_mediaDefaultValue, _usTrackName, _defaultNewTrackIsTop} = this.state;
        this.props.setDefaultValues({
            mediaDefaultValue: _mediaDefaultValue,
            usTrackName: !!_usTrackName,
            defaultNewTrackIsTop: !!_defaultNewTrackIsTop,
        })
    }

    checkUpdate = () => {
        const {mediaDefaultValue = '', usTrackName, defaultNewTrackIsTop} = this.props?.defaultValues || {};
        const {_mediaDefaultValue, _usTrackName, _defaultNewTrackIsTop} = this.state;
        // console.log('props', usTrackName, mediaDefaultValue)
        // console.log('state', _usTrackName, _mediaDefaultValue)
        return mediaDefaultValue === _mediaDefaultValue
            && (!!usTrackName === !!_usTrackName)
            && (!!defaultNewTrackIsTop === !!_defaultNewTrackIsTop)
    }

    render() {
        const {_mediaDefaultValue, _usTrackName, _defaultNewTrackIsTop} = this.state;

        return <PageWrapper pageTitle={'Settings'}>
            <div className={'settings'}>
                <InputGroup inputType="input"
                            type="text"
                            placeholder="Media default search value"
                            name="_mediaDefaultValue"
                            value={_mediaDefaultValue || ''}
                            onChange={this.getInputValues}/>
                <div className={'row'}>
                    <Switch checked={_usTrackName}
                            onChange={(checked) => this.getInputValues({
                                name: "_usTrackName",
                                value: !_usTrackName,
                            })}/>
                    <label>Use track image name us track name</label>
                </div>
                <div className={'row'}>
                    <Switch checked={_defaultNewTrackIsTop}
                            onChange={(checked) => this.getInputValues({
                                name: "_defaultNewTrackIsTop",
                                value: !_defaultNewTrackIsTop,
                            })}/>
                    <label>New track default Is Top</label>
                </div>
                <div className={'flex-wrapper-right'}>
                    <LinkButton title={'Save'}
                                disabled={this.checkUpdate()}
                                cb={this.save}/>
                </div>
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = {setDefaultValues};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
