import {_urlDevices, request} from "../api";
import {DEVICE_CONSTS, UTIL_CONSTS} from "../constants";
import {LIMIT_GET_DEVICES} from "../../constants/constLimitCounts";

export const GetDevices = ({
                               reset = true,
                               offset = 0,
                               limit = LIMIT_GET_DEVICES,
                               platform = null,
                               changeLoadingState = true,
                           } = {}) => {
    let url = `${_urlDevices}?offset=${offset}&limit=${limit}`;
    platform && (url += `&platform=${platform}`);
    const requestData = {
        url: url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && changeLoadingState && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: DEVICE_CONSTS.GET_DEVICES,
                    payload: {
                        data,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            }).finally(() => {
                reset && changeLoadingState && dispatch({type: UTIL_CONSTS.END_LOADING});
            })

    }
};
