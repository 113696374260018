// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetDevices, setPageOffset} from "../../redux/actions";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";

const platformOptions = [{id: 'iOS', name: 'IOS'}, {id: 'Android', name: 'Android'}, {id: 'Other', name: 'Other'}];

class Devices extends Component {
    constructor() {
        super();
        this.state = {
            platform: undefined,
        };
        this.getPlatformValue = this.getPlatformValue.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        const {pageOffset, devices} = this.props;
        if (pageOffset.pageName === 'devices') {
            setTimeout(() => {
                window.scrollTo(0, pageOffset.offset);
            }, 0);
            if (devices?.platform) {
                this.setState({
                    platform: devices?.platform,
                })
            }
            setPageOffset(null);
        } else {
            this.props.GetDevices();
        }

    }

    getPlatformValue({value}) {
        this.setState({
            platform: value
        });
        this.props.GetDevices({platform: value});
    };

    loadMoreItems() {
        const {devices} = this.props;
        const {platform} = this.state;
        devices?.hasMore && this.props.GetDevices({
            reset: false,
            offset: devices?.itemsList?.length,
            platform
        });
    };

    render() {
        let {devices, requestLoading} = this.props;
        let {platform} = this.state;

        let itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Համար", "Վերսիա", "Պլատֆորմ", "ՕՀ վերսիա", "Օգտատեր"],
            // fixWidths: {3: 100, 2: 100},
        };
        let itemListInfo = [];
        devices && devices.itemsList && devices.itemsList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                values: [
                    item?.deviceName || "-",
                    item?.deviceId || "-",
                    item?.appVersion || "-",
                    item?.devicePlatform || "-",
                    item?.osVersion || "-",
                    {
                        title: item?.user?.firstName ? `${item?.user?.firstName} ${item?.user?.lastName || ''}` : "-",
                        link: item?.user?.id && `/users/user/${item?.user?.id}`
                    }
                ],
            });
        });

        return <PageWrapper pageTitle={'Սարքեր'}>
            <PageHeader renderingCustomRightPart={<div className="device-filters-wrapper">
                <InputGroup inputType="selectCustom"
                            placeholder="Պլատֆորմ"
                            name="platform"
                            showSearch={false}
                            value={platform}
                            onChange={this.getPlatformValue}
                            options={platformOptions}
                            withClear={'Բոլորը'}>
                </InputGroup>
            </div>}/>
            <ItemsList itemListInfo={itemListInfo}
                       itemListHeaderInfo={itemListHeaderInfo}
                       loading={requestLoading}
                       infiniteScroll={true}
                       useWindow={true}
                       hasMore={devices?.hasMore}
                       loadMoreItems={this.loadMoreItems}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetDevices,
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
