import {UTIL_CONSTS} from "../constants";
import {store} from "../store";
import {history} from "../../configs/history";


export const ToggleLeftSideMenu = () => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.TOGGLE_LEFT_SIDE_MENU
        })
    }
};
export const CloseLeftSideMenu = () => {
    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.CLOSE_LEFT_SIDE_MENU
        })
    }
};


export const setPageOffset = (data = null) => {
    // console.log('setpageoffset', data)
    return store.dispatch({
        type: UTIL_CONSTS.SET_PAGE_OFFSET,
        payload: data || {
            pageName: '',
            offset: 0
        }
    })
};

export const setCurrentPlayedTrack = (track = null) => {
    //console.log('track', track)
    store.dispatch({
        type: UTIL_CONSTS.SET_CURRENT_PLAYED_TRACK,
        payload: track
    })
};

export const togglePlayState = (state) => {
    store.dispatch({
        type: UTIL_CONSTS.TOGGLE_PLAY_STATE,
        payload: state
    })
};



