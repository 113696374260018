// Import packages
import React from "react";
import {Route, Switch, Router, Redirect} from "react-router-dom";

// Import utils
import {history} from "../configs/history";
import {ProtectedRoute} from "./protectedRoute";

// Import pages
import AdminLayout from '../containers/AdminLayout';
import Login from '../containers/Login';

import Dashboard from '../containers/Dashboard';
import AddEditTrack from "../containers/track/AddEditTrack";

import Artists from "../containers/artists/Artists";
import AddEditArtist from "../containers/artists/AddEditArtist";

import Albums from "../containers/albums/Albums";
import AddEditAlbum from "../containers/albums/AddEditAlbum";

import Moderators from '../containers/Moderators/Moderators';
import AddEditModerator from "../containers/Moderators/AddEditModerator";

import Media from "../containers/media/Media";
import EditFile from "../containers/media/EditFile";
import EditAudioMetaData from "../containers/media/EditAudioMetaData";

import Languages from "../containers/languages/Languages";
import EditLanguage from "../containers/languages/EditLanguage";

import Section from "../containers/section/Section";
import AddEditSection from "../containers/section/AddEditSection";

import Genre from "../containers/genre/Genre";
import AddEditGenre from "../containers/genre/AddEditGenre"

import Users from "../containers/users/Users";
import UserDetails from "../containers/users/UserDetails"
import UserPlayList from "../containers/users/UserPlayList"

import YouTubeKeys from "../containers/youTubeKeys/YouTubeKeys";
import AddEditKeys from "../containers/youTubeKeys/AddEditKey";
import Devices from "../containers/devices/Devices";

import NotFound from '../containers/NotFound';
import Settings from '../containers/Settings';
import Shazam from '../containers/Shazam';
import Yandex from "../containers/Yandex";
import Notifications from "../containers/notifications/Notifications";
import AddEditNotifications from "../containers/notifications/AddEditNotifications";
import SendNotification from "../containers/notifications/SendNotification";
import Reports from "../containers/reports/Reports";
import ReportDetails from "../containers/reports/ReportDetails";
import Podcast from "../containers/podcast/Podcast";
import PodcastDetails from "../containers/podcast/PodcastDetails";

export default () => {

    return <Router history={history}>
        <Switch>
            <Redirect exact from="/" to="/login"/>
            <Route exact path="/login" component={Login}/>
            <AdminLayout>
                <Switch>
                    <Route exact path="/dashboard" component={Dashboard}/>
                    <ProtectedRoute exact path="/dashboard/track/add" component={AddEditTrack}/>
                    <ProtectedRoute exact path="/dashboard/track/edit/:id" component={AddEditTrack}/>

                    <ProtectedRoute exact path="/moderators" component={Moderators}/>
                    <ProtectedRoute exact path="/moderators/add" component={AddEditModerator}/>
                    <ProtectedRoute exact path="/moderators/edit/:id" component={AddEditModerator}/>

                    <ProtectedRoute exact path="/media" component={Media}/>
                    <ProtectedRoute exact path="/media/edit/:id" component={EditFile}/>
                    <ProtectedRoute exact path="/media/edit-audio-metadata/:id" component={EditAudioMetaData}/>

                    <ProtectedRoute exact path="/artists" component={Artists}/>
                    <ProtectedRoute exact path="/artists/add" component={AddEditArtist}/>
                    <ProtectedRoute exact path="/artists/edit/:id" component={AddEditArtist}/>

                    <ProtectedRoute exact path="/albums" component={Albums}/>
                    <ProtectedRoute exact path="/albums/add" component={AddEditAlbum}/>
                    <ProtectedRoute exact path="/albums/edit/:id" component={AddEditAlbum}/>

                    <ProtectedRoute exact path="/section" component={Section}/>
                    <ProtectedRoute exact path="/section/add" component={AddEditSection}/>
                    <ProtectedRoute exact path="/section/edit/:id" component={AddEditSection}/>

                    <ProtectedRoute exact path="/genre" component={Genre}/>
                    <ProtectedRoute exact path="/genre/add" component={AddEditGenre}/>
                    <ProtectedRoute exact path="/genre/edit/:id" component={AddEditGenre}/>

                    <ProtectedRoute exact path="/notifications" component={Notifications}/>
                    <ProtectedRoute exact path="/send-notification" component={SendNotification}/>
                    <ProtectedRoute exact path="/notification/add" component={AddEditNotifications}/>
                    <ProtectedRoute exact path="/notification/edit/:id" component={AddEditNotifications}/>

                    <ProtectedRoute exact path="/youTubeKeys" component={YouTubeKeys}/>
                    <ProtectedRoute exact path="/youTubeKeys/add" component={AddEditKeys}/>
                    <ProtectedRoute exact path="/youTubeKeys/edit/:id" component={AddEditKeys}/>

                    <ProtectedRoute exact path="/users" component={Users}/>
                    <ProtectedRoute exact path="/users/user/:id" component={UserDetails}/>
                    <ProtectedRoute exact path="/users/user/playlist/:id" component={UserPlayList}/>

                    <ProtectedRoute exact path="/devices" component={Devices}/>

                    <ProtectedRoute exact path="/reports" component={Reports}/>
                    <ProtectedRoute exact path="/reports/:id" component={ReportDetails}/>

                    <ProtectedRoute exact path="/podcasts" component={Podcast}/>
                    <ProtectedRoute exact path="/podcasts/:id" component={PodcastDetails}/>

                    <ProtectedRoute exact path="/languages" component={Languages}/>
                    <ProtectedRoute exact path="/languages/edit/:id" component={EditLanguage}/>


                    <ProtectedRoute exact path="/settings" component={Settings}/>
                    <ProtectedRoute exact path="/shazam" component={Shazam}/>
                    <ProtectedRoute exact path="/yandex" component={Yandex}/>


                    <Route exact path="/404" component={NotFound}/>
                    <Route component={NotFound}/>
                </Switch>
            </AdminLayout>
        </Switch>
    </Router>
}
