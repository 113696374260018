import {YOUTUBE_KEYS_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    youtubeKeys: {
        itemsList: [],
        platform : null,
        type : null,
        allowUse : null,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case YOUTUBE_KEYS_CONSTS.GET_YOUTUBE_KEYS:
            return {
                ...state,
                youtubeKeys: {
                    itemsList: action.payload.data ,
                    platform: action.payload.platform,
                    type: action.payload.type,
                    allowUse: action.payload.allowUse,
                }
            };
        case YOUTUBE_KEYS_CONSTS.CREATE_YOUTUBE_KEY:
            return {
                ...state,
                youtubeKeys: {
                    ...state.youtubeKeys,
                    itemsList: [action.payload].concat(state.youtubeKeys.itemsList)
                }
            };
        case YOUTUBE_KEYS_CONSTS.UPDATE_YOUTUBE_KEY_BY_ID:
            return {
                ...state,
                youtubeKeys: {
                    ...state.youtubeKeys,
                    itemsList: state.youtubeKeys.itemsList.map(key => key.id === action.payload.id
                        ? action.payload : key),
                },
            };
        case YOUTUBE_KEYS_CONSTS.DELETE_YOUTUBE_KEY_BY_ID:
            return {
                ...state,
                youtubeKeys: {
                    ...state.youtubeKeys,
                    itemsList: state.youtubeKeys.itemsList.filter(key => key.id !== action.payload),
                },
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}
