// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/user-details.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import {SkeletonSmallItem} from "../../components/uiElements/SkelatonISmalltem";
import TrackCard from "../../components/cards/TrackCard";
import {LinkButton} from "../../components/buttons/buttons";
import PageHeader from "../../components/pageContentViews/pageHeader";


// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";
import {ClearUserById, GetUserById, toggleTrackView, ToggleUserBlockState,} from "../../redux/actions";


class UserPlayList extends Component {


    render() {
        const {requestLoading, trackCardView} = this.props;
        const {user, playList}=this.props?.location?.state || {};
        console.log(playList?.tracks)
        return <PageWrapper pageTitle={`Երգացանկ - ${playList?.name}`}>
            <section className="user-playList-details">
                <div className="top-part">
                    <div className="user-image">
                        <img src={generateFileMediaUrl(user?.profilePicturePath?.path)} alt="user"/>
                    </div>
                    <div className="user-info">
                        <span className="username">{
                            requestLoading ?
                                <SkeletonSmallItem/> : `${user?.firstName || ""} ${user?.lastName || ""}`
                        }
                        </span>
                        <div>{
                            requestLoading ?
                                <SkeletonSmallItem/> : `${user?.username || ""}`
                        }</div>
                    </div>
                    <div className={'toggle-track-view-in-playList'}>
                        <LinkButton
                            className={`list-view-button ${trackCardView ? 'selected' : ''}`}
                            title={<img src={require("../../assets/images/file-manager/list.svg")} alt=""/>}
                            cb={() => toggleTrackView(true)}/>
                        <LinkButton
                            className={`list-view-button ${!trackCardView ? 'selected' : ''}`}
                            title={<img src={require("../../assets/images/file-manager/grid.svg")} alt=""/>}
                            cb={() => toggleTrackView(false)}/>
                    </div>
                </div>
                <div className={'playlist-tracks-wrapper'}>
                    {
                        !!playList?.tracks?.length && playList?.tracks.map(item => {
                            return <TrackCard key={item?.id}
                                              goTrackEditPage={this.goTrackEditPage}
                                              listView={trackCardView}
                                              data={item}/>
                        })
                    }
                </div>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetUserById,
    ClearUserById,
    ToggleUserBlockState
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPlayList)
