import React from "react";
import {store} from "../../redux/store";

import "../../assets/styles/dataDisplay/nestableItem.scss";
import {ReactComponent as ContentWarning} from "../../assets/images/ic_content_warning.svg";

import {MaterialIcon} from "../utils/Icon";
import {Image} from "../utils/Image";
import {LinkButton} from "../buttons/buttons";

import {setCurrentPlayedTrack, togglePlayState} from "../../redux/actions";
import {ALBUM_TYPES, PODCAST_TYPES} from "../../constants/constTypes";
import {Dropdown, Icon, Menu} from "antd";


function NestableItem({item}){
    const {
        index, withImage, path, description, title, subTitle, link, contentWarning, isHidden = null,
        color, deleteCb, toggleCb, hideDeleteButton = false, data, isTop, song, hideIsHiddenBtn = false,
        showMenu = false, PodcastReview, openRejectModal, setPodcastId
    } = item;
    const {playState, currentPlayedTrack} = store.getState().utils;

    const showIsHidden = isHidden !== null;

    const isCurrentTrack = currentPlayedTrack?.id === song?.id;
    const iconState = isCurrentTrack && playState;

    //functions
    const onClickPlayButton = () => {
        if (playState) {
            if (currentPlayedTrack?.id === song?.id) {
                togglePlayState(false);
            } else {
                setCurrentPlayedTrack(song);
            }
        } else {
            if (currentPlayedTrack?.id === song?.id) {
                togglePlayState(true);
            } else {
                setCurrentPlayedTrack(song);
                togglePlayState(true);
            }
        }
    };

    const menu = (<Menu onClick={(e) => {
        e.domEvent.stopPropagation();
        e.domEvent.preventDefault();
    }}>
        <Menu.Item key="1" onClick={() => {
            PodcastReview(data?.id, {status: PODCAST_TYPES.ACCEPTED.key})
        }}>
            {PODCAST_TYPES.ACCEPTED?.action}
        </Menu.Item>
        <Menu.Item key="1" onClick={() => {
            openRejectModal()
            setPodcastId(data?.id)
        }}>
            {PODCAST_TYPES.REJECTED?.action}
        </Menu.Item>
    </Menu>);

    return <div className={`nestable-list-item ${withImage ? ' largeItem ' : ''}`}>
        <div className="nestable-item-inner">
            <div className="numbering">
                <span>{index + 1}</span>
            </div>
            {isTop && <div className={`badge`}>Top</div>}
            {withImage &&
            <div className="item-image">
                <Image path={path}/>
                {song?.path &&
                <div className="player" onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}>
                    <button className={`play-button ${isCurrentTrack ? 'colored' : ''}`}
                        onClick={onClickPlayButton}
                    >
                        {iconState ? <img src={require("../../assets/images/ic_pause_white.svg")} alt=""/> :
                            <img src={require("../../assets/images/ic_play_white.svg")} alt=""/>}
                    </button>
                </div>}
            </div>}

            <div className="item-content-wrapper">
                {title && description ? <div className="info-wrapper-flex">
                    <div className="item-title">
                        <span>{title}</span>
                        {contentWarning && <ContentWarning title={''} className="e-icon"/>}
                    </div>
                    <div className="item-description">
                        <span>{description}</span>
                    </div>
                </div> : <>
                    {title &&
                    <div className="item-title">
                    <span>
                        {title}
                        {subTitle && <span className="item-subTitle">{subTitle}</span>}
                        {contentWarning && <ContentWarning title={''} className="e-icon"/>}
                    </span>
                    </div>}
                    {description &&
                    <div className="item-description">
                        <span>{description}</span>
                    </div>}

                </>}

                {
                    data?.year && <span className="item-date">
                    {data?.year}
                </span>
                }
                {
                    data?.type && <span className="item-type">
                    {ALBUM_TYPES[data?.type]?.name}
                </span>
                }
            </div>

            {showIsHidden &&
            <div className="status">
                <span>{isHidden ? "Ոչ ակտիվ " : "Ակտիվ "}</span>
            </div>}
            {color &&
            <div className={'color'} style={{backgroundColor: color}}/>}
            <div className="action-buttons">
                {!hideIsHiddenBtn && <LinkButton cb={toggleCb}
                             key={item}
                             className={!data?.isHidden ? "bg-green" : "bg-grey"}
                             title={<MaterialIcon
                                 icon={!data?.isHidden ? 'visibility_off' : 'visibility'}/>}/>}
                {link && <LinkButton link={link} title={<MaterialIcon icon="edit"/>}/>}
                {!hideDeleteButton && <LinkButton className={'bg-red'}
                                                  cb={deleteCb}
                                                  disabled={!deleteCb}
                                                  title={<MaterialIcon icon="delete"/>}/>}
                {showMenu && data.status === PODCAST_TYPES.PENDING.key && <Dropdown overlay={menu}
                                       placement={"bottomRight"}>
                    <Icon className="more" type="more" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault()
                    }}/>
                </Dropdown>}

            </div>
        </div>
    </div>
}

export default NestableItem
