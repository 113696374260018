// Import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/containerStyles/appended-item-section.scss';

// Import components
import Nestable from "react-nestable";
import PageWrapper from '../../components/pageContentViews/pageWrapper'

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetPodcastById, PodcastReview} from "../../redux/actions";
import {SkeletonItem} from "../../components/uiElements/SkelatonItem";
import NestableItem from "../../components/cards/nestableItem"
import RejectModal from "./rejectModal/RejectModal";


function PodcastDetails(props) {
    const [tracks, setTracks] = useState([])
    const [fetchData, setFetchData] = useState(!!props?.id)
    const [previewModalOpen, setModalOpen] = useState(false);
    const [reviewPodcastId, setReviewPodcastId] = useState('');

    useEffect( () => {
        if (!!props?.id) {
          mount()
        }
    }, [])

    //functions
    const mount = async () => {
        const editingData = await props.GetPodcastById(props.id);
        setTracks(editingData)
        setFetchData(false)
    }

    const openRejectModal = () => {
        setModalOpen(true)
    }

    const closeRejectModal = () => {
        setModalOpen(false)
    }

    const setPodcastId = (id) => {
        setReviewPodcastId(id)
    }

    const toggleReview = (id, data) => {
        props.PodcastReview(id, data)
        setTracks(tracks.map(item => item.id === id ? {
            ...item,
            status: data.status
        } : item))
    }

    function getNestableItems(list) {
        const items = [];
        list && !!list?.length && list.forEach((item, index) => {
            items.push({
                id: item?.id,
                track: item,
                data: item,
                index: index,
                title: item?.title || " ",
                contentWarning: item?.contentWarning,
                isTop: item?.isTop,
                song: item?.song,
                withImage: true,
                hideDeleteButton: true,
                hideIsHiddenBtn: true,
                showMenu: true,
                path: item?.coverImage?.path,
                openRejectModal: openRejectModal,
                setPodcastId: setPodcastId,
                PodcastReview: toggleReview,
            })
        });
        return items;
    }

    /*
        loadMoreItems() {
            const {items} = this.state;
            items?.hasMore && this.props.GetItems({
                offset: items?.itemsList?.length,
                [this.props.detailsType]: this.props.id,
                getResponse: true,
            }).then(res => {
                this.setState({
                    items: {
                        itemsList: [...items.itemsList, ...res.data],
                        hasMore: res.hasMore,
                    }
                })
            });
        };
    */

    let nestableItems = getNestableItems(tracks);
    return <PageWrapper pageTitle={'Փոդքաստ'}>
        <section className="general-add-edit">
            <section className={'row-list-wrapper'}>
                <div className={'items-wrapper'}>
                    {fetchData ? <div className={'loading-items'}>
                            <SkeletonItem/>
                        </div>
                        : <>{!!nestableItems?.length ?
                            <Nestable
                                items={nestableItems}
                                maxDepth={0}
                                renderItem={NestableItem}
                            />
                            :
                            <p className={'empty-text'}>Կցված Փոդքաստեր չկան</p>}
                        </>}
                </div>
            </section>
        </section>
        <RejectModal visible={previewModalOpen}
                     PodcastReview={toggleReview}
                     id={reviewPodcastId}
                     closeModal={closeRejectModal}/>
    </PageWrapper>
}

const mapDispatchToProps = {
    GetPodcastById,
    PodcastReview
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastDetails)
