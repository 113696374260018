// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";


// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetArtists, UpdateArtistsPositions, UpdateArtistById,
    DeleteArtistById, setPageOffset,
} from "../../redux/actions";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";


class Artists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
        };
        this.getSearchValue = this.getSearchValue.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.deleteItem = deleteItem.bind(this, props.DeleteArtistById, 'արտիստին');
    }

    componentDidMount() {
        const {pageOffset, artists} = this.props
        //console.log('pageOffset',pageOffset);
        if (pageOffset.pageName === 'artists') {
            setTimeout(() => {
                window.scrollTo(0, pageOffset.offset);
            }, 0);
            if (artists?.text) {
                this.setState({
                    text: artists?.text,
                })
            }
            setPageOffset(null);
        } else {
            this.props.GetArtists();
        }
    }

    getSearchValue(text) {
        //console.log('text', text)
        this.setState({
            text
        }, () => !text && this.searchItems())
    }

    searchItems() {
        const {text} = this.state;
        text?.length !== 1 && this.props.GetArtists({
            text,
        });
    }

    loadMoreItems() {
        const {artists} = this.props;
        const {text} = this.state;
        artists?.hasMore && this.props.GetArtists({
            reset: false,
            offset: artists?.itemsList?.length,
            text,
        });
    };

    async onPositionChange(items, item) {
        const movedItemId = item.id;
        const {artists} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~artists.itemsList.findIndex(m => m.id === movedItemId) &&
            ~positionedItems.findIndex(m => m.id === movedItemId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateArtistsPositions(positionedItems);
            this.props.GetArtists({
                changeLoadingState: false,
                limit: items?.length,
            });
        }
    }


    render() {
        let {text} = this.state;
        let {artists, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Նկար", "Անուն Ազգանուն"],
            status: true,
            fixWidths: {0: 60},
            styles: {
                1: {justifyContent: 'flex-start'}
            },
            actions: ["toggle", "edit", "delete"],
        };
        let itemListInfo = [];
        artists && artists.itemsList && artists.itemsList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                status: !item?.isHidden,
                values: [
                    {
                        type: 'image',
                        path: resizeImage(generateImageMediaUrl(item?.profileImage?.path), 200, 200),
                    },
                    item?.fullName || "-",
                ],
                deleteCb: this.deleteItem.bind(this, item.id),
                toggleCb: ()=>this.props.UpdateArtistById(item.id, {isHidden: !item?.isHidden}, false)
            });
        });

        return <PageWrapper pageTitle={'Արտիստներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/artists/add"}
                        getSearchValue={this.getSearchValue}
                        searchItems={this.searchItems}
                        searchValue={text}
                        search={true}/>
            <ItemsList itemListInfo={itemListInfo}
                       itemListHeaderInfo={itemListHeaderInfo}
                       loading={requestLoading}
                       editLink={"/artists/edit"}
                       detailsLink={"artists/edit"}
                       isNestable={false && !artists?.text}
                       onPositionChange={this.onPositionChange}
                       infiniteScroll={true}
                       useWindow={true}
                       hasMore={artists?.hasMore}
                       loadMoreItems={this.loadMoreItems}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetArtists,
    UpdateArtistById,
    DeleteArtistById,
    UpdateArtistsPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Artists);
