// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {ColorPicker} from "../../components/uiElements/ColorPicker";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    CreateGenre,
    UpdateGenreById,
    GetGenreById,
} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";


class AddEditGenre extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'slug',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                icon: undefined,
                color: '#ffffff',
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            fetchData: !!props?.id,
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.getPatchReqData = getPatchReqData.bind(this);
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditGenre = this.addEditGenre.bind(this);
    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;
        if (isEditing) {

            const editingData = await this.props.GetGenreById(this.props?.id);
            // const editingData=genreList?.find(genre=>genre.id === id);
            const translationsData = {};
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        slug: trItem?.slug || ''
                    };
                });
            } else {
                history.push('/genre');
                return;
            }
            const initStateData = {
                translations: translationsData,
                icon: editingData?.icon,
                color: editingData?.color,
                isHidden: editingData?.isHidden,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData,
                fetchData: false
            });
        }
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.icon?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('icon', mediaArray[0]?.id)
                : this.updatedDataMap.delete('icon');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                icon: mediaArray[0],
            },
            errors: {
                ...errors,
                icon: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.icon ?
                this.updatedDataMap.set("icon", null) :
                this.updatedDataMap.delete("icon");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                icon: null
            }
        })
    }

    async addEditGenre() {
        await this.setState({
            requestLoading: true
        });
        const {translations, icon, color, isHidden,} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'slug']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result) {
            const trData = getTranslationData(translations)
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    color,
                    translations: trData
                };

                icon && (reqData.icon = icon.id);
                this.props.CreateGenre(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const reqData = this.getPatchReqData(trData)
                this.props.UpdateGenreById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, fetchData, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել ժանր' : 'Փոփոխել ժանրը'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            loading={fetchData}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                <div className="top-side">
                    <label>Նկար</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                disabled={fetchData}
                                className={`media-select-btn ${errors.icon ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.icon && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.icon}
                                   customDelete={this.deleteMedia}
                    />
                </div>}
                <ColorPicker color={fieldsData.color}
                             loading={fetchData}
                             initColor={this.currentData?.color}
                             onChange={this.getInputValues}/>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="row-1">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                loading={fetchData}
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Սլագ"
                                placeholder="Սլագ"
                                name="slug"
                                loading={fetchData}
                                value={currentData.slug}
                                initValue={initData.slug}
                                error={errors['slug' + languageTab]}
                                maxLength={50}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditGenre}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateGenre,
    UpdateGenreById,
    GetGenreById,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditGenre)
