// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import moment from "moment";

// Import Styles
import '../../assets/styles/containerStyles/notifications.scss';

// Import components
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {GetNotifications, DeleteNotificationById} from "../../redux/actions";
import {NOTIFICATION_TYPES} from "../../constants/constTypes";
import {deleteItem} from "../../utils/helperFunctions";

class Sended extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetNotifications({sended: true})
    }

    loadMoreItems() {
        const {sendedNnotification} = this.props;
        sendedNnotification?.hasMore && this.props.GetNotifications({
            reset: false,
            sended: true,
            offset: sendedNnotification?.itemsList?.length,
        });
    };

    render() {
        const {} = this.state;
        const {sendedNnotification, requestLoading, mainLanguage} = this.props;

        let itemListHeaderInfo = {
            generalInfo: ["Նկար", "Անվանում", "Ամսատիվ", "Տիպ"],
            styles: {
                1: {justifyContent: 'flex-start'},
            },
        };

        let itemListInfo = [];
        !!sendedNnotification?.itemsList?.length && sendedNnotification.itemsList.forEach(item => {
            let trItem = item?.translations?.find(lg => lg.languageId ===  mainLanguage);

            itemListInfo.push({
                id: item.id,
                values: [
                    {
                        type: 'image',
                        path: resizeImage(generateImageMediaUrl(item?.image?.path), 200, 200),
                    },
                    trItem?.title || "-",
                    moment(item?.createdAt).format('DD/MM/YYYY HH:mm'),
                    NOTIFICATION_TYPES[item?.type].name
                ],
            });
        });

        return <div className="created-notifications">
            <ItemsList itemListInfo={itemListInfo}
                       itemListHeaderInfo={itemListHeaderInfo}
                       loading={requestLoading}
                       editLink={"/notification/edit"}
                       detailsLink={"notification/edit"}
                       infiniteScroll={true}
                       useWindow={true}
                       hasMore={sendedNnotification?.hasMore}
                       loadMoreItems={this.loadMoreItems}
            />
        </div>
    }
}

const mapDispatchToProps = {
    GetNotifications,
    DeleteNotificationById
};

export default connect(mapStateToProps, mapDispatchToProps)(Sended)
