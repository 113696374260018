function findParent(currentItem, id){
    if (  currentItem?.children?.length && currentItem.children.find(item => item.id === id) ) {
        let movedItemNewIndex = -1;
        const positionedItems = currentItem.children.map((item, index) => {
            item.id === id && (movedItemNewIndex = index);
            return { id: item.id, position: index }
        });
        return {
            parentId: currentItem.id,
            movedItemNewIndex: movedItemNewIndex,
            positionedItems
        };
    } else {
        let resData = null;
        currentItem.children && currentItem.children.length && currentItem.children.forEach(item => {
            let result = findParent(item, id);
            if ( result ) {
                resData = result;
            }
        });
        return resData;
    }
}

export async function onPositionChange(functions, items, item){
    console.log('items', items);
    console.log('item', item);
    const { UpdateItemById, UpdateItemsPositions, GetItems } = functions;
    const movedItemId = item.id;
    let movedItemParent = null;
    items && items.forEach(item => {
        let currItemRes = findParent(item, movedItemId);
        if ( currItemRes ) {
            movedItemParent = currItemRes;
        }
    });
    //console.log('movedItemParent',movedItemParent)
    if ( movedItemParent ) {
        if ( (movedItemParent.parentId !== item.parentId) || (movedItemParent.movedItemNewIndex !== item.index) ) {
            const resData = {
                parentId: movedItemParent.parentId,
            };
            (movedItemParent.parentId !== item.parentId) &&
            await UpdateItemById(movedItemId, resData, false);
           await UpdateItemsPositions(movedItemParent.positionedItems);
           GetItems({changeLoadingState: false});
        }
    } else {
        // first level menu was re-positioned in first level
        const newIndex = items.findIndex(m => m.id === movedItemId);
        if ( item.parentId || item.index !== newIndex ) {
            const positionedItems = items.map((item, index) => {
                return { id: item.id, position: index }
            });
            const resData = {
                parentId: null,
            };

            item.parentId && UpdateItemById(movedItemId, resData, false);
            await UpdateItemsPositions(positionedItems);
            GetItems({changeLoadingState: false});
        }
    }
}
