import {AUTH_CONSTS, DEVICE_CONSTS} from "../constants";

export const initialState = {
    devices: {
        itemsList: [],
        hasMore:false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case DEVICE_CONSTS.GET_DEVICES:
            return {
                ...state,
                devices: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.devices.itemsList, ...action.payload.data],
                    hasMore: action.payload.hasMore,
                }
            };

        case AUTH_CONSTS.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}
