// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetPodcasts} from "../../redux/actions";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";


class Podcast extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {podcasts} = this.props;
        !podcasts?.length && this.props.GetPodcasts();
    }

    render() {
        let {podcasts, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Նկար", "Անվանում","Օգտատեր", "Քանակ"],
            status: true,
            actions: ["details"],
        };
        let itemListInfo = [];
        !!podcasts?.itemsList?.length && podcasts.itemsList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                status: !item?.isHidden,
                fixWidths: {0: 60},
                styles: {
                    1: {justifyContent: 'flex-start'}
                },
                values: [
                    {
                        type: 'image',
                        path: resizeImage(generateImageMediaUrl(item?.coverImage?.path), 200, 200),
                    },
                    item?.name || "-",
                    item?.owner?.firstName + " " + item?.owner?.lastName,
                    item?.podcastsCount || 0,
                ],
            });
        });

        return <PageWrapper pageTitle={'Փոդքաստեր'}>

            <ItemsList itemListInfo={itemListInfo}
                       itemListHeaderInfo={itemListHeaderInfo}
                       loading={requestLoading}
                       detailsLink={"podcasts"}
                       isNestable={false}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetPodcasts
};

export default connect(mapStateToProps, mapDispatchToProps)(Podcast);
