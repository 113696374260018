// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

import {ReactComponent as ContentWarning} from "../../assets/images/ic_content_warning.svg";
import "../../assets/styles/containerStyles/report-details.scss"
// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {Switch} from "antd";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CheckReport, GetReports, SeenReport, UncheckReport,} from "../../redux/actions";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {REPORT_TYPES} from "../../constants/constTypes";

class ReportDetails extends Component {
    constructor() {
        super();
        this.state = {
            report: {}
        }
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
    }

    componentDidMount() {
        const {id} = this.props;
        this.props.GetReports().then((res) => {
            this.setState({
                report: res?.find(item => item.id === id)
            })
        });
        this.props.SeenReport(id)
    }

    toggleCheckbox() {
        console.log('toggleCheckbox', this.state.report.checked);
        !this.state.report.checked ?
        this.props.CheckReport(this.state.report.id) :
        this.props.UncheckReport(this.state.report.id)

    }

    render() {
        let {report} = this.state;

        return <PageWrapper pageTitle={'Հաշվետվություն'}>
            <div className="top-side">
                <img src={resizeImage(generateImageMediaUrl(report?.track?.coverImage?.path))} alt=""/>
                <div>
                    <span className={"info-title-wrapper"}>
                        <div className={'info-title'}>
                            {report?.track?.title || "-"}
                            <span className={'info-sub-title'}>{report?.track?.subTitle || ""}</span>
                        </div>
                        {report?.contentWarning && <ContentWarning title={''} className="e-icon"/>}
                    </span>
                    <div className="track-item-description ">
                        {!!report?.track?.artists?.length && report?.track?.artists?.map((item, index) => {
                            return !item?.isHidden ?
                                <span className="track-artist" key={item.id}>
                            {item?.fullName}
                                    {index + 1 < report?.track?.artists?.length && <span>,&nbsp;</span>}
                    </span>

                                : <span className="track-item-description"
                                        key={item.id}>
                            {item?.fullName}
                                    {index + 1 < report?.track?.artists?.length && <span>,&nbsp;</span>}
                        </span>
                        })}</div>
                </div>

                <div className={'check-item'}>
                    <label>{!report?.checked ? "Կատարված" : "Կատարված"}</label>
                    <span onClick={this.toggleCheckbox} className={`checkbox ${report?.checked ? 'checked' : ''}`}>
                        <small>&#10003;</small></span>
                </div>
            </div>

            <div className="types-wrapper">
                {
                    report?.types?.map(item => {
                        return <div className="type-item" key={item}>{REPORT_TYPES[item].value}</div>
                    })
                }
                {report?.title && <div className="title">{report?.title}</div>}
                {report?.description && <div className="description">{report?.description}</div>}
            </div>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetReports,
    UncheckReport,
    CheckReport,
    SeenReport
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetails);
