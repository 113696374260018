export const notShowErrorsRequests = [
    {
        url: 'admin/files/folder',
        methods: ['post']
    },   {
        url: '/albums',
        methods: ['post', 'patch']
    }, {
        url: '/tracks',
        methods: ['post', 'patch']
    }, {
        url: '/artists',
        methods: ['post', 'patch']
    }
]
