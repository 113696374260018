//--------------------------------------- 4Steps-server --------------------------------------------

// const AUTH_URL = "http://173.249.20.192:8500/api/v2";
// const ADMIN_URL =  "http://173.249.20.192:8510/api/v2";
// const FILE_MANAGER_URL =  "http://173.249.20.192:8502/api/v2";
// export const HOST_MEDIA_URL =  "http://173.249.20.192:8502/v2";

// const AUTH_URL = "http://localhost:3010/api/v2";
// const ADMIN_URL =  "http://localhost:4010/api/v2";
// const FILE_MANAGER_URL =  "http://localhost:3040/api/v2";
// export const HOST_MEDIA_URL =  "http://localhost:3040/v2";


//--------------------------------------- 4Steps-server Domain --------------------------------------------

const AUTH_URL = "https://api.youtop.am/auth/api/v2";
const ADMIN_URL =  "https://api.youtop.am/admin/api/v2";
// const FILE_MANAGER_URL =  "http://173.249.20.192:8502/api/v2";
const FILE_MANAGER_URL =  "https://api.youtop.am/resources/api/v2";
const SOCKET_URL = "http://173.249.20.192:8502";
export const HOST_MEDIA_URL =  "https://api.youtop.am/resources/v2";


export const _urlOauth = AUTH_URL + "/jwt";
export const _urlMedia = FILE_MANAGER_URL + "/admin/files";
export const _urlMediaAudio = FILE_MANAGER_URL + "/admin/song";
export const _urlArtists = ADMIN_URL + "/artists";
export const _urlAlbums = ADMIN_URL + "/albums";
export const _urlLanguages = ADMIN_URL + "/languages";
export const _urlUsers = ADMIN_URL + "/users";
export const _urlModerator = ADMIN_URL + "/moderators";
export const _urlSection = ADMIN_URL + "/sections";
export const _urlTracks = ADMIN_URL + "/tracks";
export const _urlGenres = ADMIN_URL + "/genres";
export const _urlNotifications = ADMIN_URL + "/notifications";
export const _urlYouTubeKeys = ADMIN_URL + "/youtube-keys";
export const _urlDevices = ADMIN_URL + "/devices";
export const _urlYandex = ADMIN_URL + "/helpers";
export const _urlReports = ADMIN_URL + "/reports";
export const _urlPodcast = ADMIN_URL + "/podcasts-lists";
export const _urlSocket = SOCKET_URL;

