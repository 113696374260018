import {_urlAlbums, request} from "../api";
import {ALBUMS_CONSTS, UTIL_CONSTS} from "../constants";
import {history} from "../../configs/history";
import {LIMIT_GET_ALBUMS} from "../../constants/constLimitCounts";
import {notificationError, notificationSuccess} from "../../components/uiElements/Notifications";

export const GetAlbums = ({
                              reset = true,
                              offset = 0,
                              text = '',
                              artist = null,
                              type = false,
                              types = false,
                              artists = [],
                              limit = LIMIT_GET_ALBUMS,
                              getResponse = false,
                              changeLoadingState = true,
                          } = {}) => {
    let url = `${_urlAlbums}?offset=${offset}&limit=${limit}`;
    artist && (artists = [artist]);
    artists && Array.isArray(artists) && artists.length && (url += `&artistIds=${artists.join(',')}`);
    text && (url += `&text=${encodeURIComponent(text)}`);
    type && (url += `&type=${type}`);
    types && (url += `&types=${types}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        !getResponse && reset && changeLoadingState && dispatch({type: UTIL_CONSTS.START_LOADING})
        return request(requestData)
            .then(({data}) => {
                if (!getResponse) {
                    dispatch({
                        type: ALBUMS_CONSTS.GET_ALBUMS,
                        payload: {
                            data,
                            text,
                            artists,
                            reset,
                            hasMore: data.length === limit,
                        }
                    });
                } else {
                    return {
                        data,
                        hasMore: data.length === limit,
                    }
                }
            }).finally(() => {
                !getResponse && reset && changeLoadingState && dispatch({type: UTIL_CONSTS.END_LOADING})
            })

    }
};


export const GetAlbumById = (id) => {
    const requestData = {
        url: `${_urlAlbums}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                return res?.data;
            })
            .catch(() => {
            })
    }
};


export const DeleteAlbumById = id => {
    const requestData = {
        url: `${_urlAlbums}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ALBUMS_CONSTS.DELETE_ALBUM_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateAlbum = data => {
    const requestData = {
        url: _urlAlbums,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ALBUMS_CONSTS.CREATE_ALBUM,
                    payload: res.data
                });
                notificationSuccess({
                    description: 'Ալբոմը հաջողությամբ ստեղծվեց!'
                })
                history.push('/albums')
            })
            .catch((err) => {
                if (err && err.response && err.response.status === 409) {
                    notificationError({
                        description: 'Սլագը գոյություն ունի!'
                    })
                }
            })
    }
};

export const UpdateAlbumById = (id, data, redirect = '/albums') => {
    const requestData = {
        url: `${_urlAlbums}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ALBUMS_CONSTS.UPDATE_ALBUM_BY_ID,
                    payload: res.data
                });

                redirect && notificationSuccess({
                    description: 'Ալբոմը հաջողությամբ փոփոխվեց!'
                })
                redirect && history.push({
                    pathname: redirect,
                })
            })
            .catch((err) => {
                if (err && err.response && err.response.status === 409) {
                    notificationError({
                        description: 'Սլագը գոյություն ունի!'
                    })
                }

            })
    }
};


export const UpdateAlbumsPositions = items => {
    const requestData = {
        url: _urlAlbums,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: 'Ալբոմը հաջողությամբ փոփոխվեց!'
                })
            })
            .catch(() => {
            })
    }
};


export const UpdateAlbumTracksPositions = (id, data) => {
    const requestData = {
        url: `${_urlAlbums}/${id}/tracks`,
        token: true,
        method: "PUT",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                notificationSuccess({
                    description: 'Ալբոմի երգերը հաջողությամբ փոփոխվեցին!'
                })
            })
    }
};
