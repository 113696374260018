// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from 'lodash.debounce';

import '../assets/styles/containerStyles/settings.scss';
// Import components
import PageWrapper from "../components/pageContentViews/pageWrapper";
import { LinkButton } from "../components/buttons/buttons";
import { InputGroup } from "../components/uiElements/inputGroup";


// Import utils
import { mapStateToProps } from "../redux/mapStateToProps";
import {
    AddFolder,
    AddTrack,
    CreateArtist,
    GetArtists,
    GetImageFileByUrl,
    setShazamCurrentState,
    UploadFile
} from "../redux/actions";
import { changeStateInputValue } from "../utils/helperFunctions";
import { getShazamArtist, getShazamArtistTopTracks } from "../components/media/AddMedia/functions/action-functions";
import { generateFileMediaUrl, generateImageMediaUrl } from "../utils/generateMemberMediaUrl";
import { MaterialIcon } from "../components/utils/Icon";
import { resizeImage } from "../utils/resizeImage";

class Shazam extends Component {
    constructor( props ) {
        super( props );
        this.initialState = {
            shazamUrl: '',
            artistFile: null,
            artistName: null,
            artist: null,
            folderPath: null,
            folderId: null,
            shazamTracks: [],
            tracks: [],
            loadingStart: false,
            loadingTracks: false,
            searchArtists: {
                itemsList: [],
                hasMore: false,
                text: ''
            },
        };
        this.state = props.shazamCurrentState? {...props.shazamCurrentState} :this.initialState;
        this.getInputValues = changeStateInputValue.bind(this);
        this.onSelectSearchChangedBounded = debounce(this.onSelectSearchChanged, 500);
    }

    componentDidMount() {
        this.props.GetArtists({getResponse: true}).then(res => {
            this.setState({
                searchArtists: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: ''
                },
            })
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state !== prevState) {
            this.props.setShazamCurrentState(this.state)
        }
    }

    start = async () => {
        await this.setState( { loadingStart: true } )
        const { mediaItems } = this.props;
        await this.getArtistDataAndTracks();

        const { artistName } = this.state;
        //  console.log('artistName,  artistName,);
        // console.log('mediaItems', mediaItems);
        const alias = artistName.replace( /\s/gi, '-' );
        const folder = mediaItems.itemsList.find( media => {
            return media.type === 'FOLDER'
                && media.name.toLowerCase() === alias.toLowerCase()
        } )
        if ( folder ) {
            await this.setState( {
                folderPath: folder.path,
                folderId: folder.id,
            })
        } else {
            await this.createFolder(alias)
        }

        const artist = await this.checkArtistExist(artistName);
     //   console.log('artist', artist)
        if (!artist) {
            await this.createArtist();
        } else {
            await this.setState({artist})
        }
        const shazamTracks = this.state.shazamTracks.map((tr, index) => {
            // setTimeout((name,title)=>{
            //     window.open(`https://www.youtube.com/results?search_query=${name} ${title}`, '_blank')
            // }, index * 1000,artistName, tr?.name);

            return {...tr, artists: [this.state?.artist?.id]}
        })
        this.setState({
            loadingStart: false,
            shazamTracks: shazamTracks
        })
    }

    getArtistDataAndTracks = () => {
        const {shazamUrl,} = this.state;
        const pathItems = shazamUrl.split('/');
        // const alias = pathItems?.[pathItems?.length - 1];
        const id = pathItems?.[pathItems?.length - 2];
        const stateTracks = [];

        const artistPromise = getShazamArtist(id).then(res => {
            return res?.data?.avatar &&
                GetImageFileByUrl(res?.data?.avatar, res?.data?.name || 'artist').then(async file => {
                    await this.setState({
                        artistFile: file,
                        artistName: res?.data?.name,
                    })
                })
        })
        const artistTracksPromise = getShazamArtistTopTracks(id).then(res => {
            const tracks = res?.data?.tracks;
            const imagesFilesPromises = [];
            if (tracks) {
                tracks.forEach((track, index) => {
                    //   window.open(`https://www.youtube.com/results?search_query=${track?.title}`, '_blank')
                    track.images.coverarthq && imagesFilesPromises.push(
                        GetImageFileByUrl(track.images.coverarthq, track?.title || 'track-image')
                            .then(async file => {
                                return this.readFile(file).then((url)=>{
                                    stateTracks[index]= {
                                        position: index,
                                        name: track?.title,
                                        file,
                                        url: url,
                                        youtubeUrl: '',
                                        artists: []
                                    };
                                })

                        })
                    )
                });
                return Promise.all(imagesFilesPromises).then(async () => {
                    await this.setState({
                        shazamTracks: stateTracks
                    })
                })
            }
        })
        return Promise.all([artistPromise, artistTracksPromise])
    }

    createFolder = (folderName) => {
        const reqData = {
            name: folderName,
        };
        return this.props.AddFolder(reqData).then(async (data) => {
            await this.setState({
                folderPath: data.path,
                folderId: data.id,
            })
        })
    }

    checkArtistExist = async (artistName) => {
        return this.props.GetArtists({
            text: artistName,
            getResponse: true,
            changeLoadingState: false,
        }).then(({data}) => {
          //  console.log(data)
            return data?.[0];
        })
    }

    createArtist = () => {
        const {artistName, artistFile} = this.state;

        return this.uploadImage(artistFile).then(async artistImage => {
           // console.log('artistImage', artistImage);
            const artistReqData = {
                isHidden: true,
                translations: [{
                    language: '5db99765e2019423d6e0df83',
                    fullName: artistName
                }],
                profileImage: artistImage.id
            }
            return this.props.CreateArtist(artistReqData, false).then(async artist => {
             //   console.log('artist', artist)
                await this.setState({artist: artist});
            })
        })
    }

    uploadImage = (file) => {
        const {folderPath, folderId} = this.state;
        let formData = new FormData();
        formData.append('files', file);
        formData.append('parent', folderId);
        //formData.append('parentPath', folderPath);
        return this.props.UploadFile(formData).then(async data => {
            return data?.[0]
        })
    }

    createTracks = async () => {
        const {shazamTracks, artist} = this.state;
        await this.setState({loadingTracks: true});

        const tracksPromises = [];
        shazamTracks.forEach((track, index) => {
            const {youtubeUrl, name, artists} = track;

            if (!track.isUploaded && name && youtubeUrl && artists.length) {
                tracksPromises.push(this.uploadImage(track.file).then(image => {
                    const reqData = {
                        isHidden: false,
                        isTop: true,
                        youtubeUrl: youtubeUrl,
                        translations: [{
                            language: '5db99765e2019423d6e0df83',
                            title: name,
                        }],
                        shazamPayload:{
                            artistId:artist.id,
                            position:track.position
                        },
                        artists: artists,
                        coverImage: image.id,
                    };

                    return this.props.AddTrack(reqData, false).then(async () => {
                        await this.setState(prevState=>({
                            shazamTracks: prevState.shazamTracks.filter( tr => track.position !== tr.position )
                        }))
                    }).catch(() => {
                        this.setState(prevState=>({
                            shazamTracks: prevState.shazamTracks.map((tr, pos) => index === pos ? {
                                ...tr,
                                isErrored: true,
                            } : tr)
                        }))
                    });

                }));
            }
        })
        Promise.all(tracksPromises).then(() => {
            this.setState({loadingTracks: false});
        })

    }

    getTrackInputValues = ({name, value}, pos) => {
       // console.log(name, value, pos)
        const shazamTracks = [...this.state.shazamTracks];
        shazamTracks[pos] = {
            ...shazamTracks[pos],
            [name]: value
        }
        this.setState({shazamTracks})
    }

    deleteTrack = (pos) => {
        this.setState({shazamTracks: this.state.shazamTracks.filter((_, index) => index !== pos)})
    }

    onPopupScroll = (e) => {
        e.persist();
        const {itemsList, text, hasMore} = this.state.searchArtists;
        //console.log('hasMore', hasMore, type);
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight && hasMore) {
            //  console.log(target.scrollTop, target.offsetHeight, target.scrollHeight)
            const reqData = {
                text,
                getResponse: true,
                offset: itemsList?.length
            }
            // console.log(type === 'albums', artistId)
            this.props.GetArtists(reqData).then(res => {
                this.setState({
                    searchArtists: {
                        itemsList: [...itemsList, ...res.data],
                        hasMore: res.hasMore,
                        text
                    },
                })
            })
        }
    }

    onSelectSearchChanged = (text) => {
        const reqData = {
            text,
            getResponse: true,
        }
        this.props.GetArtists(reqData).then((res => {
            this.setState({
                searchArtists: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: text
                },
            })
        }))
    }

    refresh=()=>{
        this.props.GetArtists({getResponse: true}).then(res => {
            this.setState({
                searchArtists: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: ''
                },
            })
        })
    }

    readFile=(file)=> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Read the image via FileReader API and save image result in state.
            reader.onload = function (e) {
                // Add the file name to the data URL
                let dataURL = e.target.result;
                //dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
                resolve(dataURL);
            };
            reader.onerror= function(e){
                //console.log(e);
                reject(e);
            }
            reader.readAsDataURL(file);
        });
    }

    clear=()=>{
        this.setState({...this.initialState});
    }

    render() {
        const {shazamUrl, artist, loadingStart, loadingTracks, shazamTracks, searchArtists} = this.state;
        let artistsOptions = searchArtists?.itemsList?.map(art => {
            return {
                id: art.id,
                name: art?.fullName || "",
                imageUrl: resizeImage(generateImageMediaUrl(art?.profileImage?.path), 200, 200),
            }
        });
        if (artist && !artistsOptions.some(op => op.id === artist.id)) {
            artistsOptions.push({
                id: artist?.id,
                name: artist?.fullName,
                imageUrl: resizeImage(generateImageMediaUrl(artist?.profileImage?.path), 200, 200),
            })
        }
        return <PageWrapper pageTitle={<span className={'shazam-page-title'}>Shazam <MaterialIcon icon={'refresh'} onClick={this.refresh}/></span>}>
            <div className={'shazam'}>
                <div className={'top'}>
                    <LinkButton title={'Clear'} cb={this.clear}/>
                 <InputGroup inputType="input"
                            type="text"
                            placeholder="Artist Url"
                            name="shazamUrl"
                            value={shazamUrl || ''}
                            onChange={this.getInputValues}/>
                </div>
                {artist && <div className={'artist'}>
                    <div className="user-image">
                        <img src={generateFileMediaUrl(artist?.profileImage?.path)} alt="user"/>
                    </div>
                    <div className="user-info">
                        <span className="username"> {artist?.fullName}</span>
                    </div>
                </div>}
                {
                    artist && shazamTracks && shazamTracks.map((track, index) => {
                        return <div className={'track-item'}>
                            <img src={track.url}/>
                            <div className={'right-part'}>
                                <InputGroup inputType="input"
                                            type="text"
                                            placeholder="Անվանում *"
                                            name="name"
                                            value={track.name || ''}
                                            onChange={(data) => this.getTrackInputValues(data, index)}/>
                                <InputGroup inputType="input"
                                            type="text"
                                            placeholder="Հղում *"
                                            name="youtubeUrl"
                                            value={track.youtubeUrl || ''}
                                            onChange={(data) => this.getTrackInputValues(data, index)}/>
                                <InputGroup inputType="selectCustom"
                                            placeholder="Արտիստներ *"
                                            name="artists"
                                            value={track.artists || ''}
                                            showSearch={true}
                                            mode={'multiple'}
                                            onPopupScroll={this.onPopupScroll}
                                            onSearch={this.onSelectSearchChangedBounded}
                                            onChange={( data ) => this.getTrackInputValues( data, index )}
                                            options={artistsOptions}/>
                            </div>
                            <div className={'delete-icon'}>

                                <MaterialIcon icon={'delete'} onClick={() => this.deleteTrack( index )}/>
                                {track.isErrored && <MaterialIcon icon={'error'} className={'error'}/>}
                                <MaterialIcon icon={'search'} onClick={() =>
                                    window.open( `https://www.youtube.com/results?search_query=${artist.fullName} ${track.name}`, '_blank' )}/>
                            </div>
                        </div>
                    })
                }

                <div className={'flex-wrapper-right'}>
                    {!artist && <LinkButton title={'Check if not exist Add Artist'}
                                            loading={loadingStart}
                                            disabled={!shazamUrl}
                                            loadingWithTitle={true}
                                            cb={this.start}/>}
                    {artist && <LinkButton title={'Create Tracks'}
                                           loading={loadingTracks}
                                           loadingWithTitle={true}
                                           cb={this.createTracks}/>}
                </div>
            </div>
        </PageWrapper>
    }
}


const mapDispatchToProps = {
    AddFolder,
    UploadFile,
    CreateArtist,
    GetArtists,
    AddTrack,
    setShazamCurrentState

};

export default connect(mapStateToProps, mapDispatchToProps)(Shazam);
