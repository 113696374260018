// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

// Import styles
import "../../assets/styles/containerStyles/edit-file.scss";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";

// Import utils
import {getInputValues, getPatchReqData, toggleStateField} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UpdateAudioMetaData} from "../../redux/actions";
import {history} from "../../configs/history";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import jsmediatags from "jsmediatags";
import {generateFileMediaUrl, generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {bytesToBase64} from "../../utils/bytesToBase64";


class EditAudioMetaData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                artwork: null,
                metaPicture: null,
                title: '',
                artist: '',
                album: '',
                date: '',
                comment: '',
            },
            metaPicture: null,
            isEditing: true,
            mediaModalOpen: false,
        };
        this.currentData = null;
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.updateFile = this.updateFile.bind(this);
    }

    componentDidMount() {
        const {location} = this.props;
        const fileData = location && location.state && location.state.data;
        // console.log(fileData, 'fileData');
        if (!fileData) {
            history.push('/media')
        } else {
            jsmediatags.read(generateFileMediaUrl(fileData.path), {
                onSuccess: res => {
                    const tags = res?.tags;
                    const initStateData = {
                        title: tags?.title || "",
                        artist: tags?.artist || "",
                        album: tags.album || "",
                        date: tags?.year || "",
                        comment: tags?.comment?.text || "",
                        artwork: null,
                    };
                    // console.log(tags, 'TAGS');
                    this.currentData = JSON.parse(JSON.stringify(initStateData));
                    this.setState({
                        ...this.state,
                        fieldsData: initStateData,
                        metaPicture: tags?.picture,
                    });
                }
            });

        }
    }

    getMedia(mediaArray) {
        const {fieldsData} = this.state;
        this.currentData?.artwork?.id !== mediaArray[0]?.id
            ? this.updatedDataMap.set('artwork', mediaArray[0]?.id)
            : this.updatedDataMap.delete('artwork');
        this.setState({
            fieldsData: {
                ...fieldsData,
                artwork: mediaArray[0],
            },
        })
    }

    deleteMedia() {
        const {fieldsData} = this.state;

        this.currentData.artwork ?
            this.updatedDataMap.set("artwork", null) :
            this.updatedDataMap.delete("artwork");
        this.setState({
            fieldsData: {
                ...fieldsData,
                artwork: null
            }
        })
    }

    async updateFile() {
        const {date,} = this.state.fieldsData;

        if (date && (date > 2100 || date < 1850)) {
            this.setState({
                errors: {
                    date: true,
                }
            });
            return;
        }
        const reqData = this.getPatchReqData();
        // !('artwork' in reqData) &&
        // console.log(('artwork' in reqData), 'REQ DATA');
        this.props.UpdateAudioMetaData(this.props.id, reqData).then(() => {
            history.push({
                pathname: '/media',
                state: {
                    folderList: this.folderList,
                    reGetData: true,
                }
            })
        }).catch((error) => {
        });

    }

    render() {
        const {requestLoading} = this.props;
        const {fieldsData, errors, mediaModalOpen, metaPicture} = this.state;
        // console.log('fileData', fieldsData)
       // fieldsData?.metaPicture?.data && console.log('bytesToBase64', bytesToBase64(fieldsData?.metaPicture?.data));
        let imageUrl = '';
        // console.log(fieldsData?.artwork?.id, 'ID');
        // console.log(metaPicture?.data , 'data');
        if (fieldsData?.artwork?.id) {
            imageUrl = generateImageMediaUrl(fieldsData?.artwork?.path);
        } else if (metaPicture?.data) {
            imageUrl = bytesToBase64(metaPicture?.data);
        }
        return <PageWrapper pageTitle={`Փոփոխել Ֆայլի մետա-տվյալները`}>
            <div className="admin-edit-file">
                <div className="top-side">
                    <label>Նկար</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn`}
                                cb={this.toggleMediaModal}/>
                </div>
                {imageUrl && <div className={'main-media'}>
                    <div className={`media-card`}>
                        <div className={'image-wrapper'}>
                            <div className={'card-image'}>
                                <img className={'image'} src={imageUrl} alt="meta-data"/>
                            </div>
                        </div>
                    </div>
                </div>}
                <InputGroup inputType="input"
                            type="text"
                            label="Անուն"
                            name="title"
                            maxLength={30}
                            error={errors?.title}
                            value={fieldsData.title}
                            initValue={this.currentData?.title}
                            onChange={this.getInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Արտիստ"
                            name="artist"
                            maxLength={30}
                            error={errors?.artist}
                            value={fieldsData.artist}
                            initValue={this.currentData?.artist}
                            onChange={this.getInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Ալբոմ"
                            name="album"
                            maxLength={30}
                            error={errors?.album}
                            value={fieldsData.album}
                            initValue={this.currentData?.album}
                            onChange={this.getInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Մեկնաբանություն"
                            name="comment"
                            maxLength={30}
                            error={errors?.comment}
                            value={fieldsData.comment}
                            initValue={this.currentData?.comment}
                            onChange={this.getInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label="Տարեթիվ"
                            name="date"
                            integerNumber={true}
                            maxValue={2100}
                            error={errors?.date}
                            value={fieldsData.date}
                            initValue={this.currentData?.date}
                            onChange={this.getInputValues}/>
                <div className="flex-wrapper-right">
                    <LinkButton cb={this.updateFile}
                                disabled={!this.updatedDataMap.size}
                                loading={requestLoading}
                                title="Փոփոխել"/>
                </div>
            </div>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateAudioMetaData
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditAudioMetaData));
