// Import packages
import React, { Component } from "react";

// Import Styles
import '../assets/styles/components/items-list.scss';
import noDataImage from '../assets/images/default_empty_logo.png';

// Import components
import InfiniteScroll from 'react-infinite-scroller';
import ListItemCard from "./cards/ListItemCard";
import { SkeletonItem } from "./uiElements/SkelatonItem";
import { Checkbox } from "antd";
import Nestable from "react-nestable";

class ItemsList extends Component {
    constructor(){
        super();
        this.collapsedIds = new Set()
        this.refNestable = React.createRef();
        this.setCollapsed = this.setCollapsed.bind(this);
    }

    componentDidMount(){
        if ( this.props.isNestable && this.props?.itemListInfo?.length ) {
            this.collapsedIds = new Set(this.props?.itemListInfo.map(item => item.id));
            const values = Array.from(this.collapsedIds);
            this.refNestable.collapse(values);
            // console.log('this.collapsedIds mount', values)
            this.forceUpdate()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if ( this.props.isNestable && !prevProps?.itemListInfo?.length && this.props?.itemListInfo?.length ) {
            this.collapsedIds = new Set(this.props?.itemListInfo.map(item => item.id));
            const values = Array.from(this.collapsedIds);
            this.refNestable.collapse(values);
            // console.log('this.collapsedIds update', values)
            this.forceUpdate()
        }
    }

    setCollapsed(id){
        // console.log('collapse', id)
        if ( this.collapsedIds.has(id) ) {
            this.collapsedIds.delete(id)
        } else {
            this.collapsedIds.add(id)
        }
        const values = Array.from(this.collapsedIds);
        this.refNestable.collapse(values);
        // console.log('this.collapsedIds', values)
        this.forceUpdate()
    }

    getScrollPosition(){
        // console.log('getPosition');
        return document.body.scrollTop || document.documentElement.scrollTop;
    }

    calculateItemsWith = () => {
        const { generalInfo = [], largeItems = [], sizePercentage = 1.3, fixWidths = {},  actions, status,} = this.props.itemListHeaderInfo || {};

        const fixWidthsLength = Object.keys(fixWidths).length;
        let fixWidthsSum = 0;
        Object.keys(fixWidths).forEach(key => fixWidthsSum += fixWidths[key]);

        const largeItemsCount = largeItems?.length;
        const itemsWithPercentWidth = generalInfo?.length - fixWidthsLength;

        let defaultWidthPercent = 100 / itemsWithPercentWidth || 100;
        //console.log('defaultWidthPercent', defaultWidthPercent);

        const largeItemsWidth = defaultWidthPercent * sizePercentage;
        const itemsDefaultWidth = largeItemsCount ?
            (100 - largeItemsCount * largeItemsWidth) / (itemsWithPercentWidth - largeItemsCount)
            : defaultWidthPercent;

        let contentWidth = 0;
        actions && (contentWidth += actions.length * 50);
        status && (contentWidth += 120);

        // console.log('itemsDefaultWidth', itemsDefaultWidth);
        // console.log('largeItemsWidth', largeItemsWidth);
        return [itemsDefaultWidth, largeItemsWidth, itemsWithPercentWidth, fixWidthsSum, contentWidth]
    }

    ListItemCardWrapper = (itemData) => {
        // console.log('itemData',itemData)
        const {detailsLink, editLink, selectingActive, isNestable} = this.props;
        const data = isNestable ? itemData?.item?.data : itemData;
        const {largeItems = [], actions, status, fixWidths = {}, styles = {}} = this.props.itemListHeaderInfo || {};
        const [itemsDefaultWidth, largeItemsWidth, itemsWithPercentWidth, fixWidthsSum, contentWidth] = this.calculateItemsWith();


        return <ListItemCard key={data.id}
                             data={data}
                             isNestable={true}
                             collapsed={this.collapsedIds.has(data.id)}
                             setCollapsed={this.setCollapsed}
                             fixWidths={fixWidths}
                             itemsWithPercentWidth={itemsWithPercentWidth}
                             styles={styles}
                             status={status}
                             actions={actions}
                             selectingActive={selectingActive}
                             largeItems={largeItems}
                             getScrollPosition={this.getScrollPosition}
                             contentWidth={contentWidth}
                             largeItemsWidth={largeItemsWidth}
                             itemsDefaultWidth={itemsDefaultWidth}
                             fixWidthsSum={fixWidthsSum}
                             detailsLink={detailsLink}
                             editLink={editLink}/>
    }

    getNestableItems = (list) => {
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            let children = []

            if ( item?.children?.length ) {
                children = this.collapsedIds.has(item.id) ? [{
                    id: item.children[0].id,
                    index: 0,
                    parentId: item.children[0]?.parentId,
                    data: item.children[0]
                }] : item.children.map((subItem, index) => {
                    return {
                        id: subItem.id,
                        index: index,
                        parentId: subItem?.parentId,
                        data: subItem
                    }
                });
            }
            items.push({
                id: item.id,
                index: index,
                parentId: item?.parentId,
                data: item,
                children: children
            })
        });
        return items;
    };

    loadMoreItems = () => {
        this.props?.infiniteScroll && this.props.loadMoreItems && this.props.loadMoreItems();
    };


    render() {
        const {
            itemListInfo, selectingActive, allSelected, isNestable, maxDepth = 1,
            infiniteScroll, hasMore, useWindow, loading, className,
        } = this.props;

        const {generalInfo = [], largeItems = [], actions, status, fixWidths = {}, styles = {}} = this.props.itemListHeaderInfo || {};
        const [itemsDefaultWidth, largeItemsWidth, itemsWithPercentWidth, fixWidthsSum, contentWidth] = this.calculateItemsWith();

        let nestableItems = this.getNestableItems(itemListInfo);
        return <div className={`items-list-wrapper ${className ? className : ""}`}>
            {!isNestable && generalInfo && itemListInfo && !!itemListInfo.length &&
            <div className={`items-list-header`}>
                {selectingActive && <Checkbox checked={allSelected}
                                              onChange={this.props.selectAll}/>}
                <div className={`general-info`} style={{width: `calc(100% - ${contentWidth}px)`}}>
                    {!!generalInfo.length && generalInfo.map((item, index) => {
                        let width = largeItems.includes(index)
                                ? largeItemsWidth
                                : itemsDefaultWidth;
                        width += '%';
                        if ( itemsWithPercentWidth ) {
                            width = `calc( ${width} - ${fixWidthsSum / itemsWithPercentWidth}px )`
                        }
                        fixWidths?.[index] && (width = fixWidths?.[index] + 'px');
                        let style = {width: width};
                        styles[index] && (style = {...style, ...styles[index]});
                        return <div className={`general-info-item`}
                                    style={style}
                                    key={index}>{item}</div>
                    })}
                </div>
                <div className="items-header-actions-part">
                    {status && <div className="status">Կարգավիճակ</div>}
                    {actions && !!actions.length &&
                    <div className="actions"> Գործողություններ</div>}
                </div>
            </div>}
            {loading && (!itemListInfo || !itemListInfo.length)
                    ? <div className={'items-list-content'}>
                        <SkeletonItem/>
                    </div>
                    : itemListInfo && !!itemListInfo.length ? <InfiniteScroll
                                    hasMore={hasMore && infiniteScroll}
                                    loadMore={this.loadMoreItems}
                                    className={'items-list-content'}
                                    pageStart={0}
                                    threshold={1000}
                                    useWindow={!!useWindow}
                                    initialLoad={false}>
                                {isNestable ? <Nestable
                                                ref={el => this.refNestable = el}
                                                items={nestableItems}
                                                collapsed={true}
                                                maxDepth={maxDepth}
                                                onChange={this.props?.onPositionChange}
                                                renderItem={this.ListItemCardWrapper}
                                        /> :
                                        itemListInfo.map((item) => {
                                            return this.ListItemCardWrapper(item);
                                        })}
                            </InfiniteScroll> :
                            <div className={'no-items'}>
                                <img src={noDataImage} alt={'no-items'}/>
                            </div>}
        </div>
    }
}

export default ItemsList
