export const ACCESS_PAGES = {
    tracks: {
        key: 'TRACKS',
        name: 'Երգեր',
        icon: 'add_shopping_cart',
        crud: true,
        isHidden: true,
    },
    users: {
        key: 'USERS',
        name: 'Օգտատերեր',
        icon: 'person',
        crud: true,
        isHidden: false,
    },
    media: {
        key: 'FILES',
        name: 'Ֆայլեր',
        icon: 'filter',
        crud: true,
        isHidden: false,
    },
    artists: {
        key: 'ARTISTS',
        name: 'Արտիստներ',
        icon: 'group_work',
        crud: true,
        isHidden: false,
    },
    albums: {
        key: 'ALBUMS',
        name: 'Ալբոմներ',
        icon: 'album',
        crud: true,
        isHidden: false,
    },
    section: {
        key: 'SECTIONS',
        name: 'Բաժիններ',
        icon: 'vertical_split',
        crud: true,
        isHidden: false,
    },
    podcasts: {
        key: 'PODCAST',
        name: 'Փոդքաստեր',
        icon: 'mic',
        crud: true,
        isHidden: false,
    },
    genre: {
        key: 'GENRE',
        name: 'ժանրեր',
        icon: 'dns',
        crud: true,
        isHidden: false,
    },
    reports: {
        key: 'REPORTS',
        name: 'Հաշվետվություն',
        icon: 'chat',
        crud: true,
        isHidden: false,
    },
    notifications: {
        key: 'NOTIFICATION',
        name: 'Ծանուցումներ',
        icon: 'notification_important',
        crud: true,
        isHidden: false,
    },
    devices: {
        key: 'DEVICES',
        name: 'Սարքեր',
        icon: 'phone_android',
        crud: true,
        isHidden: false,
    },
    youTubeKeys: {
        key: 'YOUTUBEKEYS',
        name: 'YouTube կոդեր',
        icon: 'vpn_key',
        crud: true,
        isHidden: false,
    },
    languages: {
        key: 'LANGUAGES',
        name: 'Լեզուներ',
        icon: 'language',
        crud: true,
        isHidden: false,
    },
    settings: {
        key: 'SETTING',
        name: 'Settings',
        icon: 'settings',
        crud: false,
        isHidden: false,
    },
    shazam: {
        key: 'SHAZAM',
        name: 'Shazam',
        icon: 'group_add',
        crud: false,
        isHidden: false,
    },
    yandex: {
        key: 'YANDEX',
        name: 'Yandex',
        icon: 'perm_identity',
        crud: false,
        isHidden: false,
    },
};
