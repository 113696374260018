// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import assets
import '../../assets/styles/dataDisplay/notification-modal.scss';

// Import components
import {Modal} from "antd";
import {LanguageTabs} from "./Tabs";
import {InputGroup} from "./inputGroup";
import {LinkButton} from "../buttons/buttons";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    changeStateField,
    getTranslatableInputValues, getTranslationData,
    initTranslations
} from "../../utils/helperFunctions";

import {SendNotification} from "../../redux/actions/notificationActions";
import {validateTranslations} from "../../utils/validateTranslations";

class ModalSendNotification extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = ['title', 'description'];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
            },
            languageTab: props.mainLanguage,
            notificationSend: false,
            loading: false,
            errorsTabs: [],
            errors: {},
        };
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getTranslatableInputValues.bind(this);
        this.sendNotification = this.sendNotification.bind(this);
    }

    async sendNotification() {
        const {translations} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description']
        const validation = validateTranslations(translations, translationRequiredFields, true);
        const {userIds, sendToAllUsers} = this.props;

        if (validation.result) {
            await this.setState({loading: true});
            const Tr_Data = getTranslationData(translations);
            // Object.keys(translations).forEach(key => {
            //     const trData = translations[key] || {};
            //     Tr_Data.push({
            //         language: key,
            //         title: trData.title,
            //         description: trData.description
            //     })
            // });

            const reqData = {
                translations: Tr_Data
            };
            !sendToAllUsers && (reqData.receiverIds = userIds.join(','));
            await this.props.SendNotification(reqData).then(() => {
                this.setState({
                    notificationSend: true
                });
            }).catch(() => {
            });
            this.setState({loading: false});
        } else {
            this.setState({
                errors: validation.errors,
                errorsTabs: validation.errorsTabs
            })
        }
    }

    closeModal = () => {
        this.props.closeModal(this.state.notificationSend);
        setTimeout(() => {
            this.setState({
                fieldsData: {
                    translations: initTranslations(this.translationsFields, this.props.activeLanguages),
                },
                notificationSend: false,
                errorsTabs: [],
                errors: {},
            });
        }, 300)
    }

    render() {
        const {visible, mainLanguage} = this.props;
        const {languageTab, fieldsData, notificationSend, loading, errorsTabs, errors} = this.state;
        const trData = fieldsData?.translations?.[languageTab] || {};
        return <Modal
            visible={visible}
            wrapClassName={'custom-modal'}
            onCancel={this.closeModal}
        >
            <div className={'send-notification-modal'}>
                <span className={'close-icon'} onClick={this.closeModal}>
                    <img src={require('../../assets/images/ic_clear.svg')}
                         className="close_icon" alt=""/>
                </span>
                <h2 className="title">Ծանուցում</h2>
                {notificationSend && <div className={'message'}>Ծանուցումը հաջողությամբ ուղարկվեց</div>}
                {!notificationSend && <section className="send-notification">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  activeTab={languageTab}
                                  errorsTabs={errorsTabs}
                    />
                    <div className="info-adding-fields">

                        <InputGroup inputType={"input"}
                                    type={"text"}
                                    label={"Վերնագիր"}
                                    placeholder={"Վերնագիր"}
                                    name={"title"}
                                    maxLength={50}
                                    required={languageTab === mainLanguage || (trData?.title || trData?.description)}
                                    error={errors['title' + languageTab]}
                                    value={trData?.title}
                                    onChange={this.getInputValues}/>
                        <InputGroup inputType={"textarea"}
                                    label={"Նկարագրություն"}
                                    placeholder={"Նկարագրություն"}
                                    name={"description"}
                                    maxLength={300}
                                    required={languageTab === mainLanguage || (trData?.title || trData?.description)}
                                    error={errors['description' + languageTab]}
                                    value={trData?.description}
                                    onChange={this.getInputValues}/>
                    </div>

                    <div className="btn-wrapper">
                        <LinkButton title="Ուղարկել Ծանուցում"
                                    loading={loading}
                                    disabled={notificationSend}
                                    loadingWithTitle={true}
                                    cb={this.sendNotification}/>
                    </div>
                </section>}
            </div>
        </Modal>
    }
}


const mapDispatchToProps = {
    SendNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalSendNotification)
