// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import { deleteItem } from "../../utils/helperFunctions";
import { DeleteGenreById, GetGenres, UpdateGenreById, UpdateGenresPositions } from "../../redux/actions";
import { onPositionChange } from "../../utils/helpers";
import { resizeImage } from "../../utils/resizeImage";
import { generateImageMediaUrl } from "../../utils/generateMemberMediaUrl";


class Genre extends Component {
    constructor(props){
        super(props);
        const positionChangeFunctions = {
            UpdateItemById: props.UpdateGenreById,
            UpdateItemsPositions: props.UpdateGenresPositions,
            GetItems: props.GetGenres,
        }

        this.deleteItem = deleteItem.bind(this, props.DeleteGenreById, 'ժանրը');
        this.onPositionChange = onPositionChange.bind(this, positionChangeFunctions);
    }

    componentDidMount(){
      this.props.GetGenres();
    }

    render() {
        let {genreList, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Նկար", "Անվանում", "Գույն"],
            status: true,
            fixWidths: { 0: 60 },
            styles: {
                1: { justifyContent: 'flex-start' }
            },
            actions: ["edit", "delete"],
        };
        let itemListInfo = [];
        genreList && genreList.forEach(item => {
            //subGenres
            const genreItem = {
                id: item.id,
                status: !item?.isHidden,
                values: [
                    {
                        type: 'image',
                        path: resizeImage(generateImageMediaUrl(item?.icon?.path), 200, 200),
                    },
                    item?.title || "-",
                    {
                        type: 'color',
                        color: item.color
                    }
                ],
                parentId:item?.parentId,
                deleteCb: this.deleteItem.bind(this, item.id),
            };
            if ( item?.subGenres && item?.subGenres?.length ) {
                genreItem.children = item?.subGenres.map(subItem => {
                    return {
                        id: subItem.id,
                        status: !subItem?.isHidden,
                        values: [
                            {
                                type: 'image',
                                path: resizeImage(generateImageMediaUrl(subItem?.icon?.path), 200, 200),
                            },
                            subItem?.title || "-",
                            {
                                type: 'color',
                                color: subItem.color
                            }
                        ],
                        parentId:subItem?.parentId,
                        deleteCb: this.deleteItem.bind(this, subItem.id),
                    }
                })
            }
            itemListInfo.push(genreItem);
        });
        return <PageWrapper pageTitle={'ժանրեր'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/genre/add"}/>
            <ItemsList itemListInfo={itemListInfo}
                       itemListHeaderInfo={itemListHeaderInfo}
                       loading={requestLoading}
                       editLink={"/genre/edit"}
                       maxDepth={2}
                       isNestable={true}
                       onPositionChange={this.onPositionChange}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetGenres,
    DeleteGenreById,
    UpdateGenreById,
    UpdateGenresPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Genre);
