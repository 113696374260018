// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import debounce from 'lodash.debounce';

//import assets
import '../../assets/styles/containerStyles/appended-item-section.scss';
import "../../assets/styles/containerStyles/add-edit-album.scss";
import {ReactComponent as ArrowIcon} from "../../assets/images/ic_select.svg"

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {Switch} from "antd";

// Import utils
import {
    changeStateField,
    getInputValues,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    CreateNotification,
    GetNotifications,
    UpdateNotificationById,
    GetArtists,
    GetTracks,
    GetAlbums,
} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import {NOTIFICATION_TYPES} from "../../constants/constTypes";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";


const typeOptions = Object.keys(NOTIFICATION_TYPES).map(key => ({
    id: key,
    name: NOTIFICATION_TYPES[key].name
}));

class AddEditNotifications extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                image: undefined,
                type: `${NOTIFICATION_TYPES.INFORMATION.key}`,
                resourceId: '',
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            fetchData: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.getNotificationMedia = this.getNotificationMedia.bind(this);
        this.onPopupScroll = this.onPopupScroll.bind(this);
        this.addEditNotification = this.addEditNotification.bind(this);
        this.onSearchMediaSelectBounded = debounce(this.onSearchMediaSelectList, 500);
    }

    async componentDidMount() {
        const {isEditing, fieldsData,} = this.state;
        const {createdNotification} = this.props;

        if (isEditing) {
            const editingData = createdNotification?.itemsList?.length && createdNotification?.itemsList?.find(item => item.id === this.props?.id);
            const translationsData = {};
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                    };
                });

            } else {
                history.push('/notifications');
                return;
            }
            const initStateData = {
                translations: translationsData,
                image: editingData?.image,
                type: editingData?.type,
                resourceId: editingData?.resourceId,
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData,
                fetchData: false,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.fieldsData.type !== prevState.fieldsData.type) {
            const data = this.getNotificationMedia();
            let reqData = {getResponse: true};
            if (data) {
                if (data.type === NOTIFICATION_TYPES.ALBUM.requestKey
                    || data.type === NOTIFICATION_TYPES.PLAYLIST.key) {
                    reqData.types = data.type
                }
                this.props[data?.callName](reqData)
                    .then(res => {
                        this.setState({
                            [data?.value]: {
                                itemsList: res.data,
                                hasMore: res.hasMore,
                                text: ''
                            },
                            fieldsData:{
                                ...this.state.fieldsData,
                                resourceId: '',
                            }
                        })
                    });
            }
        }
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.image?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('image', mediaArray[0]?.id)
                : this.updatedDataMap.delete('image');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                image: mediaArray[0],
            },
            errors: {
                ...errors,
                image: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.image ?
                this.updatedDataMap.set("image", null) :
                this.updatedDataMap.delete("image");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                image: null
            }
        })
    }

    onPopupScroll(e, name) {
        e.persist();
        const {itemsList, text, hasMore} = this.state[name];
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            const data = this.getNotificationMedia();
            let reqData = {
                getResponse: true,
                text,
                offset: itemsList?.length
            };
            if (data.type === NOTIFICATION_TYPES.ALBUM.requestKey
                || data.type === NOTIFICATION_TYPES.PLAYLIST.key) {
                reqData.types = data.type
            }
            hasMore && this.props[data?.callName](reqData)
                .then(res => {
                    this.setState({
                        [data?.value]: {
                            itemsList: [...itemsList, ...res.data],
                            hasMore: res.hasMore,
                            text
                        },
                    })
                });
        }
    }

    getNotificationMedia() {
        const {fieldsData, artists, tracks, albums} = this.state;
        let options = [];
        switch (fieldsData.type) {
            case NOTIFICATION_TYPES.ARTIST.key:
                options = artists?.itemsList?.map(artist => {
                    return {
                        id: artist.id,
                        name: artist?.fullName || "",
                        imageUrl: resizeImage(generateImageMediaUrl(artist?.profileImage?.path), 200, 200),
                    }
                });
                return {
                    type: NOTIFICATION_TYPES.ARTIST.key,
                    // name: 'resourceId',
                    value: 'artists',
                    label: NOTIFICATION_TYPES.ARTIST.name,
                    placeholder: NOTIFICATION_TYPES.ARTIST.name,
                    callName: 'GetArtists',
                    options,
                };
            case NOTIFICATION_TYPES.TRACK.key:
                options = tracks?.itemsList?.map(track => {
                    return {
                        id: track.id,
                        name: track?.title || "",
                        imageUrl: resizeImage(generateImageMediaUrl(track?.image?.path), 200, 200),
                    }
                });
                return {
                    type: NOTIFICATION_TYPES.TRACK.key,
                    // name: 'trackIds',
                    value: 'tracks',
                    label: NOTIFICATION_TYPES.TRACK.name,
                    placeholder: NOTIFICATION_TYPES.TRACK.name,
                    callName: 'GetTracks',
                    options,
                };
            case NOTIFICATION_TYPES.ALBUM.key:
                options = albums?.itemsList?.map(album => {
                    return {
                        id: album.id,
                        name: album?.title || "",
                        imageUrl: resizeImage(generateImageMediaUrl(album?.image?.path), 200, 200),
                    }
                });
                return {
                    type: NOTIFICATION_TYPES.ALBUM.requestKey,
                    // name: 'albumIds',
                    value: 'albums',
                    label: NOTIFICATION_TYPES.ALBUM.name,
                    placeholder: NOTIFICATION_TYPES.ALBUM.name,
                    callName: 'GetAlbums',
                    options,
                };
            // case NOTIFICATION_TYPES.SINGLE.key:
            //     options = albums?.itemsList?.map(album => {
            //         return {
            //             id: album.id,
            //             name: album?.title || "",
            //             imageUrl: resizeImage(generateImageMediaUrl(album?.image?.path), 200, 200),
            //         }
            //     });
            //     return {
            //         type: NOTIFICATION_TYPES.SINGLE.key,
            //         name: 'albumIds',
            //         value: 'albums',
            //         label: NOTIFICATION_TYPES.SINGLE.name,
            //         placeholder: NOTIFICATION_TYPES.SINGLE.name,
            //         callName: 'GetAlbums',
            //         options,
            //     };
            case NOTIFICATION_TYPES.PLAYLIST.key:
                options = albums?.itemsList?.map(album => {
                    return {
                        id: album.id,
                        name: album?.title || "",
                        imageUrl: resizeImage(generateImageMediaUrl(album?.image?.path), 200, 200),
                    }
                });
                return {
                    type: NOTIFICATION_TYPES.PLAYLIST.key,
                    // name: 'albumIds',
                    value: 'albums',
                    label: NOTIFICATION_TYPES.PLAYLIST.name,
                    placeholder: NOTIFICATION_TYPES.PLAYLIST.name,
                    callName: 'GetAlbums',
                    options,
                }
        }
    }

    onSearchMediaSelectList(name, callName, text) {
        const data = this.getNotificationMedia();
        let reqData = {
            getResponse: true,
            text,
        };
        if (data.type === NOTIFICATION_TYPES.ALBUM.requestKey
            || data.type === NOTIFICATION_TYPES.PLAYLIST.key) {
            reqData.types = data.type
        }
        callName && this.props[callName](reqData).then((res => {
            this.setState({
                [name]: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: text
                },
            })
        }))
    }

    async addEditNotification() {
        await this.setState({
            requestLoading: true
        });
        const {translations, image, type, resourceId, artistIds, trackIds, albumIds} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if(NOTIFICATION_TYPES.INFORMATION.key !== type){
            resourceId.length === 0 && (errors.resourceId = true);
            result = !!resourceId.length
        }

        if (result) {
            const trData = getTranslationData(translations);
            const reqData = {
                translations: trData
            };

            image && (reqData.image = image.id);
            type && (reqData.type = type);
            resourceId && (reqData.resourceId = resourceId);
            artistIds && (reqData.resourceId = artistIds);
            trackIds && (reqData.resourceId = trackIds);
            albumIds && (reqData.resourceId = albumIds);
            if (!this.state.isEditing) {
                this.props.CreateNotification(reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    })
            } else {
                this.props.UpdateNotificationById(this.props.id, reqData, this.props?.location?.state?.redirect)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {
            fieldsData, errors, languageTab, mediaModalOpen, requestLoading,
            isEditing, errorsTabs, fetchData
        } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        const notificationMediaData = this.getNotificationMedia();

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Ծանուցում' : 'Փոփոխել Ծանուցումը'}>
            <section className="general-add-edit">

                <div className="top-side">
                    <label>Նկար</label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                disabled={fetchData}
                                className={`media-select-btn ${errors.image ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>

                {fieldsData?.image && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.image}
                                   customDelete={this.deleteMedia}
                    />
                </div>}

                {!isEditing && <div className='row-2'>
                    <InputGroup inputType="selectCustom"
                                placeholder="Տիպ"
                                label="Տիպ"
                                name="type"
                                showSearch={false}
                                labelInValue={false}
                                value={fieldsData.type}
                                onChange={this.getInputValues}
                                options={typeOptions}/>

                    {
                        fieldsData.type !== NOTIFICATION_TYPES.INFORMATION.key &&
                        <InputGroup inputType="selectCustom"
                                    placeholder={notificationMediaData?.placeholder}
                                    label={notificationMediaData?.label}
                                    // name={notificationMediaData?.name}
                                    name={'resourceId'}
                                    showSearch={true}
                                    loading={fetchData}
                                    required={true}
                            // mode={'multiple'}
                                    onPopupScroll={(e) => this.onPopupScroll(e, notificationMediaData?.value)}
                            onSearch={(e) => this.onSearchMediaSelectBounded(notificationMediaData?.value, notificationMediaData?.callName, e)}
                            //         value={fieldsData[notificationMediaData?.name]}
                                    value={fieldsData?.resourceId}
                                    onChange={this.getInputValues}
                                    options={notificationMediaData?.options}
                                    error={errors?.resourceId}
                        />
                    }
                </div>}

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>

                <InputGroup inputType="input"
                            type="text"
                            label="Անվանում"
                            placeholder="Անվանում"
                            name="title"
                            loading={fetchData}
                            value={currentData.title}
                            initValue={initData.title}
                            error={errors['title' + languageTab]}
                            maxLength={50}
                            required={true}
                            onChange={this.getTranslationValues}/>

                <InputGroup inputType="textarea"
                            label="Նկարագրություն"
                            placeholder="Նկարագրություն"
                            name="description"
                            loading={fetchData}
                            value={currentData.description}
                            initValue={initData.description}
                            error={errors['description' + languageTab]}
                            maxLength={8000}
                            required={true}
                            onChange={this.getTranslationValues}/>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditNotification}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateNotification,
    UpdateNotificationById,
    GetNotifications,
    GetArtists,
    GetTracks,
    GetAlbums,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditNotifications)
