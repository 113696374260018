// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetSections,
    DeleteSectionById,
    UpdateSectionById,
    UpdateSectionsPositions
} from "../../redux/actions";
import ItemsList from "../../components/ItemsList";


class Section extends Component {
    constructor(props) {
        super(props);
        this.deleteItem = deleteItem.bind(this, props.DeleteSectionById, 'բաժինը');
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount() {
        const {sectionList} = this.props;
        !sectionList?.length && this.props.GetSections();
    }

    async onPositionChange(items, item) {
        const movedSectionId = item.id;
        const {sectionList} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~sectionList.findIndex(m => m.id === movedSectionId) &&
            ~positionedItems.findIndex(m => m.id === movedSectionId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateSectionsPositions(positionedItems);
            this.props.GetSections({changeLoadingState: false});
        }
    }

    render() {
        let {sectionList, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անվանում"],
            status: true,
            actions: ["toggle","edit", "delete"],
        };
        let itemListInfo = [];
        sectionList && sectionList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                status: !item?.isHidden,
                values: [
                    item?.title || "-",
                ],
                deleteCb: this.deleteItem.bind(this, item.id),
                toggleCb: ()=>this.props.UpdateSectionById(item.id, {isHidden: !item?.isHidden}, false)
            });
        });
        return <PageWrapper pageTitle={'Բաժիններ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/section/add"}/>
            <ItemsList itemListInfo={itemListInfo}
                       itemListHeaderInfo={itemListHeaderInfo}
                       loading={requestLoading}
                       editLink={"/section/edit"}
                       detailsLink={"section/edit"}
                       isNestable={true}
                       onPositionChange={this.onPositionChange}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetSections,
    DeleteSectionById,
    UpdateSectionById,
    UpdateSectionsPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Section);
