// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";


// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";
import {InputGroup} from "../../components/uiElements/inputGroup";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {GetYouTubeKeys, DeleteYouTubeKeyById, UpdateYouTubeKeyById} from "../../redux/actions";
import {keyTypeOptions, keyPlatformOptions, keyAllowUseOptions} from "./utils";
import {YOUTUBE_KEYS_PLATFORMS, YOUTUBE_KEYS_TYPES} from "../../constants/constTypes";

class YouTubeKeys extends Component {
    constructor(props) {
        super(props);
        this.state = {
            platform: undefined,
            type: undefined,
            allowUse: undefined,
        };
        this.getSelectValue = this.getSelectValue.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.deleteItem = deleteItem.bind(this, props.DeleteYouTubeKeyById, 'youTube կոդը');
    }

    componentDidMount() {
        const {youtubeKeys} = this.props
        const {platform, type, allowUse, itemsList} = youtubeKeys || {};

        if (platform || type || allowUse || !itemsList?.length) {
            this.props.GetYouTubeKeys();
        }
    }

    getSelectValue({name, value}) {
        this.setState({
            [name]: value
        }, () => this.searchItems())
    }

    searchItems() {
        const {platform, type, allowUse} = this.state;
        this.props.GetYouTubeKeys({platform, type, allowUse});
    }

    render() {
        let {platform, type, allowUse} = this.state;
        let {youtubeKeys, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Պլատֆորմ", "Տիպ", "Կոդ"],
            status: true,
            fixWidths: {0: 120, 1: 150},
            actions: ["toggle","edit", "delete"],
        };
        let itemListInfo = [];
        youtubeKeys && youtubeKeys.itemsList && youtubeKeys.itemsList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                status: item?.allowUse,
                values: [
                    YOUTUBE_KEYS_PLATFORMS[item?.platform]?.name || "-",
                    YOUTUBE_KEYS_TYPES[item?.type]?.name || "-",
                    item?.value || "-",
                ],
                deleteCb: this.deleteItem.bind(this, item.id),
                toggleCb: () => this.props.UpdateYouTubeKeyById(item.id, {allowUse: !item?.allowUse}, false)
            });
        });
        const filters = <div className={'keys-filters'}>
            <InputGroup inputType="selectCustom"
                        placeholder="Ընտրել Պլատֆորմ"
                        name="platform"
                        value={platform}
                        onChange={this.getSelectValue}
                        options={keyPlatformOptions}
                        withClear={'Բոլորը'}>
            </InputGroup>
            <InputGroup inputType="selectCustom"
                        placeholder="Ընտրել Տիպ"
                        name="type"
                        value={type}
                        onChange={this.getSelectValue}
                        options={keyTypeOptions}
                        withClear={'Բոլորը'}>
            </InputGroup>
            <InputGroup inputType="selectCustom"
                        placeholder="Ընտրել Կարգավիճակ"
                        name="allowUse"
                        value={allowUse}
                        onChange={this.getSelectValue}
                        options={keyAllowUseOptions}
                        withClear={'Բոլորը'}>
            </InputGroup>
        </div>
        return <PageWrapper pageTitle={'YouTube կոդեր'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/youTubeKeys/add"}
                        filters={filters}/>
            <ItemsList itemListInfo={itemListInfo}
                       itemListHeaderInfo={itemListHeaderInfo}
                       loading={requestLoading}
                       editLink={"/youTubeKeys/edit"}
                       detailsLink={"youTubeKeys/edit"}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    DeleteYouTubeKeyById,
    GetYouTubeKeys,
    UpdateYouTubeKeyById,
};

export default connect(mapStateToProps, mapDispatchToProps)(YouTubeKeys);
