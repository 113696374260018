import React from "react";
import {Select, Skeleton, Tooltip} from "antd";

import "../../assets/styles/dataDisplay/inputGroup.scss";

export function InputGroup(props) {
    const {
        label, type, inputType, name, placeholder, error, options, autocomplete = "on", initValue = '',
        defValue, step, value, minValue, maxValue, maxLength, autoFocus, resizable, onSearch, regExp, onKeyDown,
        className, showSearch, disabled, mode, integerNumber, number, required, loading = false, autoClearSearchValue = true
    } = props;

    function onChange(e) {
        let {name, value} = e.target;
        if (disabled) {
            return;
        }
        // console.log('Values', value)
        if (number && (isNaN(Number(value)) || value?.split('.')?.[1]?.length > 2)) {
            return;
        }
        if (value && integerNumber) {
            value = parseInt(value)
        }
        if ((integerNumber || number)) {
            if (minValue && Number(value) < minValue && value !== '') {
                return;
            }
            if (maxValue && Number(value) > maxValue && value !== '') {
                return;
            }
            if (isNaN(value)) {
                return;
            }
        } else {
            value = value.trimStart();
        }

        if (regExp && value && !regExp.test(value)) {
            return;
        }
        props.onChange({
            name,
            value,
            haveChanges: (!initValue && value) || initValue?.toString() !== value?.toString()
        });
    }

    function selectChange(option) {
        const value = props?.labelInValue ? option?.key : option;
        //  console.log('Values', initValue, value);
        props.onChange({
            name,
            value,
            haveChanges: (!initValue && value) || (initValue && !value)
                || (initValue && value && initValue.toString() !== value.toString()),
            option: option?.key ? option : undefined,
        });
    }

    function onSelect() {
       // mode && onSearch && onSearch('')
    }

    function onBlur() {
        mode && onSearch && onSearch('')
    }

    return <div className="input-group">

        {label && <label>{label} {required ? <span className={'required-badge'}>*</span> : ''}</label>}

        {inputType === "input" && type !== "number" && type !== "checkbox" && type !== "password" &&
        <div className={'item-wrapper'}>
            <SkeletonInput loading={loading}/>
            <input type={type} name={name} placeholder={placeholder} maxLength={maxLength}
                   value={value || ''} defaultValue={defValue} onChange={onChange} autoComplete={autocomplete}
                   autoFocus={autoFocus} onKeyDown={onKeyDown} disabled={!!disabled}
                   className={`${disabled ? "disabled" : ""} ${error ? "invalid" : ""}`}/>
            {props.children}
        </div>}

        {inputType === "input" && type === "password" &&
        <div className={'item-wrapper'}>
            <SkeletonInput loading={loading}/>
            <input type={type} name={name} placeholder={placeholder} maxLength={maxLength}
                   value={value || ''} defaultValue={defValue} onChange={onChange}
                   autoFocus={autoFocus} autoComplete={autocomplete} disabled={!!disabled}
                   className={`${disabled ? "disabled" : ""} ${error ? "invalid" : ""}`}/>
            {props.children}
        </div>}

        {inputType === "input" && type === "number" &&
        <div className={'item-wrapper'}>
            <SkeletonInput loading={loading}/>
            <input type={type} name={name} value={value || ''} min={minValue} max={maxValue}
                   step={step} onChange={onChange} disabled={!!disabled}
                   className={`${disabled ? "disabled" : ""} ${error ? "invalid" : ""}`}/>
        </div>}

        {inputType === "input" && type === "checkbox" &&
        <input type={type} name={name} checked={value} onChange={onChange}/>}

        {inputType === "textarea" &&
        <div className={'item-wrapper'}>
            <SkeletonInput loading={loading}/>
            <textarea name={name} placeholder={placeholder} value={value || ''}
                      defaultValue={defValue} onChange={onChange} maxLength={maxLength}
                      className={`${disabled ? "disabled" : ""} ${error ? "invalid" : ""} ${resizable ? "resizable" : ""}`}/>
        </div>}

        {inputType === "select" &&
        <select value={value || ''} name={name} placeholder={placeholder} onChange={onChange}
                className={`${error ? "invalid" : ""}`}>
            {options && !!options.length && options.map((item, index) => {
                return <option value={item.value} key={index}>{item.title}</option>
            })}
        </select>}

        {inputType === "selectCustom" &&
        <div className={'item-wrapper'}>
            <SkeletonInput loading={loading}/>
            <Select
                value={value}
                showSearch={showSearch}
                defaultOpen={false}
                disabled={!!disabled}
                autoClearSearchValue={autoClearSearchValue}
                style={{width: '100%'}}
                placeholder={placeholder}
                onPopupScroll={props.onPopupScroll}
                optionFilterProp="children"
                labelInValue={props?.labelInValue}
                optionLabelProp={'label'}
                mode={mode}
                onSelect={onSelect}
                onBlur={onBlur}
                filterOption={(input, option) => {
                    if (props.onPopupScroll) {
                        return true;
                    }
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                }
                onChange={selectChange}
                onSearch={onSearch}
                className={`${className ? className : ''} ${error ? "invalid" : ""}`}
            >
                {props.children}
                {props.withClear && value !== undefined && <Select.Option value={undefined} key={'remove'}>
                    {typeof props.withClear === 'boolean' ? 'Ջնջել' : props.withClear}
                </Select.Option>}
                {options && !!options.length && options.map((item) => {
                    const name = item?.name || item?.title || '';
                    return <Select.Option key={item?.id}
                                          value={item?.id}
                                          className={'select-item'} label={name}>
                        {props.tooltip ? <Tooltip placement="left" title={name}>
                            {name}
                        </Tooltip> : (item?.imageUrl ? <div className={'with-image-item'}>
                                    <img src={item.imageUrl} alt=""/> {name}
                                </div>
                                : name
                        )}
                    </Select.Option>
                })}
            </Select>
        </div>}

        {inputType === "wrapper" &&
        <> {props.children}</>
        }
    </div>
}

function SkeletonInput({loading}) {
    return !!loading && <div className={'skeleton-input-item'}>
        <Skeleton loading={true}
                  paragraph={{
                      rows: 0,
                  }}
                  active
        />
    </div>
}
