// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import debounce from 'lodash.debounce';

// Import Styles
import '../assets/styles/containerStyles/dashboard.scss';

// Import components
import InfiniteScroll from "react-infinite-scroller";
import PageWrapper from '../components/pageContentViews/pageWrapper';
import PageHeader from "../components/pageContentViews/pageHeader";
import TrackCard from "../components/cards/TrackCard";
import {InputGroup} from "../components/uiElements/inputGroup";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetAlbums, GetArtists, GetTracks, setPageOffset, toggleTrackView} from "../redux/actions";
import {ACCESS_PAGES} from "../constants/accessPages";
import {generateImageMediaUrl} from "../utils/generateMemberMediaUrl";
import {resizeImage} from "../utils/resizeImage";
import {LinkButton} from "../components/buttons/buttons";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
            artist: undefined,
            album: undefined,
            isTop:undefined,
            artists: {
                itemsList: [],
                hasMore: false,
                text: ''
            },
            albums: {
                itemsList: [],
                hasMore: false,
                text: '',
                artist: null,
            }
        };
        this.topOptions=[{
            id:true,
            name: "Թոփ",
        },{
            id:false,
            name: "Ոչ Թոփ",
        },]

        this.getSearchValue = this.getSearchValue.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);

        this.onPopupScroll = this.onPopupScroll.bind(this);
        this.onSelectSearchChanged = this.onSelectSearchChanged.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onSelectSearchChangedBounded = debounce(this.onSelectSearchChanged, 500);
    }

    async componentDidMount() {
        const {pageOffset, artists, albums, tracks} = this.props;
        if (pageOffset.pageName === 'dashboard') {
            setTimeout(() => {
                window.scrollTo(0, pageOffset.offset);
            }, 0);
            if (tracks?.filtered) {
                this.setState({
                    text: pageOffset?.text || '',
                    isTop: pageOffset?.isTop || undefined,
                    artist: pageOffset?.artist,
                    album: pageOffset?.album,
                });
                if (pageOffset?.artist?.key) {
                    await this.fetchData('albums', {artist: pageOffset?.artist?.key});
                }
            }
            setPageOffset(null);
        } else {
            this.props.GetTracks();
        }

        if (artists?.itemsList?.length && !artists?.text) {
            this.setState({
                artists: {
                    itemsList: artists?.itemsList,
                    hasMore: artists.hasMore,
                    text: ''
                },
            })
        } else {
            this.fetchData('artists');
        }

        if (!this.state?.albums?.itemsList?.length) {
            if (albums?.itemsList?.length && !albums?.text && !albums?.artist) {
                this.setState({
                    albums: {
                        itemsList: albums?.itemsList,
                        hasMore: albums.hasMore,
                        text: ''
                    },
                })
            } else {
                this.fetchData('albums');
            }
        }
    }

    componentWillUnmount() {
        const {tracks} = this.props;
        if (tracks?.itemsList?.length > 100) {
            this.props.GetTracks()
        }
    }

    fetchData(type, reqData = {}) {
        const getFunction = type === 'artists' ? this.props.GetArtists : this.props.GetAlbums;
        return getFunction({
            getResponse: true,
            ...reqData,
        }).then(res => {
            this.setState({
                [type]: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: reqData?.text || '',
                },
            })
        })
    }

    onPopupScroll(e, type) {
        e.persist();
        const {artist} = this.state;
        const {itemsList, text, hasMore} = this.state?.[type];
        //console.log('hasMore', hasMore, type);
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight && hasMore) {
            //  console.log(target.scrollTop, target.offsetHeight, target.scrollHeight)
            const reqData = {
                text,
                getResponse: true,
                offset: itemsList?.length
            }
            if (type === 'albums' && artist?.key) {
                reqData.artist = artist?.key;
            }
            const reqPromise = type === 'artists' ? this.props.GetArtists(reqData) :
                this.props.GetAlbums(reqData);
            reqPromise.then(res => {
                //  console.log('get data', res)
                this.setState({
                    [type]: {
                        itemsList: [...itemsList, ...res.data],
                        hasMore: res.hasMore,
                        text
                    },
                })
            })
        }
    }

    onSelectSearchChanged(text, type) {
        const {artist} = this.state;
        const reqData = {text};

        if (type === 'albums' && artist?.key) {
            reqData.artist = artist?.key;
        }

        this.fetchData(type, reqData)
    }

    loadMoreItems() {
        const {tracks} = this.props;
        const {hasMore} = tracks;
        const {artist, album, text, isTop} = this.state;
        // console.log(name, value)
        const filters = {
            artist: artist?.key,
            album: album?.key,
            text,
            isTop
        };
        hasMore && this.props.GetTracks({
            reset: false,
            offset: tracks?.itemsList?.length,
            ...filters,
        });
    };

    getSearchValue(value) {
        this.setState({
            text: value,
        }, () => {
            !value && this.searchItems();
        })
    }

    handleChangeIsTop=({name, value} = {}) =>{
        const stateData = {
            [name]: value,
        };
        this.setState(stateData, () => {
            this.searchItems()
        });
    }
    handleChangeSelect({name, value, option} = {}) {
        const stateData = {
            [name]: option,
        };
        if (name === 'artist') {
            stateData.album = undefined;
            this.fetchData('albums', {artist: value})
        }

        this.setState(stateData, () => {
            this.searchItems()
        });
    }

    searchItems() {
        const {artist, album, text, isTop} = this.state;
        const filters = {
            artist: artist?.key,
            album: album?.key,
            text,
            isTop
        };
        if (filters?.text?.length === 1) {
            return;
        }
        this.props.GetTracks(filters);
    }

    getScrollPosition() {
        return document.body.scrollTop || document.documentElement.scrollTop;
    }

    goTrackEditPage = () => {
        const {artist, album, text, isTop} = this.state;
        setPageOffset({
            pageName: 'dashboard',
            offset: this.getScrollPosition(),
            artist,
            album,
            text,
            isTop
        })
    }

    render() {
        const {text, artists, albums, artist, album, isTop} = this.state;
        const {tracks, accessPages, isAdmin, trackCardView} = this.props;

        // console.log("trackCardView", trackCardView);
        const haveProductsAccess = isAdmin || accessPages?.includes(ACCESS_PAGES.tracks?.key);
        let artistsOptions = artists?.itemsList?.map(artist => {
            return {
                id: artist.id,
                name: artist?.fullName || "",
                imageUrl: resizeImage(generateImageMediaUrl(artist?.profileImage?.path), 200, 200),
            }
        });
        let albumsOptions = albums?.itemsList?.map(album => {
            return {
                id: album.id,
                name: album?.title || "",
                imageUrl: resizeImage(generateImageMediaUrl(album?.coverImage?.path), 200, 200),
            }
        });

        const filters = <div className={'track-filters'}>
            <InputGroup inputType="selectCustom"
                        placeholder="Ընտրել Արտիստ"
                        name="artist"
                        showSearch={true}
                        labelInValue={true}
                        value={artist}
                        onPopupScroll={(e) => this.onPopupScroll(e, 'artists')}
                        onSearch={(e) => this.onSelectSearchChangedBounded(e, 'artists')}
                        onChange={this.handleChangeSelect}
                        options={artistsOptions}
                        withClear={'Բոլորը'}>
            </InputGroup>
            <InputGroup inputType="selectCustom"
                        placeholder="Ընտրել Ալբոմ"
                        name="album"
                        showSearch={true}
                        labelInValue={true}
                        value={album}
                        onPopupScroll={(e) => this.onPopupScroll(e, 'albums')}
                        onSearch={(e) => this.onSelectSearchChangedBounded(e, 'albums')}
                        onChange={this.handleChangeSelect}
                        options={albumsOptions}
                        withClear={'Բոլորը'}>
            </InputGroup>
            <InputGroup inputType="selectCustom"
                        placeholder="Կարգավիճակ"
                        name="isTop"
                        showSearch={false}
                        labelInValue={false}
                        value={isTop}
                        onChange={this.handleChangeIsTop}
                        options={this.topOptions}
                        withClear={'Բոլորը'}>
            </InputGroup>
        </div>
        return <PageWrapper pageTitle={'Երգեր'}>
            <section className="dashboard">
                <PageHeader linkTitle={haveProductsAccess && "Ավելացնել Երգ"}
                            addingLink={haveProductsAccess && "/dashboard/track/add"}
                            getSearchValue={this.getSearchValue}
                            searchItems={this.searchItems}
                            searchValue={text}
                            search={haveProductsAccess}
                            filters={filters}
                            renderingCustomRightPart={
                                <div className={'toggle-track-view'}>
                                    <LinkButton
                                        className={`list-view-button ${trackCardView ? 'selected' : ''}`}
                                        title={<img src={require("../assets/images/file-manager/list.svg")} alt=""/>}
                                        cb={() => toggleTrackView(true)}/>
                                    <LinkButton
                                        className={`list-view-button ${!trackCardView ? 'selected' : ''}`}
                                        title={<img src={require("../assets/images/file-manager/grid.svg")} alt=""/>}
                                        cb={() => toggleTrackView(false)}/>

                                </div>}
                >
                </PageHeader>
                <InfiniteScroll
                    hasMore={tracks.hasMore}
                    loadMore={this.loadMoreItems}
                    pageStart={0}
                    className={'tracks'}
                    useWindow={true}
                    initialLoad={false}>
                    {tracks && !!tracks.itemsList.length && tracks.itemsList.map((item) => {
                        return <TrackCard key={item?.id}
                                          goTrackEditPage={this.goTrackEditPage}
                                          listView={trackCardView}
                                          data={item}/>
                    })}
                </InfiniteScroll>
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetTracks,
    GetAlbums,
    GetArtists,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
