// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import debounce from 'lodash.debounce';

//import assets
import '../../assets/styles/containerStyles/notifications.scss'

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {Checkbox} from 'antd';

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetNotifications,
    SendNotification,
    GetUsers,
} from "../../redux/actions";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {history} from "../../configs/history";


class SendNotifications extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                notificationId: undefined,
                receiverIds: [],
            },
            notifications: {
                itemsList: [],
                hasMore: false,
                text: ''
            },
            usersList: {
                itemsList: [],
                hasMore: false,
                text: ''
            },
            sendAll: false,
            errors: {},
            fetchData: !!props?.id,
            requestLoading: false,
        };

        this.currentData = null;
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getInputValues = getInputValues.bind(this);
        this.checkAll = changeStateField.bind(this, 'sendAll');
        this.SendNotification = this.SendNotification.bind(this);
        this.onPopupScrollNotifications = this.onPopupScrollNotifications.bind(this);
        // this.onSearchNotification = this.onSearchNotification.bind(this);
        this.onSearchNotificationBounded = debounce(this.onSearchNotification, 500);
        this.onSearchUser = this.onSearchUser.bind(this);
        this.onPopupScrollUsers = this.onPopupScrollUsers.bind(this);
        this.onSearchUserBounded = debounce(this.onSearchUser, 500);
    }

    async componentDidMount() {
        this.props.GetNotifications({getResponse: true, sended: false}).then(res => {
            this.setState({
                notifications: {
                    itemsList: res?.data,
                    hasMore: res?.hasMore,
                    text: ''
                },
            });
        });

        this.props.GetUsers({getResponse: true}).then(res => {
            this.setState({
                usersList: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: ''
                },
            })
        })
    }

    onPopupScrollNotifications(e) {
        e.persist();
        const {itemsList, text, hasMore} = this.state.notifications;
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {

            hasMore && this.props.GetNotifications({
                getResponse: true,
                text,
                offset: itemsList?.length,
                sended: false
            })
                .then(res => {
                    this.setState({
                        notifications: {
                            itemsList: [...itemsList, ...res.data],
                            hasMore: res.hasMore,
                            text
                        },
                    })
                })
        }
    }

    onPopupScrollUsers(e) {
        e.persist();
        const {itemsList, text, hasMore} = this.state.usersList;
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            console.log(target.scrollTop, target.offsetHeight, target.scrollHeight);

            hasMore && this.props.GetUsers({
                getResponse: true,
                text,
                offset: itemsList?.length
            })
                .then(res => {
                    this.setState({
                        usersList: {
                            itemsList: [...itemsList, ...res.data],
                            hasMore: res.hasMore,
                            text
                        },
                    })
                })
        }
    }

    onSearchNotification(text) {
        this.props.GetNotifications({
            getResponse: true,
            text,
            sended: false
        }).then((res => {
            this.setState({
                notifications: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: text
                },
            })
        }))
    }

    onSearchUser(text) {
        this.props.GetUsers({
            getResponse: true,
            text,
        }).then((res => {
            this.setState({
                usersList: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: text
                },
            })
        }))
    }

    async SendNotification() {
        await this.setState({
            requestLoading: true
        });

        const {receiverIds, notificationId} = this.state.fieldsData;

        if (!!receiverIds.length && notificationId) {
            this.props.SendNotification(
                notificationId,
                {receiverIds: receiverIds.join(',')}
            )
                .finally(() => {
                    this.setState({requestLoading: false});
                    history.push('/notifications');
                })
        }
    }

    render() {
        const {fieldsData, requestLoading, fetchData, notifications, usersList, sendAll} = this.state;
        const {mainLanguage} = this.props;

        let notificationsOptions = notifications?.itemsList?.map(item => {
            return {
                id: item.id,
                name: item?.translations?.find(lg => lg.languageId === mainLanguage)?.title || "",
                imageUrl: resizeImage(generateImageMediaUrl(item?.image?.path), 200, 200),
            }
        });

        let userOptions = usersList?.itemsList?.map(item => {
            return {
                id: item.id,
                name: (item?.firstName || "") + " " +  (item?.lastName || ""),
                imageUrl: resizeImage(generateImageMediaUrl(item?.profilePicturePath?.path), 200, 200),
            }
        });


        return <PageWrapper pageTitle={'Ուղարկել Ծանուցում'}>
            <section className="general-add-edit">

                <div className='row-3'>
                    <InputGroup inputType="selectCustom"
                                placeholder="Ծանուցումներ"
                                label="Ծանուցումներ"
                                name="notificationId"
                                showSearch={true}
                                loading={fetchData}
                                required={true}
                                onPopupScroll={this.onPopupScrollNotifications}
                                onSearch={this.onSearchNotificationBounded}
                                value={fieldsData?.notificationId}
                                onChange={this.getInputValues}
                                options={notificationsOptions}/>
                </div>
                <Checkbox onChange={
                    (e) => this.checkAll(e.target.checked)
                }>Ուղարկել բոլորին</Checkbox>

                <div className='row-3'>
                    <InputGroup inputType="selectCustom"
                                placeholder="Օգտատերեր"
                                label="Օգտատերեր"
                                name="receiverIds"
                                showSearch={true}
                                loading={fetchData}
                                required={true}
                                mode={'multiple'}
                                disabled={sendAll}
                                onPopupScroll={this.onPopupScrollUsers}
                                onSearch={this.onSearchUserBounded}
                                value={fieldsData?.receiverIds}
                                onChange={this.getInputValues}
                                options={userOptions}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Ուղարկել'}
                                loading={requestLoading}
                                cb={this.SendNotification}/>
                </div>

            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetNotifications,
    SendNotification,
    GetUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendNotifications)
