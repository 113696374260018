// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetReports, setPageOffset} from "../../redux/actions";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {ReactComponent as ContentWarning} from "../../assets/images/ic_content_warning.svg";
import {REPORT_TYPES} from "../../constants/constTypes";

const typeOptions = Object.keys(REPORT_TYPES).map(key => ({
    id: key,
    name: REPORT_TYPES[key].value
}));

class Reports extends Component {
    constructor() {
        super();
        this.state = {
            type: undefined,
        };

        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.searchItems = this.searchItems.bind(this);
        this.getSelectValue = this.getSelectValue.bind(this);
    }

    componentDidMount() {
        const {pageOffset} = this.props;
        if (pageOffset.pageName === 'reports') {
            setTimeout(() => {
                window.scrollTo(0, pageOffset.offset);
            }, 0);

            setPageOffset(null);
        } else {
            this.props.GetReports();
        }

    }
    searchItems() {
        const {track, user, type} = this.state;
        this.props.GetReports({
            track,
            user,
            type
        });
    }
    getSelectValue({name, value}) {
        this.setState({
            [name]: value
        }, () => this.searchItems())
    }

    loadMoreItems() {
        const {reports} = this.props;
        const {type} = this.state;
        reports?.hasMore && this.props.GetReports({
            reset: false,
            offset: reports?.itemsList?.length,
            type
        });
    };

    render() {
        let {reports, requestLoading} = this.props;
        let {type} = this.state;
        let itemListHeaderInfo = {
            generalInfo: ["Նկար", "Անվանում", "Ստուգված"],
            styles: {
                1: {justifyContent: 'flex-start'},
            },
        };
        let itemListInfo = [];

        reports && reports.itemsList && reports.itemsList.forEach(item => {
            itemListInfo.push({
                id: item.id,
                values: [
                    {
                        type: 'image',
                        path: resizeImage(generateImageMediaUrl(item?.track?.coverImage?.path), 200, 200),
                    },
                    <span className={"info-title-wrapper"}>
                        <div className={'info-title'}>
                            {item?.track?.title || "-"}
                            <span className={'info-sub-title'}>{item?.track?.subTitle || ""}</span>
                        </div>
                        {item?.contentWarning && <ContentWarning title={''} className="e-icon"/>}
                    </span>,
                    item?.checked ? <span style={{color: 'green', fontWeight: '600'}}>&#10003;</span> : ''
                ],
            });
        });

        return <PageWrapper pageTitle={'Հաշվետվություն'}>
            <PageHeader renderingCustomRightPart={<div className="device-filters-wrapper">
                <InputGroup inputType="selectCustom"
                            placeholder="Տեսակ"
                            name="type"
                            showSearch={false}
                            value={type}
                            onChange={this.getSelectValue}
                            options={typeOptions}
                            withClear={'Բոլորը'}>
                </InputGroup>
            </div>}/>
            <ItemsList itemListInfo={itemListInfo}
                       itemListHeaderInfo={itemListHeaderInfo}
                       loading={requestLoading}
                       detailsLink={"reports"}
                       infiniteScroll={true}
                       useWindow={true}
                       hasMore={reports?.hasMore}
                       loadMoreItems={this.loadMoreItems}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetReports,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
