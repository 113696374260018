// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import Styles
import '../../assets/styles/containerStyles/user-details.scss';

// Import components
import ItemsList from "../../components/ItemsList";
import PageWrapper from '../../components/pageContentViews/pageWrapper';
import ModalSendNotification from "../../components/uiElements/ModalSendNotification";
import {SkeletonSmallItem} from "../../components/uiElements/SkelatonISmalltem";
import {CustomTabs} from "../../components/uiElements/Tabs";
// import {Switch} from 'antd';
// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";
import {changeStateField, toggleStateField} from "../../utils/helperFunctions";
import {ClearUserById, GetUserById, ToggleUserBlockState,} from "../../redux/actions";
import {getDetailsHeaderData, getDetailsLink, getDetailsListData, userDetailsTabs} from "./utils/constants";
import {LinkButton} from "../../components/buttons/buttons";
import moment from "moment";

class UserDetails extends Component {
    constructor() {
        super();
        this.state = {
            isBlockedUpdated: false,
            notificationModalOpen: false,
            orders: {
                itemsList: [],
                hasMore: false
            },
            reviews: {
                itemsList: [],
                hasMore: false
            },
            loading: true,
            activeTab: userDetailsTabs[0].key,
        };
        this.changeTab = changeStateField.bind(this, "activeTab");
        this.loadMoreorders = this.loadMoreorders.bind(this);
        this.loadMorereviews = this.loadMorereviews.bind(this);
        this.toggleNotificationModal = toggleStateField.bind(this, "notificationModalOpen");
    }

    async componentDidMount() {
        const id = this.props.id;
        await this.props.GetUserById(id);
        try {
            const orders = await this.props.GetOrders({userId: id, getResponse: true});
            const reviews = await this.props.GetReviews({userId: id, getResponse: true});
            // console.log(orders);
            Array.isArray(orders?.data) && this.setState({
                orders: {
                    itemsList: orders.data,
                    hasMore: orders.hasMore
                },
                loading: false
            });
            Array.isArray(reviews?.data) && this.setState({
                reviews: {
                    itemsList: reviews.data,
                    hasMore: reviews.hasMore
                },
                loading: false
            })
        } catch {
            this.setState({loading: false})
        }
    }

    componentWillUnmount() {
        this.props.ClearUserById();
    }

    async loadMoreorders() {
        // console.log('load-more-orders')
        const {orders} = this.state;
        if (!this.fetchingOrders && orders.hasMore) {
            this.fetchingOrders = true;
            try {
                const newData = await this.props.GetOrders({
                    userId: this.props.id,
                    getResponse: true,
                    offset: orders.itemsList.length
                });
                Array.isArray(newData) && this.setState({
                    orders: {
                        itemsList: orders.itemsList.concat(newData.data),
                        hasMore: newData.hasMore
                    }
                })
                this.fetchingOrders = false
            } catch {
            }
        }
    };

    async loadMorereviews() {
        // console.log('load-more-orders')
        const {reviews} = this.state;
        if (!this.fetchingReviews && reviews.hasMore) {
            this.fetchingReviews = true;
            try {
                const newData = await this.props.GetReviews({
                    userId: this.props.id,
                    getResponse: true,
                    offset: reviews.itemsList.length
                });
                Array.isArray(newData) && this.setState({
                    reviews: {
                        itemsList: reviews.itemsList.concat(newData.data),
                        hasMore: newData.hasMore
                    }
                })
                this.fetchingReviews = false
            } catch {
            }
        }
    };

    async upDateUser({value}) {
        const id = this.props.id;
        await this.setState({
            isBlockedUpdated: true,
        });
        const reqData = {isBlocked: value};
        this.props.ToggleUserBlockState(id, reqData).finally(() => {
            this.setState({
                isBlockedUpdated: false,
            });
        });
    }

    render() {
        const {userById, requestLoading} = this.props;
        const {notificationModalOpen, userByState, activeTab, loading} = this.state;
        const user = userById || userByState;
        let itemListHeaderInfo = {
            generalInfo: getDetailsHeaderData(activeTab),
            fixWidths: {0: 60},
            styles: {
                1: {justifyContent: 'flex-start'}
            },
            actions: activeTab === userDetailsTabs?.[1].key ? [] : ["details"],
        };
        const itemListInfo = getDetailsListData(activeTab, user);

        const detailsLink = getDetailsLink(activeTab);
        return <PageWrapper>
            <section className="user-details">
                <div className="top-part">
                   <div>
                       <div className="user-image">
                           <img src={generateFileMediaUrl(user?.profilePicturePath?.path)} alt="user"/>
                       </div>
                       <div className="user-info">
                        <span className="username">{
                            requestLoading ?
                                <SkeletonSmallItem/> : `${user?.firstName || ""} ${user?.lastName || ""}`
                        }
                        </span>
                           <div>{
                               requestLoading ?
                                   <SkeletonSmallItem/> : `${user?.username || ""}`
                           }</div>
                       </div>
                   </div>
                    <div className='created-at'>
                        {moment(user?.createdAt).format('DD/MM/YYYY HH:mm')}
                    </div>
                    {/*<div className={'block-wrapper'}>
                        <label>Blocked</label>
                        <Switch checked={user?.isBlocked} loading={isBlockedUpdated}
                                onChange={(checked) => this.upDateUser({
                                    name: "isBlocked",
                                    value: checked,
                                })}/>
                    </div>*/}
                </div>
                {/*<div className={'send-notif'}>*/}
                {/*    <LinkButton title={"Ծանուցում"}*/}
                {/*                cb={this.toggleNotificationModal}/>*/}
                {/*</div>*/}
                <CustomTabs tabsList={userDetailsTabs} activeTab={activeTab} changeTab={this.changeTab}/>

                <ItemsList itemListInfo={itemListInfo}
                           itemListHeaderInfo={itemListHeaderInfo}
                           detailsLink={detailsLink}
                           loading={loading}
                           className={`user-details-list ${activeTab}`}
                           infiniteScroll={false}/>
            </section>
            <ModalSendNotification visible={notificationModalOpen}
                                   userIds={[this.props.id]}
                                   closeModal={this.toggleNotificationModal}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetUserById,
    ClearUserById,
    ToggleUserBlockState
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
