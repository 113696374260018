import {combineReducers} from "redux";
import storageSession from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';

// import reducers
import auth from "./auth";
import utils from "./utils";
import languages from "./languages";
import artists from "./artists";
import albums from "./albums";
import users from "./users";
import moderators from "./moderators";
import media from "./media";
import section from "./section";
import track from "./track";
import genre from "./genre";
import youTubeKey from "./youTubeKeys";
import device from "./devices";
import settings from "./settings";
import notifications from "./notifications";
import socket from "./socket";
import podcasts from "./podcasts";

const mediaPersistConfig = {
    key: 'media',
    storage: storageSession,
    whitelist: ['mediaHistory']
};

export default combineReducers({
    auth,
    utils,
    languages,
    albums,
    media: persistReducer(mediaPersistConfig, media),
    users,
    moderators,
    artists,
    section,
    track,
    genre,
    youTubeKey,
    device,
    settings,
    notifications,
    socket,
    podcasts,
});
