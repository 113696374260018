import {_urlPodcast, _urlTracks, request} from "../api";
import {PODCASTS_CONSTS, UTIL_CONSTS} from "../constants";
import {LIMIT_GET_ALBUMS} from "../../constants/constLimitCounts";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetPodcasts = ({
                                reset = true,
                                offset = 0,
                                text = '',
                                limit = LIMIT_GET_ALBUMS,
                                owner = null,
                            } = {}) => {
    let url = `${_urlPodcast}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${encodeURIComponent(text)}`);
    owner && (url += `&owner=${owner}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING})
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: PODCASTS_CONSTS.GET_PODCASTS,
                    payload: {
                        data,
                        text,
                        owner,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            }).finally(() => {
                dispatch({type: UTIL_CONSTS.END_LOADING})
            })

    }
};

export const GetPodcastById = (id) => {
    const requestData = {
        url: `${_urlPodcast}/${id}/tracks`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                return res?.data;
            })
            .catch(() => {
            })
    }
};

export const PodcastReview = (id, data) => {
    const requestData = {
        url: `${_urlTracks}/podcast/${id}`,
        token: true,
        method: "PUT",
        data
    };
    console.log(data, 'data');
    return dispatch => {
        return request(requestData)
            .then(()=>{
                notificationSuccess({
                    description: 'Փոդքաստը հաջողությամբ փոփոխվեց!'
                })
            })
    }
};