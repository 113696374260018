import {request, _urlYouTubeKeys,} from "../api";
import {YOUTUBE_KEYS_CONSTS, UTIL_CONSTS} from "../constants";
import swal from "sweetalert";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetYouTubeKeys = ({
                                   platform = null,
                                   type = null,
                                   allowUse = null,
                               } = {}) => {
    let url = `${_urlYouTubeKeys}`;
    const params = new URLSearchParams();
    platform && params.append('platform', platform);
    type && params.append('type', type);
    allowUse !== null && params.append('allowUse', !!allowUse);
    params && (url += '?' + params);
    const requestData = {
        url,
        token: true,
        method: "GET",

    };

    return dispatch => {
        dispatch({type: UTIL_CONSTS.START_LOADING})
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: YOUTUBE_KEYS_CONSTS.GET_YOUTUBE_KEYS,
                    payload: {data, platform, type, allowUse}
                });
            }).finally(() => {
                dispatch({type: UTIL_CONSTS.END_LOADING})
            })
    }
};


export const DeleteYouTubeKeyById = id => {
    const requestData = {
        url: `${_urlYouTubeKeys}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: YOUTUBE_KEYS_CONSTS.DELETE_YOUTUBE_KEY_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateYouTubeKey = data => {
    const requestData = {
        url: _urlYouTubeKeys,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: YOUTUBE_KEYS_CONSTS.CREATE_YOUTUBE_KEY,
                    payload: res.data
                });
                notificationSuccess({
                    description: "YouTube կոդը հաջողությամբ ստեղծվեց!"
                });
                history.push('/youTubeKeys')
            })
            .catch(() => {
            })
    }
};

export const UpdateYouTubeKeyById = (id, data, redirect = true) => {
    const requestData = {
        url: `${_urlYouTubeKeys}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: YOUTUBE_KEYS_CONSTS.UPDATE_YOUTUBE_KEY_BY_ID,
                    payload: res.data
                });
                redirect &&  notificationSuccess({
                    description: "YouTube կոդը հաջողությամբ փոփոխվեց!"
                });
                redirect && history.push('/youTubeKeys')
            })
            .catch(() => {
            })
    }
};
