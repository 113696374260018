import {ALBUMS_CONSTS, AUTH_CONSTS,} from "../constants";

export const initialState = {
    albums: {
        itemsList: [],
        text: '',
        artist:null,
        hasMore:false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ALBUMS_CONSTS.GET_ALBUMS:
            return {
                ...state,
                albums: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.albums.itemsList, ...action.payload.data],
                    text: action.payload.text,
                    artist: action.payload.artist,
                    hasMore: action.payload.hasMore,
                }
            };
        case ALBUMS_CONSTS.CREATE_ALBUM:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    itemsList: [action.payload].concat(state.albums.itemsList)
                }
            };
        case ALBUMS_CONSTS.UPDATE_ALBUM_BY_ID:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    itemsList: state.albums.itemsList.map(artist => artist.id === action.payload.id
                        ? action.payload : artist),
                },
            };
        case ALBUMS_CONSTS.DELETE_ALBUM_BY_ID:
            return {
                ...state,
                albums: {
                    ...state.albums,
                    itemsList: state.albums.itemsList.filter(artist => artist.id !== action.payload),
                },
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;

        default:
            return state;
    }
}
