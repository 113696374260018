// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Icon, Dropdown, Menu} from "antd";
import {history} from "../../configs/history";

// Import Styles
import '../../assets/styles/cards/track-card.scss';

// Import components
import {MaterialIcon} from "../utils/Icon";
import swal from "sweetalert";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {
    DeleteTrack,
    setCurrentPlayedTrack,
    togglePlayState,
    ToggleTrackVisibility
} from "../../redux/actions";
import {resizeImage} from "../../utils/resizeImage";


class TrackCard extends Component {
    constructor() {
        super();
        this.state = {};
    }

    deleteProduct = id => {
        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել երգը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteTrack(id);
            }
        });
    };

    goEditPage = () => {
        this.props.goTrackEditPage && this.props.goTrackEditPage();
    }

    onClickPlayButton = () => {
        const {data, currentPlayedTrack, playState} = this.props;
        if (playState) {
            if (currentPlayedTrack?.id === data.song?.id) {
                togglePlayState(false);
            } else {
                setCurrentPlayedTrack(data.song);
            }
        } else {
            if (currentPlayedTrack?.id === data.song?.id) {
                togglePlayState(true);
            } else {
                //    console.log('set track')
                setCurrentPlayedTrack(data.song);
                togglePlayState(true);
            }
        }
    }

    render() {
        const {data, inSelectorModal, listView = false, isSelected, currentPlayedTrack, playState,} = this.props;
        const menu = (<Menu onClick={(e) => {
            e.domEvent.stopPropagation();
            e.domEvent.preventDefault();
        }}>
            <Menu.Item key="1">
                <Link to={"/dashboard/track/edit/" + data?.id} onClick={this.goEditPage}>Edit</Link>
            </Menu.Item>
            <Menu.Item key="2" onClick={() => {
                this.props.ToggleTrackVisibility(data?.id, {isHidden: !data?.isHidden})
            }}>
                {data?.isHidden ? 'Show' : 'Hide'}
            </Menu.Item>
            <Menu.Item key="3">
                <Link to={{
                    pathname: "/dashboard/track/add",
                    state: data
                }}>Copy</Link>
            </Menu.Item>
            <Menu.Item key="4">
                <span onClick={this.deleteProduct.bind(this, data?.id)}>Delete</span>
            </Menu.Item>
        </Menu>);

        const isCurrentTrack = currentPlayedTrack?.id === data?.song?.id;
        const iconState = isCurrentTrack && playState;

        return listView ? <Link to={`/dashboard/track/edit/${data?.id}`}
                                className={`track-grid-card ${isSelected ? 'isSelected' : ""}`}
                                onClick={(e) => {
                                    if (inSelectorModal) {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        inSelectorModal && this.props.onSelect(data, isSelected)
                                    } else {
                                        this.goEditPage()
                                    }
                                }}>
                {inSelectorModal && <div className={`check-mark ${isSelected ? 'show' : ''}`}>
                    <img src={require('../../assets/images/check-mark.svg')} alt=""/>
                </div>}
                {data?.isTop && <div className={`badge`}>Top</div>}
                {this.props.onDelete &&
                <div className={`delete-btn`} onClick={() => this.props.onDelete(data?.id)}>
                    <MaterialIcon icon="delete"/>
                </div>}
                {!inSelectorModal && <Dropdown overlay={menu}
                                               placement={"bottomRight"}>
                    <Icon className="more" type="more" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault()
                    }}/>
                </Dropdown>}
                {data?.isHidden &&
                <img className="hidden-track-icon" src={require("../../assets/images/ic_hide.svg")} alt=""/>
                }

                <img className="track-img"
                     src={resizeImage(generateImageMediaUrl(data?.coverImage?.path))} alt=""/>
                <div className={'track-info'}>
                    <div className="track-title">{data?.title}<span className="track-subTitle"> {data?.subTitle}</span>
                    </div>
                    {!inSelectorModal && <>
                        <div className="description">{data?.description || ''}</div>
                    </>}
                </div>
                {data?.song?.path &&
                <div className="player" onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                }}>
                    <button className={`play-button ${isCurrentTrack ? 'colored' : ''}`}
                            onClick={!inSelectorModal && this.onClickPlayButton}>
                        {iconState ? <img src={require("../../assets/images/ic_pause_white.svg")} alt=""/> :
                            <img src={require("../../assets/images/ic_play_white.svg")} alt=""/>}
                    </button>
                </div>}
            </Link>
            :
            <Link to={`/dashboard/track/edit/${data?.id}`}
                  className={`track-card ${isSelected ? 'isSelected' : ""}`}
                  onClick={(e) => {
                      if (inSelectorModal) {
                          e.stopPropagation();
                          e.preventDefault();
                          inSelectorModal && this.props.onSelect(data, isSelected)
                      } else {
                          this.goEditPage()
                      }
                  }}>
                {inSelectorModal && <div className={`check-mark ${isSelected ? 'show' : ''}`}>
                    <img src={require('../../assets/images/check-mark.svg')} alt=""/>
                </div>}
                {data?.isTop && <div className={`badge`}>Top</div>}
                {this.props.onDelete &&
                <div className={`delete-btn`} onClick={() => this.props.onDelete(data?.id)}>
                    <MaterialIcon icon="delete"/>
                </div>}
                {!inSelectorModal && <Dropdown overlay={menu}
                                               placement={"bottomRight"}>
                    <Icon className="more" type="more" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault()
                    }}/>
                </Dropdown>}
                {data?.isHidden &&
                <img className="hidden-track-icon" src={require("../../assets/images/ic_hide.svg")} alt=""/>
                }
                <div>
                    <img className="track-img"
                         src={resizeImage(generateImageMediaUrl(data?.coverImage?.path))} alt=""/>
                    <div className="track-title">{data?.title}<span className="track-subTitle"> {data?.subTitle}</span>
                    </div>
                    {!inSelectorModal && <>
                        <div className="description">{data?.description || ''}</div>
                    </>}
                    {data?.song?.path &&
                    <div className="player" onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}>
                        <button className={`play-button ${isCurrentTrack ? 'colored' : ''}`}
                                onClick={!inSelectorModal && this.onClickPlayButton}>
                            {iconState ? <img src={require("../../assets/images/ic_pause_white.svg")} alt=""/> :
                                <img src={require("../../assets/images/ic_play_white.svg")} alt=""/>}
                            {/*<MaterialIcon icon={iconState ? 'pause' : 'play_arrow'}/>*/}
                        </button>
                    </div>}
                </div>
            </Link>

    }
}

const mapDispatchToProps = {
    DeleteTrack,
    ToggleTrackVisibility
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackCard)
