import {request, _urlArtists, _urlYandex} from "../api";
import {ARTISTS_CONSTS, UTIL_CONSTS} from "../constants";
import {history} from "../../configs/history";
import axios from 'axios';
import {LIMIT_GET_ARTISTS} from "../../constants/constLimitCounts";
import { notificationError, notificationSuccess } from "../../components/uiElements/Notifications";
import { store } from "../store";

export const GetArtists = ({
                               reset = true,
                               offset = 0,
                               text = '',
                               limit = LIMIT_GET_ARTISTS,
                               getResponse = false,
                               changeLoadingState = true,
                           } = {}) => {
    let url = `${_urlArtists}?offset=${offset}&limit=${limit}`;
    text && (url += `&text=${encodeURIComponent(text)}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        !getResponse && reset && changeLoadingState && dispatch({type: UTIL_CONSTS.START_LOADING})
        return request(requestData)
            .then(({data}) => {
                if (!getResponse) {
                    dispatch({
                        type: ARTISTS_CONSTS.GET_ARTISTS,
                        payload: {
                            data,
                            text,
                            reset,
                            hasMore: data.length === limit,
                        }
                    });
                } else {
                    return {
                        data,
                        hasMore: data.length === limit,
                    }
                }
            }).finally(() => {
                !getResponse && reset && changeLoadingState && dispatch({type: UTIL_CONSTS.END_LOADING})
            })
    }
};


export const GetArtistById = (id) => {
    const requestData = {
        url: `${_urlArtists}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                return res?.data;
            })
            .catch(() => {
            })
    }
};


export const DeleteArtistById = id => {
    const requestData = {
        url: `${_urlArtists}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: ARTISTS_CONSTS.DELETE_ARTIST_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateArtist = (data, redirect=true) => {
    const requestData = {
        url: _urlArtists,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ARTISTS_CONSTS.CREATE_ARTIST,
                    payload: res.data
                });

                notificationSuccess({
                    description: "Արտիստը հաջողությամբ ստեղծվեց"
                })

                redirect && history.push('/artists');
                return res.data;
            })
                .catch((err) => {
                    if (err && err.response && err.response.status === 409) {
                        notificationError({
                            description: 'Սլագը գոյություն ունի!'
                        })
                    }
                })
    }
};

export const UpdateArtistById = (id, data, redirect = true) => {
    const requestData = {
        url: `${_urlArtists}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ARTISTS_CONSTS.UPDATE_ARTIST_BY_ID,
                    payload: res.data
                });
                redirect && notificationSuccess({
                    description: "Արտիստը հաջողությամբ փոփոխվեց!"
                })
                redirect && history.push('/artists')
            })
                .catch((err) => {
                    if (err && err.response && err.response.status === 409) {
                        notificationError({
                            description: 'Սլագը գոյություն ունի!'
                        })
                    }
                })
    }
};


export const UpdateArtistsPositions = items => {
    const requestData = {
        url: _urlArtists,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Արտիստը հաջողությամբ փոփոխվեց!"
                })
            })
            .catch(() => {
            })
    }
};


export const UpdateArtistTracksPositions = (id, data) => {
    const requestData = {
        url: `${_urlArtists}/${id}/tracks`,
        token: true,
        method: "PUT",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                notificationSuccess({
                    description: "Արտիստի երգերը հաջողությամբ փոփոխվեցին"
                })
            })
    }
};

export const UpdateArtistAlbumsPositions = (id, data) => {
    const requestData = {
        url: `${_urlArtists}/${id}/albums`,
        token: true,
        method: "PUT",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                notificationSuccess({
                    description: "Արտիստի ալբոմները հաջողությամբ փոփոխվեցին"
                })
            })
    }
};

export const GetArtistYandexTracksList = (id) => {
    const scope = store.getState() && store.getState().auth && store.getState().auth.scope;
    const accessToken= store.getState().auth.accessToken

    return dispatch => {
        return axios.get(`${_urlYandex}?yandexId=${id}`, {
            responseType: 'blob',
            headers: {
                "Accept": "text/html",
                "Content-Type": "text/html",
                "scope": scope,
                "x-access-token": accessToken,
            }
        }).then(res => {
            notificationSuccess({
                description: "Արտիստի երգերը հաջողությամբ բեռնվեցին"
            });
            console.log('artist tracks-> ', res.data);
            return res.data
        })


    }
};
