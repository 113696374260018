//media modal
export const MEDIA_MODAL_TYPES = {
    MEDIA_MAIN: 'MEDIA_MAIN',
    MEDIA_ARRAY: 'MEDIA_ARRAY',
};

export const MEDIA_TYPES = {
    FILE: 'FILE',
    FOLDER: 'FOLDER',
};

export const UPLOAD_TYPES = {
    SELF: 'SELF',
    URL: 'URL',
};

export const YOUTUBE_KEYS_PLATFORMS = {
    iOS: {
        id: 'iOS',
        name: 'Ios'
    },
    Android: {
        id: 'Android',
        name: 'Android'
    }
};

export const YOUTUBE_KEYS_TYPES = {
    production: {
        id: 'production',
        name: 'Production'
    },
    development: {
        id: 'development',
        name: 'Development'
    }
};

export const ALBUM_TYPES = {
    MAIN: {
        key: "MAIN",
        name: 'Ալբոմ'
    },
    SINGLE: {
        key: "SINGLE",
        name: 'Սինգլ'
    },
    PLAYLIST: {
        key: "PLAYLIST",
        name: 'Փլեյլիստ'
    },
};

export const NOTIFICATION_TYPES = {
    INFORMATION: {
        key: "INFORMATION",
        requestKey: "INFORMATION",
        name: 'Ինֆորմացիոն'
    },
    ARTIST: {
        key: "ARTIST",
        requestKey: "ARTIST",
        name: 'Արտիստ'
    },
    TRACK: {
        key: "TRACK",
        requestKey: "TRACK",
        name: 'Երգ'
    },
    ALBUM: {
        key: "ALBUM",
        requestKey: "SINGLE,MAIN",
        name: 'Ալբոմ'
    },
    // SINGLE: {
    //     key: "SINGLE",
    //     name: 'Սինգլ'
    // },
    PLAYLIST: {
        key: "PLAYLIST",
        requestKey: "PLAYLIST",
        name: 'Փլեյլիստ'
    },
};

export const REPORT_TYPES = {
    INCONSISTENT_ARTIST: {
        type: "INCONSISTENT_ARTIST",
        value: "This track wasn't recorded by the artist shown"
    }, //անհամապատասխան արտիստ
    AUDIO_PROBLEM: {
        type: "AUDIO_PROBLEM",
        value: "There's an audio problem"
    },
    UNDESIRABLE_CONTENT: {
        type: "UNDESIRABLE_CONTENT",
        value: "Undesirable content"
    }, //Անցանկալի բովանդակություն
    OTHER: {
        type: "OTHER",
        value: "Other"
    }
}
export const PODCAST_TYPES = {
    PENDING: {
        key: "PENDING",
        name: 'Ընթացիկ',
        action: 'Ընթացիկ'
    },
    ACCEPTED: {
        key: "ACCEPTED",
        name: 'Հաստատված',
        action: 'Հաստատել'
    },
    REJECTED: {
        key: "REJECTED",
        name: 'Մերժված',
        action: 'Մերժել'
    },
};
