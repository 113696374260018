import { _urlGenres, request } from "../api";
import { GENRE_CONSTS, UTIL_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";
export const GetGenres = ({ changeLoadingState = true } = {}) => {
    const requestData = {
        url: _urlGenres,
        token: true,
        method: "GET",
    };

    return dispatch => {
        changeLoadingState && dispatch({ type: UTIL_CONSTS.START_LOADING });
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: GENRE_CONSTS.GET_GENRES,
                    payload: data
                });
            }).finally(() => {
                changeLoadingState && dispatch({type: UTIL_CONSTS.END_LOADING});
            })

    }
};

export const GetGenreById = id => {
    const requestData = {
        url: `${_urlGenres}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then((res) => {
                return res?.data
            })
            .catch(() => {
            })
    }
};


export const DeleteGenreById = id => {
    const requestData = {
        url: `${_urlGenres}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: GENRE_CONSTS.DELETE_GENRE_BY_ID,
                    payload: id
                });
            })
            .catch(() => {
            })
    }
};

export const CreateGenre = data => {
    const requestData = {
        url: _urlGenres,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: GENRE_CONSTS.CREATE_GENRE,
                    payload: res.data
                });
                notificationSuccess({
                    description: "ժանրը հաջողությամբ ստեղծվեց!"
                })
                history.push('/genre')
            })
            .catch(() => {
            })
    }
};

export const UpdateGenreById = (id, data, showNotification = true) => {
    const requestData = {
        url: `${_urlGenres}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
                .then(res => {
                    if ( showNotification ) {
                        dispatch({
                            type: GENRE_CONSTS.UPDATE_GENRE_BY_ID,
                            payload: res.data
                        });
                        notificationSuccess({
                            description: "ժանրը հաջողությամբ փոփոխվեց!"
                        })
                        history.push('/genre')
                    }
                })
            .catch(() => {
            })
    }
};

export const UpdateGenresPositions = items => {
    const requestData = {
        url: _urlGenres,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "ժանրը հաջողությամբ փոփոխվեց!"
                })
            })
            .catch(() => {
            })
    }
};
