import {YOUTUBE_KEYS_PLATFORMS, YOUTUBE_KEYS_TYPES} from "../../constants/constTypes";

export const keyTypeOptions = Object.keys(YOUTUBE_KEYS_TYPES).map(key => {
    return YOUTUBE_KEYS_TYPES[key];
})

export const keyPlatformOptions = Object.keys(YOUTUBE_KEYS_PLATFORMS).map(key => {
    return YOUTUBE_KEYS_PLATFORMS[key];
})
export const keyAllowUseOptions = [
    {
        id: 1,
        name: 'Ակտիվ'
    },
    {
        id: 0,
        name: 'Ոչ Ակտիվ'
    }
]
