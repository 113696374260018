import {ACTION_TYPES,} from "../../socket/constants";
import {AUTH_CONSTS,} from "../constants";

export const initialState = {
    socketConnected: false,
    albumDownloading:false,
    trackDownloading:false,
    downloadedAlbum:null,
    downloadedTrack:null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.AUTHENTICATED:
            // console.log('N_AUTHENTICATED RED');
            return {
                ...state,
                socketConnected: true
            };
        case ACTION_TYPES.UNAUTHORIZED:
            return {
                ...state,
                socketConnected: false
            };

        case ACTION_TYPES.ALBUM_DOWNLOADING:
            return {
                ...state,
                albumDownloading: action.payload
            };
        case ACTION_TYPES.SET_DOWNLOADED_ALBUM:
            return {
                ...state,
                downloadedAlbum: action.payload
            };
        case ACTION_TYPES.TRACK_DOWNLOADING:
            return {
                ...state,
                trackDownloading: action.payload
            };
        case ACTION_TYPES.SET_DOWNLOADED_TRACK:
            return {
                ...state,
                downloadedTrack: action.payload
            };

        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
