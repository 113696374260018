// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import debounce from 'lodash.debounce';

// Import styles
import "../assets/styles/components/tracks-selector-modal.scss";

// Import Components
import {Modal} from "antd";
import {LinkButton} from "./buttons/buttons";
import {InputGroup} from "./uiElements/inputGroup";
import InfiniteScroll from "react-infinite-scroller";
import TrackCard from "./cards/TrackCard";


// Import utils
import {GetTracks, GetAlbums, GetArtists} from "../redux/actions";
import {mapStateToProps} from "../redux/mapStateToProps";
import {changeStateInputValue} from "../utils/helperFunctions";
import {resizeImage} from "../utils/resizeImage";
import {generateImageMediaUrl} from "../utils/generateMemberMediaUrl";

class ProductsSelectorModal extends Component {
    constructor() {
        super();
        this.state = {
            selectedTracks: [],
            text: '',
            artist: undefined,
            album: undefined,
            artists: {
                itemsList: [],
                hasMore: false,
                text: ''
            },
            albums: {
                itemsList: [],
                hasMore: false,
                text: '',
                artist: null,
            }
        };
        this.inputElement = React.createRef();
        this.getSearchValue = this.getSearchValue.bind(this);
        this.selectProduct = this.selectProduct.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.insertMedia = this.insertMedia.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.onPopupScroll = this.onPopupScroll.bind(this);
        this.onSelectSearchChanged = this.onSelectSearchChanged.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
        this.onSelectSearchChangedBounded = debounce(this.onSelectSearchChanged, 500);
    }

    componentDidMount() {
        const {tracks, artists, albums} = this.props;
        (tracks?.filtered || !tracks?.itemsList?.length) && this.props.GetTracks();
        console.log("artists", artists);
        console.log("albums", albums);
        if (artists?.itemsList?.length && !artists?.text) {
            this.setState({
                artists: {
                    itemsList: artists?.itemsList,
                    hasMore: artists.hasMore,
                    text: '',
                },
            })
        } else {
            this.fetchData('artists');
        }


        if (albums?.itemsList?.length && !albums?.text && !albums?.artist) {
            this.setState({
                albums: {
                    itemsList: albums?.itemsList,
                    hasMore: albums.hasMore,
                    text: '',
                },
            })
        } else {
            this.fetchData('albums');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isOpen, appendTracks} = this.props;
        if (!prevProps.isOpen && isOpen && Array.isArray(appendTracks)) {
            this.setState({
                selectedTracks: appendTracks
            })
        }
    }

    fetchData(type, reqData = {}) {
        const getFunction = type === 'artists' ? this.props.GetArtists : this.props.GetAlbums;
        return getFunction({
            getResponse: true,
            ...reqData,
        }).then(res => {
            this.setState({
                [type]: {
                    itemsList: res.data,
                    hasMore: res.hasMore,
                    text: reqData?.text || '',
                },
            })
        })
    }

    onPopupScroll(e, type) {
        e.persist();
        const {artist} = this.state;
        const {itemsList, text, hasMore} = this.state?.[type];
        //console.log('hasMore', hasMore, type);
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight && hasMore) {
            //  console.log(target.scrollTop, target.offsetHeight, target.scrollHeight)
            const reqData = {
                text,
                getResponse: true,
                offset: itemsList?.length
            }
            if (type === 'albums' && artist?.key) {
                reqData.artist = artist?.key;
            }
            const reqPromise = type === 'artists' ? this.props.GetArtists(reqData) :
                this.props.GetAlbums(reqData);
            reqPromise.then(res => {
                //  console.log('get data', res)
                this.setState({
                    [type]: {
                        itemsList: [...itemsList, ...res.data],
                        hasMore: res.hasMore,
                        text
                    },
                })
            })
        }
    }

    onSelectSearchChanged(text, type) {
        const {artist} = this.state;
        const reqData = {text};

        if (type === 'albums' && artist?.key) {
            reqData.artist = artist?.key;
        }

        this.fetchData(type, reqData)
    }

    selectProduct(item, isSelected) {
        const {selectedTracks} = this.state;
        this.setState({
            selectedTracks: isSelected ? selectedTracks.filter(m => m.id !== item.id) : [item, ...selectedTracks]
        })
    }

    insertMedia() {
        const {selectedTracks} = this.state;
        this.props.getSelectedTracks(selectedTracks);
        this.closeModal();
    }

    closeModal() {
        this.setState({
            selectedTracks: []
        });
        this.props.closeModal();
    }

    getSearchValue({value}) {
        this.setState({
            text: value
        }, () => {
            !value && this.getProducts()
        });
    }

    onKeyDown(e) {
        e.key === 'Enter' && this.getProducts()
    }

    getProducts() {
        const {text, artist, album} = this.state;
        this.props.GetTracks({
            text,
            artist: artist?.key,
            album: album?.key,
        });
    }

    loadMoreItems() {
        const {text, artist, album} = this.state;
        const {tracks} = this.props;
        const {hasMore} = tracks;
        hasMore && this.props.GetTracks({
            reset: false,
            offset: tracks.itemsList.length,
            text,
            artist: artist?.key,
            album: album?.key,
        });
    };

    handleChangeSelect({name, value, option} = {}) {
        const stateData = {
            [name]: option,
        };
        if (name === 'artist') {
            stateData.album = undefined;
            this.fetchData('albums', {artist: value})
        }

        this.setState(stateData, () => {
            this.searchItems()
        });
    }

    searchItems() {
        const {artist, album, text} = this.state;
        const filters = {
            artist: artist?.key,
            album: album?.key,
            text,
        };
        if (filters?.text?.length === 1) {
            return;
        }
        this.props.GetTracks(filters);
    }


    render() {
        const {isOpen, tracks} = this.props;
        const {selectedTracks, text, artists, albums, artist, album} = this.state;

        let artistsOptions = artists?.itemsList?.map(artist => {
            return {
                id: artist.id,
                name: artist?.fullName || "",
                imageUrl: resizeImage(generateImageMediaUrl(artist?.profileImage?.path), 200, 200),
            }
        });
        let albumsOptions = albums?.itemsList?.map(album => {
            return {
                id: album.id,
                name: album?.title || "",
                imageUrl: resizeImage(generateImageMediaUrl(album?.coverImage?.path), 200, 200),
            }
        });

        // console.log("artistsOptions", artistsOptions)
        return <Modal
            visible={isOpen}
            onCancel={this.closeModal}
            wrapClassName={"custom-modal"}>

            <div className="tracks-selector-modal">
                <div className="tracks-selector-modal-inner">
                    <div className="selector-modal-header">
                        <div className="selector-modal-title">
                            <h1>Երգեր</h1>
                        </div>
                        <div className="inputs-wrapper">
                            <InputGroup inputType="selectCustom"
                                        placeholder="Ընտրել Արտիստ"
                                        name="artist"
                                        showSearch={true}
                                        labelInValue={true}
                                        value={artist}
                                        onPopupScroll={(e) => this.onPopupScroll(e, 'artists')}
                                        onSearch={(e) => this.onSelectSearchChangedBounded(e, 'artists')}
                                        onChange={this.handleChangeSelect}
                                        options={artistsOptions}
                                        withClear={'Բոլորը'}>
                            </InputGroup>
                            <InputGroup inputType="selectCustom"
                                        placeholder="Ընտրել Ալբոմ"
                                        name="album"
                                        showSearch={true}
                                        labelInValue={true}
                                        value={album}
                                        onPopupScroll={(e) => this.onPopupScroll(e, 'albums')}
                                        onSearch={(e) => this.onSelectSearchChangedBounded(e, 'albums')}
                                        onChange={this.handleChangeSelect}
                                        options={albumsOptions}
                                        withClear={'Բոլորը'}>
                            </InputGroup>
                            <InputGroup
                                inputType={"input"}
                                type={'text'}
                                name={'text'}
                                value={text}
                                placeholder={'Փնտրել...'}
                                onKeyDown={this.onKeyDown}
                                onChange={this.getSearchValue}>
                                <div className={'search-icon'} onClick={this.getProducts}>
                                    <img src={require('../assets/images/ic_search.svg')} alt=""/>
                                </div>
                            </InputGroup>
                        </div>
                        <button className="selector-modal-closing-btn"
                                onClick={this.closeModal}>
                            <img src={require('../assets/images/ic_clear.svg')}
                                 className="close_icon" alt=""/>
                        </button>
                    </div>
                    <div className={'tracks-items-wrapper'}>
                        <InfiniteScroll
                            hasMore={tracks.hasMore}
                            loadMore={this.loadMoreItems}
                            pageStart={0}
                            className={'tracks'}
                            useWindow={false}
                            initialLoad={false}>
                            {tracks && !!tracks?.itemsList?.length && tracks.itemsList.map((item) => {
                                const isSelected = selectedTracks.find(v => v.id === item.id);
                                return <TrackCard key={item?.id}
                                                  isSelected={isSelected}
                                                  listView={false}
                                                  inSelectorModal={true}
                                                  onSelect={this.selectProduct}
                                                  data={item}/>
                            })}
                        </InfiniteScroll>

                    </div>
                    <div className="choose-button">
                        <LinkButton title={'Ընտրել'}
                                    disabled={!selectedTracks.length}
                                    cb={this.insertMedia}/>
                    </div>

                </div>
            </div>
        </Modal>
    }
}

const mapDispatchToProps = {
    GetTracks,
    GetAlbums,
    GetArtists,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsSelectorModal);
