// Import packages
import React, {useState} from "react";


//import assets
import './reject-modal.scss';

// Import components
import {Modal} from "antd";
import {InputGroup} from "../../../components/uiElements/inputGroup";
import {LinkButton} from "../../../components/buttons/buttons";
import {PODCAST_TYPES} from "../../../constants/constTypes";

// Import utils


function RejectModal(props) {
    const {visible, id} = props;
    const [reviewMessage, setReviewMessage] = useState('');
    const [error, setError] = useState(false);

    function closeModal() {
        props.closeModal()
    }

    function getInputValues({value}) {
        setReviewMessage(value)
    }

    function saveReview() {
        if (reviewMessage) {
            props.PodcastReview(id, {
                status: PODCAST_TYPES.REJECTED.key,
                reviewMessage: reviewMessage
            })
            props.closeModal()
            setReviewMessage('')
        } else {
            setError(true)
        }
    }

    return <Modal
        visible={visible}
        wrapClassName={'custom-modal'}
        onCancel={closeModal}>

        <div className={'reject-modal'}>
                <span className={'close-icon'} onClick={closeModal}>
                    <img src={require('../../../assets/images/ic_clear.svg')}
                         className="close_icon" alt=""/>
                </span>
            <div className="header">
                Մերժման պատճառ
            </div>
            <section className={'content-wrapper'}>
                <InputGroup inputType="textarea"
                            label="Նկարագրություն"
                            placeholder="Նկարագրություն"
                            name="description"
                            value={reviewMessage}
                            initValue={reviewMessage}
                            error={error}
                            maxLength={1000}
                            onChange={getInputValues}/>
                <LinkButton title={'Հաստատել'} cb={saveReview}/>
            </section>
        </div>
    </Modal>

}


export default RejectModal;
