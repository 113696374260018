import io from "socket.io-client";
import {store} from "../../redux/store";
import {ACTION_TYPES} from "../constants";
import {_urlSocket} from "../../redux/api";
// import {  } from "../emitters";


export const socketConnection = {
    _instance: null,
    get instance() {
        let {accessToken} = store.getState().auth;

        if (!this._instance && accessToken) {
            this.connectToSocket();
        }
        return this._instance;
    },
    set instance(instance) {
        this._instance = instance;
    },
    connectToSocket() {
        // console.log("start connect");

        if (store.getState().auth.accessToken) {
            this._instance = io.connect(_urlSocket, {
                'reconnectionDelay': 5000,
                transports:['websocket']
            });
            this._instance.on('connect', () => {

                // console.log("connected");
                let { accessToken, scope } = store.getState().auth;
                this._instance.emit(ACTION_TYPES.AUTHENTICATION, {'x-access-token': accessToken, scope });
            });

            this._instance.on(ACTION_TYPES.AUTHENTICATED, async () => {
                // console.log('AUTHENTICATED');
                await store.dispatch({
                    type: ACTION_TYPES.AUTHENTICATED
                });
            });

            this._instance.on(ACTION_TYPES.UNAUTHORIZED, (error) => {
                // console.log('UNAUTHORIZED');
                store.dispatch({
                    type: ACTION_TYPES.UNAUTHORIZED
                })
            });

            // this._instance.on(ACTION_TYPES..., (data) => {
            //      // console.log('new data...', data);
            // });

            this._instance.on('disconnect', (reason) => {
                 // console.log(reason, 'disconnect');

                if (reason === 'io server disconnect') {
                    let token = store.getState().auth.accessToken;
                    token && this._instance.connect();
                }
                // else the socket will automatically try to reconnect
            });
            this._instance.on('reconnect', (attemptNumber) => {
                // console.log('socket reconnected', attemptNumber);
            });
        }
    }

};
