// Import packages
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";

// Import Styles
import '../../assets/styles/cards/list-card.scss';

// Import components
import { LinkButton } from "../buttons/buttons";
import { MaterialIcon } from "../utils/Icon";
import { Checkbox } from "antd";
import { history } from "../../configs/history";
import { setPageOffset } from "../../redux/actions";

function ListItemCard(props){
    const contentRef = useRef();
    const {
        data, status, actions, largeItems, largeItemsWidth, itemsDefaultWidth, detailsLink, editLink, isNestable, collapsed,
        selectingActive, getScrollPosition, fixWidths, styles, fixWidthsSum, itemsWithPercentWidth, contentWidth
    } = props;
    let location = useLocation();
    const _collapsed = isNestable && data?.children?.length;

    function goToDetails(){
        if ( getScrollPosition ) {
            const offset = getScrollPosition?.();
            //console.log(offset);
            const pageName = location.pathname.split('/')?.[1];
            setPageOffset({
                offset,
                pageName,
            })
        }
        history.push({
            pathname: `/${detailsLink}/${data.id}`,
        })
    }

    function onClickInfoItem(link, e){
        e.preventDefault();
        e.stopPropagation();
        if ( getScrollPosition ) {
            const offset = getScrollPosition?.();
            //console.log(offset);
            const pageName = location.pathname.split('/')?.[1];
            setPageOffset({
                offset,
                pageName,
            })
        }
        history.push(link)
    }


    return <div className={`list-item-wrapper ${data?.newItem ? 'selected' : ''}`} onClick={data?.onClick}>
        {selectingActive && <span className={'selecting-checkbox'}>
                <Checkbox checked={data?.selected} onChange={data?.handleSelect}/>
            </span>}
        {_collapsed && <span className={'collapse-icon'} onClick={() => props.setCollapsed(data.id)}>
           <MaterialIcon icon={collapsed ? 'add' : 'remove'}/>
            </span>}
        <div className="general-info-content"
             style={{ width: `calc(100% - ${contentWidth}px)` }}
             onClick={detailsLink && goToDetails}
             ref={contentRef}>
            {data?.values && !!data.values.length && data.values.map((item, index) => {
                let width = largeItems.includes(index)
                    ? largeItemsWidth
                    : itemsDefaultWidth;
                width += '%';
                if ( itemsWithPercentWidth ) {
                    width = `calc( ${width} - ${fixWidthsSum / itemsWithPercentWidth}px )`
                }
                // console.log('itemsWithPercentWidth', itemsWithPercentWidth)
                // console.log('fixWidthsSum', fixWidthsSum)
                // console.log('width', width)
                fixWidths?.[index] && (width = fixWidths?.[index] + 'px');
                let style = { width: width };
                styles[index] && (style = { ...style, ...styles[index] });
                return <div className={`general-info-item`}
                            style={style}
                            key={index}>
                    {
                        (typeof item === 'object' && item !== null && item?.type !== 'span') ?
                            <>
                                {item?.type === 'image' && <img src={item?.path} className={'item-image'} alt={''}/>}
                                {item?.type === 'color' &&
                                <span className={'color'} style={{ backgroundColor: item.color }}/>}
                                <span onClick={item.link && onClickInfoItem.bind(null, item.link)}>
                                {item?.title ? item?.title : ''}
                            </span>
                            </>
                            : item
                    }
                </div>
            })}
        </div>
        <div className="item-actions-part">
            {status && <>
                {
                    typeof data?.status === 'boolean' ?
                        <div className="status">{data?.status ? 'Ակտիվ' : 'Ոչ ակտիվ'}</div>
                        : <span className={`status-badge`} style={{ backgroundColor: data?.status?.color }}>
                    {data.status?.title}</span>
                }
            </>
            }
            {actions && <div className="actions">
                {!!actions.length && actions.map((item) => {
                    return item === "edit" ?
                        <LinkButton link={{ pathname: `${editLink}/${data.id}` }}
                                    key={item}
                                    disabled={!editLink}
                                    getScrollPosition={getScrollPosition}
                                    title={<MaterialIcon icon="edit"/>}/>

                        : (item === "delete" ?
                            <LinkButton key={item}
                                        title={<MaterialIcon icon="delete"/>}
                                        disabled={!data.deleteCb}
                                        className="bg-red"
                                        cb={data.deleteCb}/>
                            : (item === "details" ?
                                <LinkButton key={item}
                                            disabled={!detailsLink}
                                            cb={goToDetails}
                                            title={<MaterialIcon
                                                icon="keyboard_arrow_right"/>}
                                /> :
                                (item === "toggle") ?
                                    <LinkButton cb={data.toggleCb}
                                                key={item}
                                                disabled={!data?.toggleCb}
                                                className={data?.status ? "bg-green" : "bg-grey"}
                                                title={<MaterialIcon
                                                    icon={data?.status ? 'visibility_off' : 'visibility'}/>}
                                    /> : ""))
                })}
            </div>}
        </div>
    </div>

}

export default ListItemCard
