import {Skeleton} from "antd";
import React from "react";
import '../../assets/styles/components/skelaton-item.scss';

export const SkeletonItem = ({loading}) => {
    return <div className={'skelton-item'}>
        <Skeleton loading={true}
                  paragraph={{
                      rows: 3,
                      width: "100%"
                  }}
                  active
        />
    </div>
}

export const SkeletonTrack = ({loading}) => {
    return <div className={' track-card skelton-item-track'}>
        <Skeleton loading={true}
                  shape={'square'}
                  paragraph={false}
                  avatar
                  active
        />
    </div>
}
